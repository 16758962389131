import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { Dayjs } from 'dayjs';
import { sortBy } from 'lodash';
import { createElement, FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Title, useNotify } from 'react-admin';
import { DashboardAnomaliesFound } from './DashboardAnomaliesFound';
import { DashboardAppointmentActivity } from './DashboardAppointmentActivity';
import { DashboardHistoricalDatePicker } from './DashboardHistoricalDatePicker';
import { DashboardAppointmentTrend } from './DashboardAppointmentTrend';
import { DashboardPatientActivity } from './DashboardPatientActivity';
import { DashboardNetPromoterTrend } from './DashboardNetPromoterTrend';
import { DashboardReputationActivity } from './DashboardReputationActivity';
import { DashboardReputationSummary } from './DashboardReputationSummary';
import { DashboardReputationTrend } from './DashboardReputationTrend';
import { DashboardReviewTrend } from './DashboardReviewTrend';
import { DashboardScheduledMessages } from './DashboardScheduledMessages';
import { DashboardSentMessages } from './DashboardSentMessages';
import { DashboardSettingsMenu, DashboardSettingsMenuItem, DashboardSettingsMenuToggle, DashboardSettingsMenuToggleList } from './DashboardSettingsMenu';
import { DashboardTopPatientCharities } from './DashboardTopPatientCharities';
import { DashboardUndeliverables } from './DashboardUndeliverables';
import { DashboardUnreachableAppointments } from './DashboardUnreachableAppointments';
import { DashboardWidget, Widget } from './DashboardWidget';
import { DragObject, OnDrop } from './DashboardWidgetDragDrop';
import { useHasNoCharity, useHasNoEmr } from './Organizations';
import { useUserPreference } from './UserPreferences';
import { isNotEmpty } from './utils';


// most common IfCanUse perms
const resource = 'locations';
const action = 'read';

const widgets: Widget[] = [
  { tag: 'reputation-activity', label: 'Reputation Activity', component: DashboardReputationActivity, resource, action },
  { tag: 'reputation-summary', label: 'Reputation Summary', component: DashboardReputationSummary, resource, action },
  { tag: 'patient-activity', label: 'Patient Activity', component: DashboardPatientActivity, resource, action },
  { tag: 'appointment-activity', label: 'Appointment Activity', component: DashboardAppointmentActivity, resource, action },
  { tag: 'unreachable-appointments', label: 'Upcoming Unreachable Appointments', component: DashboardUnreachableAppointments, resource, action },
  { tag: 'undeliverables', label: 'Undeliverables', component: DashboardUndeliverables, resource, action },
  { tag: 'sent-messages', label: 'Sent Messages', component: DashboardSentMessages, resource, action },
  { tag: 'scheduled-messages', label: 'Scheduled Messages', component: DashboardScheduledMessages, resource, action },
  { tag: 'anomalies-found', label: 'Anomalies Found', component: DashboardAnomaliesFound, resource, action },
  { tag: 'no-show-trend', label: 'Appointment Trend', component: DashboardAppointmentTrend, resource, action },
  { tag: 'top-patient-charities', label: 'Top Patient Charities', component: DashboardTopPatientCharities, resource, action },
  { tag: 'reputation-trend', label: 'Reputation Trend', component: DashboardReputationTrend, resource, action },
  { tag: 'review-trend', label: 'Review Trend', component: DashboardReviewTrend, resource, action },
  { tag: 'net-promoter-trend', label: 'NPS Trend', component: DashboardNetPromoterTrend, resource, action },
];

export const Dashboard: FC = () => {
  const [ _location, setLocation ] = useAppLocationState();
  const [ orderedWidgets, setOrderedWidgets ] = useState( widgets );
  const [ date, setDate ] = useState<Dayjs | null>( null );
  const { preferences, isLoading, update: updatePreference, reset: resetPreference } = useUserPreference();
  const hasNoCharity = useHasNoCharity();
  const hasNoEmr = useHasNoEmr();
  const hidden = useMemo<Record<string, boolean | undefined>>( () => ( {
    'appointment-activity': hasNoEmr,
    'undeliverables': !!date,
    'scheduled-messages': !!date,
    'anomalies-found': hasNoEmr || !!date,
    'top-patient-charities': hasNoCharity,
  } ), [ hasNoCharity, hasNoEmr, date ] );
  const notify = useNotify();

  useEffect( () => {
    setLocation( 'dashboard' );
    return () => setLocation( null );
  }, [ setLocation ] );

  const hiddenPreference = useMemo( () => ( preferences?.dashboardHiddenWidgets || [] ) as string[], [ preferences ] );

  const orderPreference = useMemo<string[] | undefined>( () => {
    const tags = ( preferences?.dashboardWidgetOrder || [] ) as string[];
    try {
      return [
        ...tags.filter( tag => widgets.find( w => w.tag == tag ) ),  // preferred first
        ...widgets.filter( w => !tags.includes( w.tag ) ).map( w => w.tag ), // add any missing/new after
      ];
    } catch( e ) {
      return;
    }
  }, [ preferences ] );

  useEffect( () => {
    if( !orderPreference?.length ) return;
    const ordered = orderPreference.map( tag => widgets.find( w => w.tag == tag ) ).filter( isNotEmpty );
    setOrderedWidgets( ordered );
  }, [ orderPreference ] );

  const onDrop: OnDrop = useCallback( ( tag: string ) => async ( item: DragObject ) => {
    const from = orderedWidgets.findIndex( w => w.tag == item?.tag );
    const to = orderedWidgets.findIndex( w => w.tag == tag );
    const ow = [ ...orderedWidgets ];
    const mover = ow.splice( from, 1 )[ 0 ];
    ow.splice( to, 0, mover );
    setOrderedWidgets( ow );
    if( updatePreference ) {
      const { error, message } = await updatePreference( 'dashboardWidgetOrder', ow.map( w => w.tag ) );
      if( error ) {
        notify( error, { type: 'error' } );
      } else {
        notify( message || 'Saving dashboard config', { type: 'success' } );
      }
    }
    return item;
  }, [ orderedWidgets, setOrderedWidgets, preferences, updatePreference ] );

  const handleReset = useCallback( () => {
    if( !resetPreference ) return;
    resetPreference( 'dashboardActivityDays' );
    // resetPreference( 'dashboardHiddenWidgets' ); // the show/hide menu has a separate reset button
    resetPreference( 'dashboardTrendMonths' );
    resetPreference( 'dashboardWidgetOrder' );
  }, [ resetPreference ] );

  if( isLoading ) return null;

  return (
    <Box >
      <Title title='Dashboard' />
      <Box mb={ 1 } style={ { display: 'flex' } }>
        <Typography gutterBottom variant='body2' component='span'>
          Dashboard
        </Typography>

        <div style={ { flexGrow: 1, display: 'inline-block' } }></div>

        <DashboardHistoricalDatePicker date={ date } setDate={ setDate } />

        <DashboardSettingsMenuToggleList
          preferenceKey='dashboardHiddenWidgets'
          label='Show/Hide Charts'
          options={ sortBy( widgets, w => w.label ).filter( w => !hidden[ w.tag ] ).map( w => ( { value: w.tag, label: w.label } ) ) }
          disabled={ !!date }
        />

        <DashboardSettingsMenu disabled={ !!date } >
          <DashboardSettingsMenuToggle
            preferenceKey='dashboardActivityDays'
            label='Activity Days'
            defaultValue={ 30 }
            options={ [ 7, 14, 30, 45, 60 ] }
          />
          <DashboardSettingsMenuToggle
            preferenceKey='dashboardTrendMonths'
            label='Trend Months'
            defaultValue={ 6 }
            options={ [ 3, 6, 9, 12, 18 ] }
          />
          <DashboardSettingsMenuItem onClick={ handleReset }>Reset dashboard preferences</DashboardSettingsMenuItem>
        </DashboardSettingsMenu>

      </Box>

      <Grid container spacing={ 3 }>

        { orderedWidgets.map( ( widget, idx ) => {
          const { tag, component, ...rest } = widget;
          return (
            <DashboardWidget key={ idx } idx={ idx } date={ date } hidden={ hidden[ tag ] || hiddenPreference.includes( tag ) } tag={ tag } onDrop={ onDrop } { ...rest } >
              { createElement( component ) }
            </DashboardWidget>
          );
        } ) }

      </Grid>
    </Box >
  );
}


