import { Create as EditIcon, PlayArrow, Remove } from '@mui/icons-material';
import { Box, Button, ButtonProps, Card, CardContent, CircularProgress, FormControl, Grid, InputLabel, ListSubheader, MenuItem, Select, SelectProps, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DataGridPremium, DataGridPremiumProps, GridColDef } from '@mui/x-data-grid-premium';
import { EditDialog } from '@react-admin/ra-form-layout';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { format } from 'date-fns';
import { transform as inflect } from 'inflection';
import { dump as yaml } from 'js-yaml';
import linkify from 'linkify-html';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Labeled, Title, useAuthenticated, useGetMany } from 'react-admin';
import { useLocation, useParams } from 'react-router-dom';
import { ConfigForm } from './Configs';
import { apiUrl, httpClient } from './DataProvider';

export { Storage as SystemIcon } from '@mui/icons-material';

const labelize = ( s: string ): string => inflect( s, [ 'underscore', 'humanize' ] );

interface Action {
  id: string;
  name: string,
  description?: string;
  configs?: string[];
  scope: string;
  priority?: number;
  group?: string;
  runTimeSeconds?: number;
  requiresEmr?: boolean;
}
interface Config {
  id: string;
  name: string;
  type: 'string' | 'number' | 'boolean';
  value: string | boolean | number; enum?: string[]; min?: number, max?: number;
  comment: string;
}
interface Log {
  [ index: string ]: string | number | object | null | undefined;
  id: string;
  pid?: string;
  level?: number;
  time?: number;
  hostname?: string;
  name?: string;
  msg: string;
}

const levels: Record<number, string> = {
  10: 'trace',
  20: 'debug',
  30: 'info',
  33: 'cons',
  40: 'warn',
  50: 'error',
  51: 'announce',
  60: 'fatal',
}

const parseLogStream = ( body: string ): Log[] => {
  const level = 30;
  return body.split( '\n' ).slice( 0, -1 ).map( ( msg, idx ) => {
    const id = idx.toString();
    try {
      return ( { id, ...JSON.parse( msg ) } ) as Log;
    } catch( e ) {
      return { id, level, msg } as Log;
    }
  } );
}

const formatTimestamp = ( d?: number ): string => {
  if( !d ) return '';
  return format( d, 'HH:mm:ss.SSS' );
}

const columns: GridColDef<Log, string>[] = [
  {
    field: 'level',
    headerName: 'Level',
    type: 'number',
    // flex: 0,
    // width: 150,
    valueFormatter: ( value ) => levels[ value ]?.toUpperCase() ?? value,
    cellClassName: p => typeof p.value == 'number' && levels[ p.value ] ? `level-${ levels[ p.value ] }` : '',
  },
  {
    field: 'time',
    headerName: 'Time',
    width: 140,
    type: 'number',
    valueFormatter: ( value ) => formatTimestamp( value ),
  },
  {
    field: 'msg',
    headerName: 'Message',
    // width: 110,
    flex: 2,
    renderCell: params => {
      const { value } = params;
      if( !value || value.includes( '<' ) ) return value; // fix "<<< ..." disappearing
      const __html = linkify( value, {
        target: '_blank',
        rel: 'noopener',
        // format: ( value, type ) => {if( type === 'url' && value.length > 50 ) {value = value.slice( 0, 50 ) + '…';} return value;},
        truncate: 60,
      } );
      return ( <div dangerouslySetInnerHTML={ { __html } } /> );
    },
  },
  // // https://mui.com/x/react-data-grid/master-detail/#customizing-the-detail-panel-toggle
  // {
  //   ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  //   renderCell: ( params ) => (
  //     <CustomDetailPanelToggle id={ params.id } value={ params.value } />
  //   ),
  // },
];

export const System: FC = () => {
  useAuthenticated();
  const theme = useTheme();
  const { pathname, state } = useLocation();
  const { actionId } = useParams();
  const [ _location, setLocation ] = useAppLocationState();
  const [ actions, setActions ] = useState<Array<Action>>( [] );
  const [ selected, setSelected ] = useState( '' );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ editConfig, setEditConfig ] = useState( '' );
  const [ log, setLog ] = useState<Log[]>( [] );
  const getAction = useCallback( ( selected: string ) => actions.find( a => a.id == selected ), [ actions ] );
  const action = useMemo<Action | undefined>( () => getAction( selected ), [ actions, selected ] );
  const { data: configs, isLoading: _isLoadingActions } = useGetMany<Config>( 'configs', { ids: action?.configs }, { enabled: !!selected } );
  const selectRef = useRef<HTMLSelectElement>();

  const getConfig = useCallback( ( id: string ): Config | undefined => ( configs || [] ).find( c => c.id == id ), [ configs ] );

  const fetchActions = useCallback( async ( key = '' ) => {
    setIsLoading( true );
    const { body } = await httpClient( `${ apiUrl }/system/actions/${ key }` );
    setIsLoading( false );
    return body;
  }, [ httpClient ] );

  // On first page load to init and de-init app location (breadcrumb)
  useEffect( () => {
    setLocation( 'admin.system' );
    return () => setLocation( null );
  }, [] );

  // When breadcrumb "System" is clicked from a selected action state
  useEffect( () => {
    console.log( 'pathname', pathname, state, actionId );
    if( pathname !== '/admin/system/clear' ) return; // clear action when clicking "System" breadcrumb
    history.pushState( {}, '', `/#/admin/system` );
    setLocation( 'admin.system' );
    setSelected( '' );
    // NB the following did not work. Need another way to reset the uncontrolled Select input, possibly add form wrapper and call reset method on that ref 
    if( selectRef?.current ) {
      selectRef.current.value = '';
    }
  }, [ pathname, actionId, state, selectRef ] );

  // useEffect( () => {
  //   // if( loation == 'admin.system' ) setSelected( '' ); // clear action when clicking "System" breadcrumb
  //   console.log( 'location', location.path, location.values, selected, pathname )
  // }, [ location ] );

  // load actions from local storage pending fetchActions return
  useEffect( () => {
    ( async () => {
      if( actions.length || !isLoading ) return;
      const json = localStorage.getItem( 'admin-system-actions' );
      if( actions.length || !json || !isLoading ) return;
      try {
        setActions( JSON.parse( json ) );
        setIsLoading( false );
      } catch( e ) { return; }
    } )()
  }, [ actions, setActions, isLoading ] );

  // fetch actions, update menu and localStorage and set app location
  useEffect( () => {
    ( async () => {
      try {
        const body = await fetchActions();
        setActions( JSON.parse( body ) );
        localStorage.setItem( 'admin-system-actions', body );
      } catch( e ) {
        setActions( [] );
      }
      setLocation( 'admin.system' );
    } )()
  }, [ setActions, fetchActions ] );

  const onSelect = useCallback<NonNullable<SelectProps<string>[ 'onChange' ]>>( ( e ) => {
    const { target: { value: selected } } = e;
    setIsLoading( false );
    setSelected( selected );
    setLocation( `admin.system.action.${ selected }`, { selected, action: getAction( selected ) } );
    history.pushState( {}, '', `/#/admin/system/${ selected }` );
    setLog( [] );
  }, [ setSelected, action, actions, getAction ] );

  const onRun = useCallback<NonNullable<ButtonProps[ 'onClick' ]>>( async () => {
    if( !action ) return;
    setLog( [] );
    setLog( parseLogStream( await fetchActions( action.id ) ) );
  }, [ action, setLog, fetchActions ] );

  type GetDetailPanelContent = NonNullable<DataGridPremiumProps[ 'getDetailPanelContent' ]>;
  const getDetailPanelContent = useCallback<GetDetailPanelContent>( ( { row } ) => {
    const { id, level, time, pid, hostname, name, msg, ...rest } = row;
    if( !Object.keys( rest ).length ) return undefined;
    return (
      // TODO colorize (linkify might help w newlines)
      // TODO later, JSON | YAML buttons/tabs w pref store
      // <pre>{ JSON.stringify( rest, null, 2 ) }</pre>  
      ( <Box sx={ { padding: '1rem' } }>
        <pre>
          { yaml( rest, {
            skipInvalid: true,
            lineWidth: 140,
            noRefs: true,
            noCompatMode: true,
          } ) }
        </pre>
      </Box> )
    );
  }, [] );

  const Spinner: FC = () => <CircularProgress size='1rem' />;

  return (
    ( <IfCanAccess resource='system' action='manage'>
      <Card
        sx={ {
          minHeight: '25em',
        } }
      >

        <Title title="System Management" />
        <CardContent>


          <Grid container key='controls' spacing={ 6 } alignItems='center'        >
            <Grid item key='input' >
              <FormControl >
                <InputLabel id="demo-simple-select-label">Action</InputLabel>
                <Select
                  ref={ selectRef }
                  defaultValue=''
                  label='Action'
                  sx={ { minWidth: '18rem' } }
                  disabled={ !actions.length }
                  onChange={ onSelect }
                  // defaultOpen
                  autoWidth
                  MenuProps={ { sx: { maxHeight: '80%', left: '105px' } } }
                // IconComponent={ isLoadingActions ? Spinner : undefined }
                >
                  { Object.entries( groupBy( sortBy( actions, 'name' ), a => a.group || 'No Group' ) )
                    .sort()
                    // .filter( ( [ group ] ) => !( location.hostname != 'localhost' && group.match( /localhost/i ) ) )
                    .flatMap( ( [ group, actions ] ) => {
                      return [
                        ( <ListSubheader key={ group } color='primary'>{ group.toUpperCase() }</ListSubheader> ),
                        ...( actions.sort().map( a => (
                          <MenuItem key={ a.id } value={ a.id } dense
                            sx={ {
                              marginLeft: '1rem',
                              // fontWeight: a.description ? 'bold' : undefined,
                            } }
                          >
                            { a.name }{/* <div style={ { width: '100%' } } /> { a.priority } <div style={ { width: '1rem' } } /> */ }
                          </MenuItem>
                        ) )
                        )
                      ]
                    } ) }

                </Select>
              </FormControl>
            </Grid>
            <Grid item key='run'>
              <Button
                disableFocusRipple
                variant={ log.length ? 'outlined' : 'contained' }
                color='error'
                disabled={ !action?.id || isLoading }
                onClick={ onRun }
                endIcon={ isLoading ? <Spinner /> : <PlayArrow /> }
              >
                Run
              </Button>
            </Grid>
          </Grid>


          <Grid key='metadata' container spacing={ 6 } alignItems='flex-start'>
            <Grid key='metadata1' item container alignItems='flex-start' direction='column'
              // sx={ {border: '1px solid blue'} }
              xs={ 5 }
            >
              <Grid key='description' item>
                { action?.description &&
                  <Labeled label='Description'>
                    <>
                      { ( action.description || '' ).split( '\n' ).map( ( description, key ) =>
                        <Typography key={ key } paragraph>
                          { description }
                        </Typography>
                      ) }
                    </>
                  </Labeled>
                }

              </Grid>
              <Grid item container key='metadata1' spacing={ 4 } >
                { action && action.hasOwnProperty( 'runTimeSeconds' ) &&
                  <Grid key='time' item >
                    <Labeled label="Run time">
                      <Typography>
                        { action.runTimeSeconds } s
                      </Typography>
                    </Labeled>
                  </Grid> }
                { action && action.hasOwnProperty( 'requiresEmr' ) &&
                  <Grid key='emr' item >
                    <Labeled label='EMR used'>
                      <Typography>
                        { action.requiresEmr ? 'Yes' : 'No' }
                      </Typography>
                    </Labeled>
                  </Grid>
                }
                { action?.scope &&
                  <Grid key='scope' item >
                    <Labeled label='Access level'>
                      <Typography>
                        { labelize( action.scope ) }
                      </Typography>
                    </Labeled>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid key='configs' item xs={ 7 } mt={ 2 } >
            { action?.configs &&
              <>
                <Table size='small'  >
                  <TableHead sx={ {
                    '& .MuiTableCell-root': {
                      color: 'gray',
                      fontSize: '70%',
                      lineHeight: '0.9rem',
                    },
                  } }>
                    <TableRow>
                      <TableCell key='c' >Config values</TableCell>
                      <TableCell key='i' ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { action.configs.length == 0
                      ? ( <TableRow ><TableCell colSpan={ 2 } >None</TableCell></TableRow> )
                      : action.configs.map( config => (
                        <TableRow key={ config } hover >
                          <TableCell key='c' onClick={ () => { setEditConfig( config ) } } >
                            <Tooltip followCursor title={ getConfig( config )?.comment } placement='bottom-start' >
                              <>
                                <Typography>
                                  { config }
                                </Typography>
                                <Typography variant='body2' ml={ 4 } sx={ {
                                  fontFamily: 'monospace',
                                  color: theme.palette.primary.light,
                                } }>
                                  { getConfig( config )?.type == 'boolean' ? getConfig( config )?.value.toString() : getConfig( config )?.value }
                                </Typography>
                              </>
                            </Tooltip>
                          </TableCell>
                          <TableCell key='i' onClick={ () => { setEditConfig( config ) } }>
                            <EditIcon color='primary' fontSize='small' />
                          </TableCell>
                        </TableRow>

                      ) ) }
                  </TableBody>
                </Table>
                <EditDialog
                  title={ editConfig }
                  resource='configs'
                  record={ getConfig( editConfig )! }  // eslint-disable-line @typescript-eslint/no-non-null-assertion
                  mutationMode='pessimistic'
                  fullWidth
                  maxWidth="sm"
                  isOpen={ !!editConfig }
                  close={ () => { setEditConfig( '' ); } }
                >
                  <ConfigForm />
                </EditDialog>
              </>
            }
          </Grid>

          <Grid key='logs' container mt={ 4 }>
            { log.length > 0 &&

              <DataGridPremium
                columns={ columns }
                rows={ log }
                initialState={ {
                  density: 'compact',
                  // hideFooter: true,
                  // autoHeight
                  // autoPageSize
                  // disableRowSelectionOnClick

                } }
                getRowHeight={ () => 'auto' }
                getDetailPanelContent={ getDetailPanelContent }
                getDetailPanelHeight={ () => 'auto' }
                sx={ {
                  '& .MuiDataGrid-cell--withRenderer .MuiButtonBase-root.Mui-disabled': {
                    color: 'hsl(0deg 0% 0% / 0%)',
                  },
                  // trace:gray,debug:blue,info:green,cons:magentaBright,warn:yellow,error:red,fatal:bgRed
                  '& .level-trace': { color: 'gray', borderLeft: 'solid 6px gray' },
                  '& .level-debug': { color: 'blue', borderLeft: 'solid 6px blue' },
                  '& .level-info': { color: 'green', borderLeft: 'solid 6px green' },
                  '& .level-cons': { color: 'darkmagenta', borderLeft: 'solid 6px darkmagenta' },
                  '& .level-warn': { color: 'black', backgroundColor: 'yellow', borderLeft: 'solid 6px yellow' },
                  '& .level-error': { color: 'red', borderLeft: 'solid 6px red' },
                  '& .level-announce': { color: 'greenyellow', borderLeft: 'solid 6px greenyellow' },
                  '& .level-fatal': { color: 'black', backgroundColor: 'red', borderLeft: 'solid 6px red' },
                } }
                slots={ {
                  detailPanelCollapseIcon: Remove,
                } }
              />
            }
          </Grid>

        </CardContent >
      </Card >
    </IfCanAccess > )
  );

}
