import { Add as ContentAdd, Create as ContentEdit, List as ContentList, AccountTree as Icon, RemoveRedEye as ImageEye, Portrait } from '@mui/icons-material';
import { Box, InputAdornment, ListItemIcon, ListItemText, TableHead } from '@mui/material';
import { titleize } from 'inflection';
import { pick } from 'lodash';
import { FC, MouseEventHandler, ReactElement, useEffect, useMemo, useState } from 'react';
import { Button, ButtonProps, ChipField, Create, CreateProps, Datagrid, DatagridHeaderProps, Edit, EditButton, EditProps, Identifier, InputProps, List, ListProps, NumberField, NumberInput, ReferenceArrayField, ReferenceField, SelectField, SelectInput, Show, ShowProps, SimpleForm, SingleFieldList, Tab, TabbedShowLayoutProps, TextField, TextInput, useBasename, useGetList, useRecordContext, useResourceContext } from 'react-admin';
import { Link, Link as RRLink } from 'react-router-dom';
import { amenityChoices } from './AssetInput';
import { OrganizationShowAssets } from './AssetShow';
import { BooleanCheckboxInput } from './BooleanCheckboxInput';
import { BooleanColorField } from './BooleanColorField';
import { ColorField, ColorInput } from './ColorInput';
import { choices } from './DataProvider';
import { DualListInput } from './DualListInput';
import { SimpleFormWithLocation, TabbedShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import { CustomToolbar } from './ReminderTemplateLists';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';
import { validateName } from './validate';

export const OrganizationIcon = Icon;

export const DatagridNoHeader: FC<DatagridHeaderProps> = () => ( <TableHead /> );

export interface MyBreadcrumbContext {
  location?: Record<string, string>;
  organization?: Record<string, string>;
  member?: Record<string, string>;
  user?: Record<string, string>;
  asset?: Record<string, string>;
}

// const HasEmrContext = createContext<boolean>( true )
//  
// export interface HasEmrProviderProps {
//   children: ReactElement;
// }
//  
//  
// export const HasEmrProvider: FC<{ children: ReactElement; }> = ( { children } ) => {
//   const { isLoading, data: orgs } = useGetList( 'organizations' );
//  
//   const hasEmr = useMemo<boolean>( () => {
//     const org = ( orgs || [] ).find( o => o.parent === null );
//     if( org && org.hasOwnProperty( 'hasEmr' ) ) {
//       return !!org.hasEmr;
//     }
//     return true;
//   }, [ orgs ] );
//  
//   return (
//     <HasEmrContext.Provider value={ hasEmr } >
//       { children }
//     </HasEmrContext.Provider>
//   );
// };

export const useHasNoCharity = () => {
  const { isLoading, data } = useGetList( 'organizations', { filter: { parent: null } } );
  const [ hasNoCharity, setHasNoCharity ] = useState<boolean | undefined>();

  useEffect( () => {
    ( async () => {
      if( data || !isLoading ) return;
      const hasNoCharityString = localStorage.getItem( 'has-no-charity' );
      if( data || !hasNoCharityString || !isLoading ) return;
      setHasNoCharity( hasNoCharityString.toLowerCase() == 'true' );
    } )()
  }, [ data, setHasNoCharity, isLoading ] );

  useEffect( () => {
    ( async () => {
      if( isLoading ) return true;
      const [ org ] = ( data || [] );
      const hasNoCharity = !!org?.disableCharities;
      localStorage.setItem( 'has-no-charity', hasNoCharity.toString() )
      setHasNoCharity( hasNoCharity );
    } )()
  }, [ data, setHasNoCharity, isLoading ] );

  return useMemo<boolean>( () => hasNoCharity === undefined ? true : hasNoCharity, [ hasNoCharity ] );
}

export const useHasNoEmr = () => {
  const { isLoading, data } = useGetList( 'organizations', { filter: { parent: null } } );
  const [ hasNoEmr, setHasNoEmr ] = useState<boolean | undefined>();

  useEffect( () => {
    ( async () => {
      if( data || !isLoading ) return;
      const hasNoEmrString = localStorage.getItem( 'has-no-emr' );
      if( data || !hasNoEmrString || !isLoading ) return;
      setHasNoEmr( hasNoEmrString.toLowerCase() == 'true' );
    } )()
  }, [ data, setHasNoEmr, isLoading ] );

  useEffect( () => {
    ( async () => {
      if( isLoading ) return true;
      const [ org ] = ( data || [] );
      const hasNoEmr = !!org?.hasNoEmr;
      localStorage.setItem( 'has-no-emr', hasNoEmr.toString() )
      setHasNoEmr( hasNoEmr );
    } )()
  }, [ data, setHasNoEmr, isLoading ] );

  return useMemo<boolean>( () => hasNoEmr === undefined ? true : hasNoEmr, [ hasNoEmr ] );

}

export const useEmr = () => {
  const { isLoading, data } = useGetList( 'organizations', { filter: { parent: null } } );
  const [ emr, setEmr ] = useState<string>( '' );

  useEffect( () => {
    ( async () => {
      if( data || !isLoading ) return;
      const emr = localStorage.getItem( 'emr' );
      if( data || !emr || !isLoading ) return;
      setEmr( emr );
    } )()
  }, [ data, setEmr, isLoading ] );

  useEffect( () => {
    ( async () => {
      if( isLoading ) return true;
      const [ org ] = ( data || [] );
      const emr = ( org?.emrBrand || '' ) as string;
      localStorage.setItem( 'emr', emr )
      setEmr( emr );
    } )()
  }, [ data, setEmr, isLoading ] );

  return emr;

}


// export const MyBreadcrumb: FC<BreadcrumbProps> = props => {
//  
//   // const assetLabel: GetLabelFunction = context => {
//   //   const { subject, body, id } = get<MyBreadcrumbContext[ 'asset' ], keyof MyBreadcrumbContext[ 'asset' ], MyBreadcrumbContext[ 'asset' ]>( context, 'asset', {} );
//   //   return subject ? subject : body ? ( body.length > 20 ? `${ body.slice( 0, 20 ) }...` : body ) : id;   // TODO replace hard ellipses w css styling
//   // };
//  
//   return (
//     <Breadcrumb { ...props }>
//  
//       <BreadcrumbItem name="organizations" label="Organizations" to="/organizations" >
//         <BreadcrumbItem
//           name="show"
//           label={ ( { record = {} } ): string => ( record as RaRecord )?.name || '' }
//         />
//         <BreadcrumbItem name="create" label="New organization" />
//  
//         <BreadcrumbItem
//           name="organization"
//           label={ context => get( context as MyBreadcrumbContext, 'organization.name' ) || '' }
//           to={ context => `/organizations/${ get( context as MyBreadcrumbContext, 'organization.id', '' ) }/show` }
//         >
//           <BreadcrumbItem name="locations" label="Locations"
//             to={ context => `/locations?filter=${ JSON.stringify( { organization: get( context as MyBreadcrumbContext, 'organization.id', '' ) } ) }` }
//           >
//             <BreadcrumbItem label="Create" name="create" />
//             <BreadcrumbItem name="location"
//               label={ context => `${ get( context as MyBreadcrumbContext, 'location.name', '' ) }` }
//               to={ context => `/locations/${ get( context as MyBreadcrumbContext, 'location.id', '' ) }/show` }
//             >
//               <BreadcrumbItem label="Edit" name="edit" />
//               <BreadcrumbItem name="preview" label="Preview" />
//  
//               <BreadcrumbItem name="assets" label="Assets"
//                 to={ context => `/assets?filter=${ JSON.stringify( { tags: get( context as MyBreadcrumbContext, 'location.id', '' ) } ) }` }
//               >
//                 <BreadcrumbItem label="Create" name="create" />
//                 <BreadcrumbItem name="asset"
//                   // label={ assetLabel }
//                   label={ 'id' }
//                   to={ context => `/assets/${ get( context as MyBreadcrumbContext, 'asset.id', '' ) }/show` }
//                 >
//                   <BreadcrumbItem label="Edit" name="edit" />
//                 </BreadcrumbItem>
//               </BreadcrumbItem>
//             </BreadcrumbItem>
//           </BreadcrumbItem>
//  
//           <BreadcrumbItem name="members"
//             label="Team Members"
//             to={ context => `/members?filter=${ JSON.stringify( { organization: get( context as MyBreadcrumbContext, 'organization.id', '' ) } ) }` }
//           >
//             <BreadcrumbItem label="Invite" name="create" />
//             <BreadcrumbItem name="member"
//               label={ context => `${ get( context as MyBreadcrumbContext, 'user.name', '' ) }` }
//               to={ context => `/members/${ get( context as MyBreadcrumbContext, 'member.id', '' ) }/show` }
//             >
//               <BreadcrumbItem label="Edit" name="edit" />
//  
//  
//             </BreadcrumbItem>
//           </BreadcrumbItem>
//  
//  
//           <BreadcrumbItem name="assets" label="Assets"
//             to={ context => `/assets?filter=${ JSON.stringify( { tags: get( context as MyBreadcrumbContext, 'organization.id', '' ) } ) }` }
//           >
//             <BreadcrumbItem label="Create" name="create" />
//             <BreadcrumbItem name="asset"
//               // label={ assetLabel }
//               label={ 'id' }
//               to={ context => `/assets/${ get( context as MyBreadcrumbContext, 'asset.id', '' ) }/show` }
//             >
//               <BreadcrumbItem label="Edit" name="edit" />
//             </BreadcrumbItem>
//  
//           </BreadcrumbItem>
//  
//         </BreadcrumbItem>
//  
//       </BreadcrumbItem>
//  
//  
//     </Breadcrumb>
//   );
// }


// TODO take control of width of TreeWithDetails.cardTree.minWidth
//  ?? using theme.overrides ??
//
// import { defaultTheme } from 'react-admin';
// import merge from 'lodash/merge';
// const myTheme = merge({ }, defaultTheme, {
//     overrides: {
//       MuiButton: { // override the styles of all instances of this component
//         root: { // Name of the rule
//           color: 'white', // Some CSS
//         },
//       },
//     },
// });

// const useTreeWithDetailsStyles = makeStyles( {
//   container: {
//     display: 'grid',
//   },
// } );
//  
// export const OrganizationTree: FC<ListProps> = props => {
//   const treeClasses = useTreeWithDetailsStyles( props );
//   // const location = useLocation();
//   const history = useHistory();
//   // const orgPath = ( location.pathname.match( new RegExp( '(/organizations/[^/]+)/' ) ) || [] )[ 1 ];
//   const { data: rootOrgs, loaded: rootOrgsLoaded } = useGetRootNodes( 'organizations' );
//  
//   //        <ResourceBreadcrumbItems />
//   //             to={ ( record ) => record && `${ linkToRecord( '/songs', record.id ) }/edit` }
//   //             to={ ( { record } ) => record && `${ linkToRecord( '/songs', record.id ) }/show` }
//  
//   useEffect( () => {
//     if( !rootOrgs || rootOrgs.length < 1 ) return;
//     if( history.location.pathname.match( new RegExp( '/organizations$' ) ) ) {
//       history.push( `/organizations/${ rootOrgs[ 0 ].id }/show` );
//     }
//   }, [ rootOrgs, rootOrgsLoaded, history.location ] );
//  
//  
//   return (
//     <>
//       <MyBreadcrumb { ...props } variant="actions" />
//  
//       <TreeWithDetails
//         titleField="name"
//         draggable
//         linkTo="show"
//         create={ OrganizationCreateNode }
//         edit={ OrganizationEditNode }
//         show={ OrganizationShowNode }
//         classes={ treeClasses }
//         nodeActions={ <></> }
//         { ...props }
//       />
//  
//       {/* <LocationCreateDialog { ...props } basePath={ `${ orgPath }/show/locations` } record={ location.state as Partial<Record> } /> */ }
//  
//       {/* <LocationShowDialog { ...props } basePath={ `${ orgPath }/show/locations` } /> */ }
//  
//       {/* <LocationEditDialog { ...props } basePath={ `${ orgPath }/show/locations` } /> */ }
//  
//       {/*
//               <EditDialog { ...props } basePath={ `${ orgPath }/show/2` } resource="locations" >
//               <SimpleForm sanitizeEmptyValues  >
//               <Typography>I am root</Typography>
//               </SimpleForm>
//               </EditDialog>
//             */}
//     </>
//   )
// };


export const OrganizationList: FC<Childless<ListProps>> = props => {
  useListAppLocation( 'organization' );
  // const location = useLocation();
  // const navigate = useNavigate()
  // const { data: rootOrgs, isLoading: isRootOrgsLoading } = useGetRootNodes( 'organizations' );

  // useEffect( () => {
  //   if( isRootOrgsLoading || !rootOrgs
  //     || !location.pathname.match( new RegExp( '/organizations$' ) ) ) {
  //     return;
  //   }
  //   if( rootOrgs.length === 1 ) {
  //     navigate( `/organizations/${ rootOrgs[ 0 ].id }/show` );
  //   }
  // }, [ rootOrgs, isRootOrgsLoading, location ] );


  return (
    <List
      { ...props }
      exporter={ false }
    >
      <Datagrid
        rowClick="show"
        bulkActionButtons={ false }
        sx={ {
          '& .column-name': {
            width: '100%',
          }
        } }
      >
        <TextField source="name" />
        <BrandingButton />
        <EditButton />
      </Datagrid>
    </List>
  )
};

export interface BrandingButtonProps extends ButtonProps {
  enableSource?: string; // a field in the record, that if truthy enable the button
}

export const BrandingButton: FC<BrandingButtonProps> = ( props ) => {
  const { enableSource } = props;
  const basename = useBasename();
  const resource = useResourceContext( props );
  const record = useRecordContext( props );
  // const createPath = useCreatePath();
  const stopPropagation: MouseEventHandler = e => e.stopPropagation();
  const enable = !enableSource || !!( record && record[ enableSource ] );

  if( !record || !enable ) return null;

  //       <Image />

  return (
    <Button
      component={ Link }
      label='Branding'
      to={ `${ basename }/${ resource }/${ encodeURIComponent( record.id ) }/show/assets` }
      onClick={ stopPropagation }
    >
      <Portrait />
    </Button>
  );
}

export const ButtonSpacer = (): ReactElement => (
  <Box sx={ { flexGrow: 8, border: '1px solid blue' } } />
);

// export const NodeEditActions = ( props: EditActionsProps ): ReactElement => {
//   // const { basePath, resource } = props;
//   const { pathname } = useLocation();
//   const title = useDefaultTitle();
//   const { data, ...rest } = props;
//   // const orgPath = ( pathname.match( new RegExp( '(/organizations/[^/]+)/' ) ) || [] )[ 1 ];
//   const { id } = useParams<{ id: string }>();
//  
//   return (
//     <TopToolbar>
//       {/* <MyBreadcrumb { ...props } variant="actions" /> */ }
//  
//       {/* pathname.endsWith( '/show' ) &&   // removed Add Child for now
//           <Button
//           component={ RRLink }
//           to={ {
//           pathname: `${ basePath }/create`,
//           state: { parentId: id },
//           } }
//           label="Add child organization"
//           >
//           <ContentAdd />
//           </Button>
//         */ }
//  
//       <ButtonSpacer />
//  
//       { pathname.endsWith( '/show' ) && <EditButton { ...rest } record={ data } /> }
//  
//       { !pathname.match( '/show(/[a-z]+)?$' ) && <ShowButton { ...props, title } record={ data } /> }
//  
//       { /*  pathname.endsWith( '/show/team' ) &&
//         <IfCanAccess resource='members' action='create' >
//           <MemberAddButton label="Invite new member" style={ { margin: '0 8px' } } id={ id } />
//         </IfCanAccess>
//       */ }
//  
//     </TopToolbar>
//   );
// }

// TODO refactor
export const getResourceTagId = ( resource?: string ): string => {
  switch( resource ) {
    case 'organizations': return '61aa9c9106d49c894f38fa93';
    case 'locations': return '61aa9c0c06d49c894f38fa4c';
  }
  return '';
}

export const OrganizationShow: FC<Omit<ShowProps, 'children'>> = props => {

  // actions={ <ResourceShowActions { ...props } hideDelete={ true } /> }
  // 

  return (
    <Show
      { ...props }
    >
      <TabbedShowOrganization />
    </Show>
  );
}

// export const OrganizationShowNode: FC<ShowProps> = props => {
//  
//   return (
//     <ShowNode
//       actions={ <NodeEditActions { ...props } /> }
//       { ...props }
//     >
//       <TabbedShowOrganization />
//     </ShowNode>
//   );
// }

export interface LocationGridEditButtonProps extends ButtonProps {
  id?: string;
}

export const LocationGridEditButton: FC<ButtonProps> = props => {
  const { id } = props;
  // const { pathname } = useLocation();
  const stopPropagation: MouseEventHandler = e => e.stopPropagation();

  if( !id ) return null;// <Button />;

  return (
    <Button
      label="Edit"
      component={ RRLink }
      to={ {
        pathname: `/locations/${ id }`,
      } }
      onClick={ stopPropagation }
    >
      <ContentEdit />
    </Button>
  );
}

export interface LocationGridDetailButtonProps extends ButtonProps {
  id?: string;
}

export const LocationGridDetailButton: FC<LocationGridDetailButtonProps> = props => {
  const { id, resource } = props;
  const stopPropagation: MouseEventHandler = e => e.stopPropagation();

  if( !id ) return <Button />;

  return (
    <Button
      label="Detail"
      component={ RRLink }
      to={ {
        pathname: `/${ resource }/${ id }/show`,
      } }
      onClick={ stopPropagation }
    >
      <ImageEye />
    </Button>
  );
}

export interface LocationAddButtonProps extends ButtonProps {
  id?: string;
}

export const LocationAddButton: FC<LocationAddButtonProps> = props => {
  const { id: organization, label = 'Add Location' } = props;
  const stopPropagation: MouseEventHandler = e => e.stopPropagation();

  if( !organization ) return <Button />;

  return (
    <Button
      label={ label }
      component={ RRLink }
      to={ {
        pathname: '/locations/create',
        state: {
          record: { organization }
        },
      } }
      onClick={ stopPropagation }
    >
      <ContentAdd />
    </Button>


  );
}

// export interface MemberAddButtonProps extends ButtonProps {
//   id?: string;
// }
//  
// export const MemberAddButton: FC<MemberAddButtonProps> = props => {
//   const { id: organization, label = 'Add Member' } = props;
//   const stopPropagation: MouseEventHandler = e => e.stopPropagation();
//  
//   if( !organization ) return <></>;
//  
//   return (
//     <Button
//       label={ label }
//       component={ RRLink }
//       to={ {
//         pathname: '/members/create',
//         state: {
//           record: { organization }
//         },
//       } }
//       onClick={ stopPropagation }
//     >
//       <ContentAdd />
//     </Button>
//  
//  
//   );
// }

export interface LocationModeButtonProps extends ButtonProps {
  id?: string;
  total?: number;
  shown: number;
}


export const LocationMoreButton: FC<LocationModeButtonProps> = props => {
  const { id, total, shown } = props;
  const stopPropagation: MouseEventHandler = e => e.stopPropagation();

  if( !id || !total || shown >= total ) return <></>;

  return (
    <Button
      label={ `All ${ total } locations` }
      component={ RRLink }
      to={ {
        pathname: '/locations', // `/locations?filter=${ JSON.stringify( { organization: id } ) }`
      } }
      onClick={ stopPropagation }
    >
      <ContentList />
    </Button>


  );
}

export const TabbedShowOrganization: FC<Omit<TabbedShowLayoutProps, 'children'>> = props => {
  const { record } = props;
  // const shown = 5;
  // const { id } = useParams<{ id: string }>();
  // const { total } = useGetManyReference( 'locations', {
  //   target: 'organization',
  //   id,
  //   pagination: { page: 1, perPage: shown },
  //   sort: { field: 'name', order: 'ASC' },
  //   filter: {},
  //   meta: 'organizations',
  // }, { enabled: !!id } );
  // const { loaded: childrenLoaded, total: childrenCount } = useGetManyReference( 'organizations', 'parent', id, { page: 1, perPage: 1000 }, { field: 'name', order: 'ASC' }, { }, 'organizations', { enabled: !!id } );
  // const { isLoading: isLoadingAssets, total: assetsCount } = useGetManyReference( 'assets', {
  //   target: 'tags',
  //   id,
  //   pagination: { page: 1, perPage: 1000 },
  //   sort: { field: 'subject', order: 'ASC' },
  //   filter: {},
  //   meta: 'organizations',
  // } );
  // const { isLoading: isLoadingTeam, total: teamCount } = useGetManyReference( 'members', {
  //   target: 'organization',
  //   id,
  //   pagination: { page: 1, perPage: 1000 },
  //   sort: { field: 'role', order: 'ASC' },
  //   filter: {},
  //   meta: 'organizations',
  // }, { enabled: record?.isRoot } );

  // const locationGridRowClick: RowClickFunction = ( id ) => {
  //   return `/locations/${ id }/show`
  // }
  // const memberGridRowClick: RowClickFunction = ( id ) => {
  //   return `/members/${ id }/show`
  // }

  return (
    <TabbedShowLayoutWithLocation basePath='organization'  { ...props } >
      <Tab label="detail" >
        <TextField source="name" />
        { record?.parent &&
          <ReferenceField source="parent" reference="organizations" link="show"  >
            <ChipField source="name" />
          </ReferenceField>
        }
        <ReferenceArrayField label="Amenity Options" source="amenityTags" reference="tags" >
          <SingleFieldList >
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        { ( record?.children || [] ).length > 0 &&
          <ReferenceArrayField source="children" reference="organizations"  >
            <SingleFieldList linkType="show" >
              <ChipField source="name" style={ { marginRight: '8px' } } />
            </SingleFieldList>
          </ReferenceArrayField>
        }
        <TextField source="fontHeader" />
        <TextField source="fontBody" />
        <ColorField source="colorPrimary" label="Primary Color" />
        <ColorField source="colorSecondary" label="Secondary Color" />
        <SelectField source="iconStyle" choices={ makeChoices( iconStyles ) } />
        <SelectField source="amenityLayout" choices={ makeChoices( amenityLayouts ) } />

        <BooleanColorField label='Enable charities' source='disableCharities' invert />
        <NumberField source='valuePerImpression' options={ { style: 'currency', currency: 'USD' } } />
        <NumberField source='valuePerReview' options={ { style: 'currency', currency: 'USD' } } />

        <TimestampsField source="createdAt" label="Created" />
        <TimestampsField source="updatedAt" label="Updated" />

        {/* <Divider style={ { marginTop: '16px', marginBottom: '16px' } } /> */ }

        { /*
             <ReferenceManyField
             label="Locations"
             reference="locations"
             target="organization"
             sort={ { field: 'name', order: 'ASC' } }
             page={ 1 }
             perPage={ shown }
             pagination={ <MuiToolbar>
             <Box sx={ { flexGrow: 1 } } />
             <LocationMoreButton shown={ shown } total={ total } id={ id } />

             </MuiToolbar>
             }
             >

             <Datagrid
             header={ <DatagridNoHeader /> }
             rowClick={ locationGridRowClick }
             bulkActionButtons={ false }
             >
             <TextField source="name" />
             <TextField label="State" source="addressComponents.short.state" />
             <TextField label="City" source="addressComponents.short.city" />
             <LocationGridEditButton />
             </Datagrid>

             </ReferenceManyField>
           */ }
      </Tab >

      {/* 
          <Tab path="locations" label={ `Locations ${ loaded && total ? `(${ total })` : '' }` } >
          <ReferenceManyField label="" reference="locations" target="organization" sort={ { field: 'name', order: 'ASC' } } >
          <Datagrid rowClick={ locationGridRowClick }>
          <TextField source="name" />
          <TextField source="address" />
          <RetailHoursField />
          <LocationGridEditButton basePath={ props.basePath } />
          </Datagrid>
          </ReferenceManyField>
          <LocationAddButton basePath={ props.basePath } />
          </Tab>
        */}


      < Tab path="assets" label='Branding Collateral' >
        <OrganizationShowAssets />
      </Tab >

      { /*
         record?.isRoot &&
         <Tab path="team" label={ `Team ${ !isLoadingTeam && teamCount ? `(${ teamCount })` : '' }` } >
         <ReferenceManyField label={ false } reference="members" target="organization" fullWidth>
         <Datagrid
         bulkActionButtons={ false }
         rowClick={ memberGridRowClick }
         >
         <ReferenceField label="Name" source="user" reference="users" link={ false } >
         <TextField source="name" />
         </ReferenceField>
         <ReferenceField label="Email" source="user" reference="users" link={ false } >
         <TextField source="email" />
         </ReferenceField>
         <TextField source="role" />
         <TimestampsField source="createdAt" label="Added" />
         </Datagrid>
         </ReferenceManyField>
         </Tab>
       */ }
    </TabbedShowLayoutWithLocation >
  );

}


export const useInheritedAmenityTags = ( source: 'parent' | 'organization' = 'parent' ): { data: Identifier[], loaded: boolean } => {
  const record = useRecordContext();
  // const { data: tree } = useGetTree( 'organizations' );
  // const [ tags, setTags ] = useState<Identifier[] | undefined>();
  //
  useEffect( () => undefined, [ source ] );
  // useEffect( () => {
  //   ( async (): Promise<Identifier[] | undefined> => {
  //     if( !get( record, source ) ) return [];
  //     const ascend = ( id: Identifier ): Identifier[] | undefined => {
  //       const current = tree?.find( o => o.id == id );
  //       if( !current ) return;
  //       if( current.amenityTags?.length ) return current.amenityTags;
  //       if( current.parent ) return ascend( current.parent );
  //     }
  //     setTags( ascend( get( record, source ) ) );
  //   } )();
  // }, [ tree, record, setTags, source ] );
  //  
  // return {
  //   data: tags || [],
  //   loaded: tags != undefined,
  // };

  return { data: record?.tags || [], loaded: true };
}

type ArrElement<ArrType> = ArrType extends readonly ( infer ElementType )[] ? ElementType : never;

export const AmenityOptionsInput: FC<InputProps> = props => {
  const record = useRecordContext( props );
  const { data: inheritedAmenityTags } = useInheritedAmenityTags();

  const choices = amenityChoices.map( c => pick( c, [ 'id', 'name', 'icon' ] ) )
    .filter( choice => record?.isRoot
      || inheritedAmenityTags.length == 0
      || inheritedAmenityTags.includes( choice.id ) );

  return (
    <DualListInput
      { ...props }
      choices={ choices }
      translateChoice={ false }
      addButtonLabel="Add"
      removeButtonLabel="Remove"
      addAllButtonLabel="Add All"
      removeAllButtonLabel="Remove All"
      availableItemsLabel="Inactive"
      selectedItemsLabel="Active"
      optionText={ ( choice: ArrElement<typeof choices> ) => (
        <>
          <ListItemIcon sx={ { minWidth: '40px' } }>{ choice?.icon }</ListItemIcon>
          <ListItemText primary={ choice.name } />
        </>
      ) }
    />
  );

}

// import { InputAdornment, Box } from '@mui/material';
// import { ColorSwatchButton } from './ColorInput';

export const makeChoices: ( choices: string[] ) => { id: string, name: string }[] = choices => (
  choices.map( c => ( { id: c, name: titleize( c ) } ) )
);
export const iconStyles = [ 'default', 'primary', 'secondary', 'black' ];
export const amenityLayouts = [ 'accordion', 'card' ];

export const OrganizationEdit: FC<EditProps> = props => {
  // const getTree = useGetTreeCallback( 'organizations' );
  // const validateNameIsUnique = useValidateNameIsUniqueIn( getTree );

  return (
    <Edit
      // @ts-ignore:7006
      redirect={ ( resource, id, data, state ) => {
        console.log( { resource, id, data, state } );
        return `organization/${ resource }`;
      } }
      mutationMode='pessimistic'
      { ...props }
    >
      <SimpleFormWithLocation basePath='organization' sanitizeEmptyValues toolbar={ <CustomToolbar /> } >
        <TextInput source="name" validate={ [ ...validateName ] } fullWidth />
        <AmenityOptionsInput label="Amenity options" source="amenityTags" />
        <SelectInput source="fontHeader" choices={ choices.font } fullWidth required />
        <SelectInput source="fontBody" choices={ choices.font } fullWidth required />
        <ColorInput source="colorPrimary" label="Primary color" />
        <ColorInput source="colorSecondary" label="Secondary color" />
        <SelectInput source="iconStyle" choices={ makeChoices( iconStyles ) } required />
        <BooleanCheckboxInput label='Enable charities' source='disableCharities' parse={ v => !v } format={ v => !v } />
        <NumberInput source='valuePerImpression'
          InputProps={ {
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          } }
        />
        <NumberInput source='valuePerReview'
          InputProps={ {
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          } }
        />
        <TextInput source='comment' fullWidth />
      </SimpleFormWithLocation>
    </Edit >
  );
}


export const OrganizationCreate: FC<CreateProps> = props => {
  // const getTree = useGetTreeCallback( 'organizations' );
  // const validateNameIsUnique = useValidateNameIsUniqueIn( getTree );

  return (
    <>
      {/* <MyBreadcrumb variant="actions" /> */ }
      <Create redirect="show" { ...props }>
        <SimpleForm sanitizeEmptyValues   >
          <TextInput source="name" validate={ [ ...validateName ] } />
        </SimpleForm>
      </Create>
    </>
  );
}

// export const OrganizationEditNode: FC<EditProps> = props => {
//   const { title, ...rest } = props;
//   // const getTree = useGetTreeCallback( 'organizations' );
//   // const validateNameIsUnique = useValidateNameIsUniqueIn( getTree );
//  
//   return (
//     <EditNode
//       actions={ <NodeEditActions  { ...rest } /> }
//       { ...props }
//     >
//       <SimpleForm sanitizeEmptyValues >
//         <TextInput source="name" validate={ [ ...validateName ] } />
//         <AmenityOptionsInput label="Amenity options" source="amenityTags" />
//       </SimpleForm>
//     </EditNode>
//   );
// }
//  
// export const OrganizationCreateNode: FC<CreateProps> = props => {
//   // const getTree = useGetTreeCallback( 'organizations' );
//   // const validateNameIsUnique = useValidateNameIsUniqueIn( getTree );
//  
//   return (
//     <CreateNode redirect="show" { ...props } >
//       <SimpleForm sanitizeEmptyValues   >
//         <TextInput source="name" validate={ [ ...validateName ] } />
//       </SimpleForm>
//     </CreateNode>
//   );
// }


// //// Amenity Picker
// done Add a “Select All”, “Deselect All”
//  
// TODO At the bottom add Amenity:  New, Clone, Edit, Delete.  These allow for new amenities via CRUD.
//      Amenities that come from our template cannot be deleted, but may be copied and edited as copies (name must be unique).
//                                                                                                                                                                                                                   
// done At top of dialog show organization, location (more than name or use more expressive name to make sure you are not moding the wrong location.
//                                                                                                                                        
//
// done Delete button should be disabled if linked resources, or option to promote (Chose promote)
//
// done Organizations should be a list of organizations or list of organization trees (not opened).
//
// done When you select an organization and go to the organization page, you should get information
// done such as administrative information for organization, number of locations for that organization,
// done list of locations (CRUD).
//
// done Details and Assets is correct, but nearby does not make any sense in this context.
//
// done Run check that name of organization and location must be unique so that you can use
// done the name in the BREADCRUMB and lookups without collisions.
// done Implement a  forbidden characters and forbidden name list:
//      [default, organization, alert, run, …] [<, >,!,?,*, #…] (lower case match).
//
// done The organization does not have hours nor other artifacts of location
// done (root level node where we will populate meta data about
// ???? the ownership of the organization, ownership contact information,
// done global ACLs, default rules and permissions for locations,
// done globalassets usable by the locations, etc).
// 
// TODO Any staff members with less than admin over the organization should see a list of the 
//      locations they can manage.
//
// TODO Admin for the organization should be able to manage credentials of staff that can update 
// done list of locations.  We can start with only organization admin accounts with no delegations 
// done or limitations to locations for the first version.  That level would allow the creation of 
// done global common assets like images for all locations (corporate logo), and also the creation 
// done of images for each location at the location level. 
//
// TODO Invitations: https://auth0.com/docs/brand-and-customize/email/send-email-invitations-for-application-signup
//
// done Amenities are objects that have images within them.


