import { GetRolesResult, Permissions } from '@react-admin/ra-rbac';
import { jwtDecode } from 'jwt-decode';
import { without } from 'lodash';
import { AuthProvider as RaAuthProvider, UserIdentity } from 'react-admin';
import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import { EmailPasswordPreBuiltUI } from 'supertokens-auth-react/recipe/emailpassword/prebuiltui.js';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import Multitenancy from 'supertokens-auth-react/recipe/multitenancy';
import Passwordless from 'supertokens-auth-react/recipe/passwordless/index.js';
import { PasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/passwordless/prebuiltui.js';
import Session from 'supertokens-auth-react/recipe/session';

export const getApiDomain = () => process.env.REACT_APP_API_SERVER || location.origin.replace( /admin\./, '' );
export const getApiBasePath = () => '/api/v1/auth';
export const getWebsiteDomain = () => location.origin;
export const getTenantId = () => process.env.REACT_APP_AUTH0_AUDIENCE


export const recipeDetails = {
  docsLink: 'https://supertokens.com/docs/emailpassword/introduction',
};

export const PreBuiltUIList = [ EmailPasswordPreBuiltUI, PasswordlessPreBuiltUI ]; // [EmailPasswordPreBuiltUI];

export const ComponentWrapper = ( props: { children: JSX.Element } ): JSX.Element => {
  return props.children;
};

let ranInit = false;

export const initSupertokens = () => {
  if( ranInit ) return;
  ranInit = true;
  const { REACT_APP_TENANT_ID: TENANT_ID } = process.env;
  // @ts-ignore: 2339
  const { TENANT_ID: tenantId } = ( window.INSTANCE_DATA || { TENANT_ID } ) as Record<string, string | undefined>;
  SuperTokens.init( {
    appInfo: {
      appName: 'Analog Concierge Admin',
      apiDomain: getApiDomain(),
      apiBasePath: getApiBasePath(),
      websiteDomain: getWebsiteDomain(),
      // websiteBasePath: '/#/auth',
    },

    defaultToSignUp: false,

    // usesDynamicLoginMethods: true,

    // // self-service routing to /auth
    // disableAuthRoute: true,

    // getRedirectionURL: async ( context ) => {
    //   if( context.action === 'SUCCESS' && context.newSessionCreated ) {
    //     if( context.redirectToPath !== undefined ) {
    //       // we are navigating back to where the user was before they authenticated
    //       return context.redirectToPath;
    //     }
    //     if( context.createdNewUser ) {
    //       // user signed up
    //     } else {
    //       // user signed in
    //     }
    //     return '/';
    //   }
    //   return undefined;
    // },

    // getRedirectionURL: async ( context ) => {
    //   if( context.action === 'TO_AUTH' ) {
    //     // This is called when we want to navigate to the login page.
    //     // By default, this will go to the configured websiteBasePath (/auth)
    //   } else if( context.action === 'SUCCESS' && context.newSessionCreated ) {
    //     // This is called when the user has successfully signed in / signed up.
    //     // By default, this will go to '/' or to 
    //     // the redirectToPath if it is set (the page from which the user was redirected to the auth page).
    //     let redirectToPath = context.redirectToPath;
    //     if( redirectToPath !== undefined ) {
    //       // we are navigating back to where the user was before they authenticated
    //       return redirectToPath;
    //     }
    //     if( context.createdNewUser ) {
    //       // user signed up
    //       return '/onboarding'
    //     } else {
    //       // user signed in
    //       return '/'
    //     }
    //   }
    //   // return undefined to let the default behaviour play out
    //   return undefined;
    // },

    recipeList: [
      EmailPassword.init( {

        getRedirectionURL: async ( context ) => {
          if( context.action === 'RESET_PASSWORD' ) {
            // called when the user clicked on the forgot password button
            return '/#/auth/reset-password'; // ?rid=emailpassword';
          }
          // return undefined to let the default behaviour play out
          console.log( 'getRedirectionURL', context.action );
          return undefined;
        },


        // preAPIHook: async ( context ) => {
        //   const { url, requestInit } = context;
        //  
        //   // let action = context.action;
        //   // if( action === 'EMAIL_EXISTS' ) {
        //   //  
        //   // } else if( action === 'SEND_RESET_PASSWORD_EMAIL' ) {
        //   //  
        //   // } else if( action === 'EMAIL_PASSWORD_SIGN_IN' ) {
        //   //  
        //   // } else if( action === 'EMAIL_PASSWORD_SIGN_UP' ) {
        //   //  
        //   // } else if( action === 'SUBMIT_NEW_PASSWORD' ) {
        //   //  
        //   // } else if( action === 'VERIFY_EMAIL' ) {
        //   //  
        //   // }
        //  
        //   return { requestInit, url };
        // },

        onHandleEvent: async ( context ) => {
          // if( context.action === 'PASSWORDLESS_RESTART_FLOW' ) {
          //   // TODO:
          // } else if( context.action === 'PASSWORDLESS_CODE_SENT' ) {
          //   // TODO:
          // } else {
          //   const { id, emails, phoneNumbers } = context.user;
          if( context.action === 'SUCCESS' ) {
            queryClient.invalidateQueries(); // solves the dead first Dashboard screen
            // if( context.isNewRecipeUser && context.user.loginMethods.length === 1 ) {
            //   // TODO: Sign up
            // } else {
            //   // TODO: Sign in
            // }
          }
          // }
          // console.log( context );
        },

      } ),
      Passwordless.init( {
        contactMethod: 'EMAIL',
        signInUpFeature: {
          resendEmailOrSMSGapInSeconds: 120,  // Mis-interpreted as time to enter code
        },
        onHandleEvent: async ( context ) => {
          if( context.action === 'PASSWORDLESS_RESTART_FLOW' ) {
            // TODO:
          } else if( context.action === 'PASSWORDLESS_CODE_SENT' ) {
            // TODO:
          } else {
            // const { id, emails, phoneNumbers } = context.user;
            if( context.action === 'SUCCESS' ) {
              if( context.isNewRecipeUser && context.user.loginMethods.length === 1 ) {
                // TODO: Sign up
              } else {
                // TODO: Sign in
              }
            }
          }
        }

      } ),
      Session.init( {
        // exposeAccessTokenToFrontendInCookieBasedAuth: true,
      } ),

      Multitenancy.init( {
        override: {
          functions: ( oI ) => {
            return {
              ...oI,
              getTenantId: ( _input ) => {
                return tenantId || 'UNKNOWN';
              }
            }
          }
        }
      } ),

      EmailVerification.init( {
        mode: 'REQUIRED',
      } ),
    ],

    // clientType: 'web',
    // cookieHandler?: CookieHandlerInput;
    // windowHandler?: WindowHandlerInput;
    // dateProvider?: DateProviderInput;
    // usesDynamicLoginMethods?: boolean;
    // languageTranslations?: {
    //   defaultLanguage?: string;
    //   currentLanguageCookieScope?: string;
    //   translations?: TranslationStore;
    //   translationFunc?: TranslationFunc;
    // };
    // enableDebugLogs?: boolean;
    // getRedirectionURL?: (
    //   context: GetRedirectionURLContext,
    //   userContext: UserContext
    // ) => Promise<string | undefined | null>;
    // style?: string;
    // useShadowDom?: boolean;
    // disableAuthRoute?: boolean;
    // defaultToSignUp?: boolean;
    privacyPolicyLink: 'https://analoginformatics.com/privacy',
    termsOfServiceLink: 'https://analoginformatics.com/terms',
    style: `
        [data-supertokens~=authPage] [data-supertokens~=headerSubtitle] {
            display: none;
        }
        [data-supertokens~=superTokensBranding] {
            display: none;
        }
        [data-supertokens~=authPage] .MuiCardHeader-root {
            padding: 1rem 0;
        }
        [data-supertokens~=container] {
             --palette-primary: 46, 71, 102;
             --palette-primaryBorder: 146, 171, 202;
            // --palette-background: 51, 51, 51;
            // --palette-inputBackground: 41, 41, 41;
            // --palette-inputBorder: 41, 41, 41;
            // --palette-textTitle: 255, 255, 255;
            // --palette-textLabel: 255, 255, 255;
            // --palette-textPrimary: 255, 255, 255;
            // --palette-error: 173, 46, 46;
            // --palette-textInput: 169, 169, 169;
            // --palette-textLink: 169, 169, 169;
            --palette-superTokensBrandingBackground: 200,  0, 0, 0.001;
            --palette-superTokensBrandingText: 200,  0, 0, 0.001;
        }
    `,
  }

  );

}

import { apiUrl, httpClient, queryClient } from './DataProvider';

export class SupertokensProvider implements RaAuthProvider {

  token: string | undefined;
  user: UserIdentity | undefined;
  // metadata: Record<string, unknown> | undefined;
  permissions: Permissions | undefined;
  calledLogin = false

  constructor() {
    initSupertokens();
  }

  public login = async (): Promise<void> => { }

  public checkError = async ( error: unknown ): Promise<void> => {
    if( error && !this.calledLogin && typeof error == 'object' && 'status' in error && typeof error.status == 'number' && [ 401, 403 ].includes( error.status ) ) {
      this.permissions = undefined;
      localStorage.removeItem( 'permissions' );
      this.calledLogin = true; // only redirect once
      return Promise.resolve(); // reject();
    }
    return Promise.resolve();
  }

  public checkAuth = async (): Promise<void> => {
    await Session.doesSessionExist();

    //
    await this.getPermissions( { route: 'dashboard' } );  // try to fix first load
  }

  public logout = async (): Promise<void> => {
    this.permissions = undefined;
    localStorage.removeItem( 'permissions' );
    await Session.signOut();
  }

  public getIdentity = async (): Promise<UserIdentity> => {
    try {
      const userId = await Session.getUserId();
      if( !userId ) return { id: 'unknown' }; // throw Error( 'No identity' );
      const { body } = await httpClient( `${ apiUrl }/auth/sessioninfo` );
      const info = JSON.parse( body ) as { metadata?: Record<string, unknown> };
      const { metadata = {} } = info || {};
      const {
        name: fullName,
        picture: avatar,
      } = metadata;
      return { id: userId, fullName, avatar } as UserIdentity;
    } catch( e ) {
      return { id: 'unknown' }; // throw Error( 'No identity' );
    }
  }

  public getPermissions = async ( _params: unknown ): Promise<Permissions> => {
    try {
      if( this.permissions ) return this.permissions;
      const token = this.token || ( await Session.getAccessToken() ) || '';
      const user = this.user || await this.getIdentity();
      this.permissions = this.decodePermissions( token, user );
      // console.log( this.permissions );
      return this.permissions;
    } catch( e ) {
      console.error( e );
    }
    return [];
  }

  public getToken = async (): Promise<string> => {
    return ( await Session.getAccessToken() ) || '';
  }

  public getRoles = async (): Promise<GetRolesResult> => ( {} );

  private decodePermissions = ( token: string, user?: UserIdentity ): Permissions => {
    const aliases = {
      locations: [
        'alerttemplates',
        'anomalyreportdefinitions',
        'anomalyreportemails',
        'anomalyreports',
        'dashboardalerts',
        'inboundtexts',
        'organizations',
        'outboundalerts',
        'outboundmessages',
        'reputationclicks',
        'reputationplatforms',
        'reputationprofiles',
        'reputationreferrals',
        'reputationreportschedules',
        'reputationresponsetemplates',
        'reputationreviews',
        'reputationsubjectreviews',
        'reputationsubjects',
        'reputationsummaries',
        'reputationvisualizations',
      ]
    };
    const decoded = jwtDecode( token ) as { 'st-perm': { v: string[] }, 'st-role': { v: string[] } };
    const { v: decodedList = [] } = decoded[ 'st-perm' ];
    const permissionsList = decodedList.flatMap( p => {
      if( !p.includes( 'locations' ) ) return [ p ];
      return [ p, ...aliases.locations.map( a => p.replace( 'locations', a ) ) ]; // generalize this later
    } )

    const fields: Record<string, unknown> = {
      owner: [ user?.sub ],
    };
    const permSets: { re: RegExp, permissions: Permissions }[] = [
      {
        re: /^(read:(\w+)|(\w+)\.read)$/, // read:providers or providers.read
        permissions: [
          { resource: 'resource', action: [ 'read', 'list', 'show' ] },
          { resource: 'resource.*', action: [ 'read' ] },
          // { resource: 'resource.tab.*', action: 'read' },
          // { resource: 'resource.tab.externalIds', action: 'read', type: 'deny' },
          { resource: 'resource.externalIds', action: 'read', type: 'deny' },  // TODO this blocks everyone
        ]
      }, {
        re: /^(write:(\w+)|(\w+)\.write)$/,  // write:providers or providers.write
        permissions: [
          { resource: 'resource', action: [ 'write', 'create', 'edit', 'clone' ] },
          { resource: 'resource.*', action: [ 'write' ] },
        ],
      }, {
        re: /^write:(\w+):owner$/, // write:providers:owner (Provider Staff)
        permissions: [
          { resource: 'resource', action: [ 'read', 'create' ] },
          { resource: 'resource', action: [ 'write', 'edit', 'clone' ], record: { owner: fields[ 'owner' ] } },
          { resource: 'resource.*', action: [ 'write' ] },
        ],
      }, {
        re: /^(delete:(\w+)|(\w+)\.delete)$/,  // delete: providers or providers.delete
        permissions: [ { resource: 'resource', action: [ 'delete', 'export' ] } ],
        // }, {
        //   re: /^system$/,  // system admin
        //   permissions: [ { resource: 'system', action: [ 'manage', 'reseller' ] } ],
        //   // }, {
        //   //   re: /^system(:(\w+))?$/,  // config scope
        // //   permissions: [ { resource: 'system', action: [ 'manage' ] } ],
      }
    ];

    const permissions: Permissions = ( permissionsList )
      .flatMap( ( perm ): Permissions => {
        const resource = perm.includes( ':' ) ? perm.split( ':' )[ 1 ] : perm.split( '.' )[ 0 ];
        const { permissions = [] } = permSets.find( p => p.re.test( perm ) ) || {};
        return permissions.map( perm => ( {
          ...perm,
          resource: ( Array.isArray( perm.resource ) ? perm.resource : [ perm.resource ] ).map( s => s.replace( /resource/, resource ).replace( /all/, '*' ) ),
        } ) );
      } );

    // Just for locations, remove 'create' unless that specific perm is granted
    if( !( permissionsList.includes( 'create:locations' ) || permissionsList.includes( 'locations.create' ) ) ) {
      const locPermIdx = permissions.findIndex( p => p.resource == 'locations' && p.action.includes( 'create' ) );
      if( locPermIdx >= 0 ) {
        permissions[ locPermIdx ].action = without( permissions[ locPermIdx ].action, 'create' );
      }
    }

    if( permissionsList.includes( 'system' ) || permissionsList.includes( 'system.write' ) ) {
      permissions.push( { resource: 'system', action: [ 'manage', 'reseller' ] } );
    }
    if( permissionsList.includes( 'system:reseller' ) ) {
      permissions.push( { resource: 'system', action: [ 'reseller' ] } );
    }


    return permissions;
  }

}
