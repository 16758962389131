import { Box, Card, CardContent, CardHeader, useTheme } from '@mui/material';
import { Pie } from '@nivo/pie';
import { addDays } from 'date-fns';
import { Dayjs } from 'dayjs';
import { get, reverse, set } from 'lodash';
import { default as queryString } from 'query-string';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Loading, useRedirect } from 'react-admin';
import { ChartProps, colorPalettes, elevation, PieDatum } from './DashboardWidget';
import { makePieChartTooltip } from './DashboardTooltip';
import { getMessagePurposes, mapMessagePurpose } from './DashboardSentMessages';
import { apiUrl, httpClient } from './DataProvider';


interface MessagesToSendDatum {
  date: string;
  type: string;
  count: string;
}


export const DashboardScheduledMessages: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const redirect = useRedirect();
  const isSmall = false;
  const [ data, setData ] = useState<PieDatum[]>( [] );
  const [ isLoading, setIsLoading ] = useState( true );
  const total = useMemo( () => data.reduce( ( tot, d ) => tot += d.value, 0 ), [ data ] );

  const fetchSummary = useCallback( async ( date?: Dayjs | null ) => {
    const { body } = await httpClient( `${ apiUrl }/summaries/waitingByDateByType${ date ? `?date=${ encodeURIComponent( date.toISOString() ) }` : '' }` );
    return body;
  }, [ httpClient ] );

  useEffect( () => {
    ( async () => {
      if( data.length || !isLoading ) return;
      const json = localStorage.getItem( 'dashboard-send' );
      if( data.length || !json || !isLoading ) return;
      try {
        setData( JSON.parse( json ) );
        setIsLoading( false );
      } catch( e ) { return; }
    } )()
  }, [ data, setData, isLoading ] );

  useEffect( () => {
    ( async () => {
      const body = await fetchSummary( date );
      const raw = JSON.parse( body ) as MessagesToSendDatum[];
      // console.log( 'scheduled raw', raw );
      const data: Record<string, number> = raw.reduce( ( data, d ) => {
        const key = mapMessagePurpose( d.type );
        set( data, key, d.count + get( data, key, 0 ) );
        return data;
      }, {} );
      let rows = Object.entries( data ).map( ( [ id, value ] ) => ( { id, value } ) ) as PieDatum[];
      if( rows.length == 0 ) {
        // @ts-ignore:2322
        rows = [ { id: null, label: 'No data', value: true } ];
      }
      setData( rows );
      setIsLoading( false );
      // console.log( 'scheduled', rows );
      localStorage.setItem( 'dashboard-send', JSON.stringify( rows ) );
    } )()
  }, [ setData, fetchSummary, date ] );

  const isEmpty = useMemo( () => data[ 0 ]?.id === null, [ data ] );
  const colors = useMemo( () => reverse( [ ...colorPalettes.discretePrimary ] ), [ colorPalettes ] );

  return (

    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title="Scheduled Messages"
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `7 Days Starting ${ date.format( 'MMM D' ) }` : 'Next 7 Days' }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      // avatar={ <DashboardAlertIcon /> }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          : <Pie
            data={ data }
            margin={ { top: 0, right: 160, bottom: 10, left: 10 } }
            sortByValue
            arcLabelsSkipAngle={ 20 }
            isInteractive={ !isEmpty }
            arcLabel={ ( d ) => `${ ( 100 * d.value / total ).toFixed( 0 ) }% (${ d.value })` }
            arcLabelsRadiusOffset={ 0.6 }
            enableArcLabels={ !isEmpty }
            enableArcLinkLabels={ false }

            onClick={ ( datum ) => {
              const { id } = datum as PieDatum;
              const messagePurposes = getMessagePurposes( id );
              const filter = {
                delimiter: ';',
                messagePurpose: messagePurposes.join( ';' ),
                reminderState: 'waiting',
                reminderRequestedFireDate: `gt:${ new Date().toISOString().slice( 0, 10 ) },lte:${ addDays( new Date(), 7 ).toISOString().slice( 0, 10 ) }`,
              };
              redirect( `/messaging/reminders?${ queryString.stringify( { filter: JSON.stringify( filter ) } ) }` );
            } }

            theme={ {
              background: theme.palette?.background?.default,
              axis: {
                legend: { text: { fontSize: isSmall ? 16 : 18 } },
                ticks: { text: { fontSize: isSmall ? 0 : 14 } },
              },
              labels: { text: { fill: '#333', fontSize: 14, fontWeight: 'bold' } },
              legends: { text: { fontSize: 14 } },
              text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
              tooltip: {
                container: {
                  backgroundColor: theme.palette?.background?.default,
                  color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                },
              },
            } }
            tooltip={ makePieChartTooltip( theme, total ) }

            colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } // { scheme: 'accent' } }
            width={ 500 }
            height={ 400 }
            legends={ [
              {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 110,
                translateY: -80,
                itemsSpacing: 4,
                itemWidth: 80,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ] }
          />
        }
      </CardContent>
    </Card>

  );

}


