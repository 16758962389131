import { Box, Chip, ChipProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { titleize } from 'inflection';
import get from 'lodash/get';
import { FC, ReactElement } from 'react';
import { AutocompleteArrayInput, AutocompleteArrayInputProps, TextFieldProps, useRecordContext } from 'react-admin';

export interface AutocompleteTextArrayInputProps extends Omit<AutocompleteArrayInputProps, 'choices' | 'optionText' | 'optionValue' | 'children'> {
  choices: string[];
}

/**
 * An Input component for an autocomplete field, using an array of strings for the options
 *
 * Pass possible options as an array of strings in the 'choices' attribute.
 *
 * @example
 * const choices = [ 'Male', 'Female' ];
 * <AutocompleteTextArrayInput source="gender" choices={choices} />
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [ 'myroot.gender.male', 'myroot.gender.female' } ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <AutocompleteTextArrayInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <TextField> component
 *
 * @example
 * <AutocompleteTextArrayInput source="author_id" options={{ color: 'secondary' }} />
 */
export const AutocompleteTextArrayInput = ( props: AutocompleteTextArrayInputProps ): ReactElement => {
  const { choices: choiceStrings, ...rest } = props;
  // const record = useRecordContext( props );
  const choices: AutocompleteArrayInputProps[ 'choices' ] = choiceStrings.map( s => ( { id: s, name: s } ) );

  return (
    <AutocompleteArrayInput
      choices={ choices }
      optionText="id"
      { ...rest }
    />
  );
}

// Here's how to do ArrayInput with strings/numbers
// https://github.com/marmelab/react-admin/pull/8090/files
//    <ArrayInput source='emailList' fullWidth >
//      <SimpleFormIterator inline disableReordering >
//        <TextInput source='' label='Email' helperText={ false } fullWidth />
//      </SimpleFormIterator>
//    </ArrayInput>



export interface TextArrayFieldProps extends Omit<TextFieldProps, 'variant'>,
  Partial<Pick<ChipProps, 'size' | 'variant'>> { }

const TextArrayField: FC<TextArrayFieldProps> = props => {
  const { source, size, variant } = props;
  const theme = useTheme();
  const record = useRecordContext( props );

  // This created duplicate labels
  // <Labeled label={ label } source={ source }> 

  return (
    < Box
      sx={ {
        flexGrow: 1,
        position: 'relative',
      }
      }
    >
      <Box
      >
        { ( ( source && get( record, source, [] ) ) || [] ).map( ( item: string ) => (
          <Chip
            label={ titleize( item ) }
            key={ item }
            sx={ {
              margin: theme.spacing( 0.5, 0.5, 0.5, 0 ),
            } }
            size={ size }
            variant={ variant }
          />
        )
        ) }
      </Box>
    </Box >

  )
}

export default TextArrayField;
