import { Create as EditIcon, Lock, NoEncryption, RemoveRedEye as ImageEye } from '@mui/icons-material';
import { Box, CircularProgress, Grid, LinearProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useTheme } from '@mui/material';
import { List, ListActions, IfCanAccess } from '@react-admin/ra-rbac';
import CryptoJS from 'crypto-js';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AutocompleteInput, AutocompleteInputProps, BooleanField, BooleanFieldProps, Button, Create, CreateButton, CreateProps, Datagrid, DatagridHeader, Edit, EditButton, EditProps, FilterButton, FunctionField, Labeled, ListProps, maxLength, minLength, RaRecord, RecordContextProvider, RedirectionSideEffect, ReferenceField, regex, required, RowClickFunction, SearchInput, SelectField, Show, ShowProps, SimpleFormProps, TextField, TextFieldProps, TextInput, TopToolbar, useGetList, useGetOne, useRecordContext, WithRecord } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { AdminShowLayout, AdminSimpleForm, useAdminListAppLocation } from './Admin';
import { apiUrl, choices, httpClient } from './DataProvider';
import { LangApi } from './langs';
import { LanguageBox, LanguageInput, LanguageSelect, LanguageSelectProps } from './LanguageSelect';
import { CustomToolbar } from './ReminderTemplateLists';
import { ResetButton, ShowActionsWithReset } from './ShowActionsWithReset';
import { TimestampsField } from './TimestampsField';
import { Childless } from './types';

const hashString = ( s: string ) => CryptoJS.MD5( s ).toString( CryptoJS.enc.Base64 ).replace( '+', '-' ).replace( '/', '_' ).replace( /=+$/, '' );

export { default as TextIcon } from '@mui/icons-material/Title';

// const TextFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
//   <Filter { ...props }>
//     <LanguageInput label="Language" source='lang' alwaysOn />
//     <SearchInput source="q" alwaysOn />
//     {/* <TextInput source='name' label='Variable name' /> */ }
//     {/* <VariableNameInput source='name' label='Variable name' /> */ }
//   </Filter>
// );

const TextReferenceCountField: FC<TextFieldProps> = () => {
  const record = useRecordContext();
  const { id } = record || {};
  const [ counts, setCounts ] = useState<number[] | undefined>();

  const fetchCounts = useCallback( async () => {
    const { json } = await httpClient( `${ apiUrl }/texts/${ id }/actions/getReferences` );
    const refs = json as TextReference[];
    const templateCount = refs.filter( j => j.type == 'messagetemplates' ).length;
    const sms = refs.filter( j => [ 'sms', 'smsShort' ].includes( j.section || '' ) ).length;
    const email = refs.filter( j => [ 'email' ].includes( j.section || '' ) ).length;
    const voice = refs.filter( j => [ 'voice' ].includes( j.section || '' ) ).length;
    return [ templateCount, refs.length, sms, email, voice ];
  }, [ httpClient ] );

  useEffect( () => {
    ( async () => {
      if( counts !== undefined ) return;
      const c = await fetchCounts();
      setCounts( c );
    } )()
  }, [ id ] );

  if( counts === undefined ) return <LinearProgress />;
  const [ count, total, sms, email, voice ] = counts;
  const short = [
    sms ? `${ sms }s` : '',
    email ? `${ email }e` : '',
    voice ? `${ voice }v` : '',
    `${ total - count }C`,
    `${ count }T`,
  ].filter( s => s ).join( ' ' );
  const long = [
    sms ? `sms: ${ sms }` : '',
    email ? `email: ${ email } ` : '',
    voice ? `voice: ${ voice }` : '',
    `Components: ${ total - count } `,
    `Templates: ${ count } `,
  ].filter( s => s ).join( ' ' );
  return (
    <Tooltip title={ long } >
      <Typography sx={ { textWrap: 'nowrap' } }>{ short }</Typography>
    </Tooltip>
  )

}

interface TextReference {
  id: string;
  type: string;
  name: string;
  names: string[];
  section?: 'sms' | 'smsShort' | 'subject' | 'email';
}

interface TextExpandPreviewProps {
  data: TextReference,
  noPreview?: boolean,
  lang?: string;
}

const TextExpandPreview: FC<TextExpandPreviewProps> = ( { data, noPreview, lang = 'en' } ) => {
  const { id, type, section, names } = data;
  const theme = useTheme();
  const { data: snippet } = useGetOne<RaRecord>( 'snippets', { id }, { enabled: type == 'snippets' } );
  const { data: htmlComponent } = useGetOne<RaRecord>( 'htmlcomponents', { id }, { enabled: type == 'htmlcomponents' } );
  const { data: messageTemplate } = useGetOne<RaRecord>( 'messagetemplates', { id }, { enabled: type == 'messagetemplates' } );
  const [ preview, setPreview ] = useState<string | undefined>();

  const fetchPreview = useCallback( async ( id: string, format: string ) => {
    return await httpClient( `${ apiUrl }/preview/messagetemplates/${ id }/${ format }${ !lang || lang == 'en' ? '' : `?lang=${ lang }` }` );
  }, [ lang, data ] );

  useEffect( () => {
    ( async () => {
      if( type != 'messagetemplates' || !section ) return;
      const { body: p } = await fetchPreview( id, section );
      setPreview( p );
    } )();
  }, [ id, type, section ] );

  const hasNameAsVar = ( s: string ): boolean => {
    return !!names.find( name => s.includes( `{{${ name }}}` ) );
  }

  const formatAsSms = useCallback( ( value?: string ) => {
    return ( ( value || '' ) as string )
      .split( /\n/ )
      .map( ( s, key ) => {
        const matches = s.match( /([^{]+|\{\{[^}]+\}\})/g ) || [];
        if( matches.length > 1 || ( matches[ 0 ] || '' )[ 0 ] == '{ ' ) {
          return (
            <Typography key={ key }>
              { matches.map( ( m, k ) => {
                // if( m[ 0 ] != '{' ) return m;
                // if( m != `{{${ data.name }}}` ) return m;
                if( !hasNameAsVar( m ) ) return m;
                return <span style={ {
                  // color: theme.palette.error.main,
                  // fontWeight: 'bold',
                  border: `2px solid ${ theme.palette.error.main }`,
                } } key={ k }>{ m }</span>;
              } ) }
            </Typography>
          );
        }
        return <Typography key={ key }> { s || ( <span>&nbsp;</span> ) } </Typography>;
      } );
  }, [] );

  const SmsBox: FC<{ value?: string }> = ( { value } ) => {
    return (
      <Box
        sx={ {
          minHeight: '10rem',
          marginTop: 0,
          padding: '1rem',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.palette.divider,
          overflowWrap: 'break-word',
          '& .MuiTypography-root': {
            fontFamily: 'monospace',
            fontSize: '90%',
          },
        } }
      >
        { formatAsSms( value ) }
      </Box>
    );
  }

  const EmailSubjectBox: FC<{ value?: string }> = ( { value } ) => {
    return (
      <Box
        sx={ {
          // minHeight: '3rem',
          marginTop: 0,
          padding: '1rem',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: theme.palette.divider,
          overflowWrap: 'break-word',
          '& .MuiTypography-root': {
            fontFamily: 'monospace',
            fontSize: '90%',
          },
        } }
      >
        { formatAsSms( value ) }
      </Box>
    );
  }

  if( type == 'snippets' && snippet ) {
    return (
      <EmailSubjectBox value={ snippet.value } />
    );
  }

  if( type == 'htmlcomponents' && htmlComponent ) {
    if( noPreview ) return <>preview disabled</>;
    return (
      <Stack>
        <Labeled label='Template' >
          <SmsBox value={ htmlComponent.value } />
        </Labeled>
        {/* <Labeled label='Preview' > <SmsBox value={ preview } /> </Labeled> */ }
      </Stack>
    );
  }

  if( type == 'messagetemplates' && section == 'smsShort' && messageTemplate ) {
    return (
      <Stack>
        <Labeled label='Template' >
          <SmsBox value={ messageTemplate.smsShortMessage } />
        </Labeled>
        <Labeled label='Preview' >
          <SmsBox value={ preview } />
        </Labeled>
      </Stack>
    );
  }

  if( type == 'messagetemplates' && section == 'sms' && messageTemplate ) {
    return (
      <Stack>
        <Labeled label='Template' sx={ { marginTop: 6 } } >
          <SmsBox value={ messageTemplate.smsMessage } />
        </Labeled>
        <Labeled label='Preview' >
          <SmsBox value={ preview } />
        </Labeled>
      </Stack>
    );
  }


  if( type == 'messagetemplates' && section == 'subject' && messageTemplate ) {
    return (
      <Stack>
        <Labeled label='Template' >
          <EmailSubjectBox value={ messageTemplate.emailSubjectLine } />
        </Labeled>
        <Labeled label='Preview'   >
          <EmailSubjectBox value={ preview } />
        </Labeled>
      </Stack>
    );
  }

  if( type == 'messagetemplates' && section == 'email' && messageTemplate ) {
    if( noPreview ) return <>preview disabled</>;
    return (
      <Labeled label='Email' >
        <iframe
          width={ 600 }
          height={ 1200 }
          seamless
          src={ `${ apiUrl }/preview/messagetemplates/${ id }/email?${ [
            `${ !lang || lang == 'en' ? '' : `lang=${ lang }` }`,
            ...names.map( n => `names=${ n }` )
          ].filter( s => !!s ).join( '&' ) }` }
          style={ {
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.divider,
          } }
        />
      </Labeled>
    );
  }

  return null;
}

interface TextExpandReferencesProps {
  lang?: string;
}

const TextExpandReferences: FC<TextExpandReferencesProps> = ( props ) => {
  const { lang = 'en' } = props;
  const record = useRecordContext();
  const { id } = record || {};
  const [ data, setData ] = useState<TextReference[] | undefined>();

  const fetchReferences = useCallback( async (): Promise<TextReference[]> => {
    const { json } = await httpClient( `${ apiUrl }/texts/${ id }/actions/getReferences` );
    return json as TextReference[];
  }, [ httpClient ] );

  useEffect( () => {
    ( async () => {
      if( data !== undefined ) return;
      const d = await fetchReferences();
      setData( d );
    } )()
  }, [ id ] );

  if( !data ) return <CircularProgress size='small' />;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { data.map( ( d, key ) => {
          const noPreview = data.slice( 0, key + 1 ).filter( s => s.type == d.type && s.section == 'email' ).length > 3;

          return (
            <TableRow key={ key }
              sx={ {
                verticalAlign: 'top',
              } }
            >
              <TableCell>
                <Stack>
                  <Typography>{ d.type }</Typography>
                  <Typography>{ d.section }</Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Box>
                  <Link to={ `/${ d.type }/${ d.id }/show` } >{ d.id }</Link>
                </Box>
                <TextExpandPreview data={ d } noPreview={ noPreview } lang={ lang } />
              </TableCell>
            </TableRow>
          );
        } )
        }
      </TableBody>
    </Table>
  );
}


export interface VariableNameInputProps extends Omit<AutocompleteInputProps, 'choices'> {
  custom?: boolean;
}

export const VariableNameInput: FC<VariableNameInputProps> = props => {
  const { custom, label, ...rest } = props;
  const { data, isLoading } = useGetList<RaRecord>( 'texts', {
    pagination: { perPage: 500, page: 1 },
    sort: { field: 'name', order: 'ASC' },
    filter: { lang: 'en', custom },
  } );

  const choices = useMemo( () => {
    return data?.map( d => ( {
      id: d.name,
      name: `{{${ d.name }}}`,
    } ) ) ?? [];
  }, [ data ] );

  return (
    <AutocompleteInput
      label={ label }
      choices={ choices }
      translateChoice={ false }
      isLoading={ isLoading }
      { ...rest }
      sx={ {
        minWidth: '20rem',
      } }
    />
  );

}

export const VariableNameField: FC<TextFieldProps> = props => {
  const { label, source } = props;

  return (
    <FunctionField label={ label } source={ source }
      render={ ( r: RaRecord ) => (
        <Typography
          fontSize='90%'
        >
          { `{{${ r.name }}}` }
        </Typography>
      ) }
    />
  );
}

export const TextList: FC<Childless<ListProps>> = props => {
  useAdminListAppLocation( 'text.texts' );

  const filters = [
    <LanguageInput label="Language" source='lang' alwaysOn />,
    <VariableNameInput label="Variable Name" source="name" alwaysOn />,
    <SearchInput source="q" alwaysOn />,
  ];

  return (
    <List
      { ...props }
      exporter={ false }
      perPage={ 25 }
      filters={ filters }
      filterDefaultValues={ { lang: 'en' } }
      sort={ { field: 'id', order: 'ASC' } }
      actions={ <ListActions hasCreate={ false } /> }
    >
      <Datagrid
        bulkActionButtons={ false }
        rowClick='show'
        expandSingle
        // expand={ <TextExpandReferences /> }
        sort={ { field: 'id', order: 'ASC' } }
        sx={ {
          '& .column-locked, & .column-locked span ': {
            maxWidth: 'fit-content',
          },
          '& .column-value, & .column-value span ': {
            maxWidth: '80%',
          },
        } }
        header={ <DatagridHeader /> }
      >

        {/* <TextField label="Code" source="lang" /> */ }
        {/* <TextField label="Name" source="name" /> */ }
        <TextField label="Text" source="value" />

        <TextReferenceCountField source='references' sortable={ false } textAlign='right' />

        <VariableNameField source='name' label='Variable name' />

        <LanguageField label='Language' source='lang' />

        <TimestampsField label="Updated" source="updatedAt" noWrap />
        <LockedField source='locked' FalseIcon={ null } sortable={ false } />

      </Datagrid>
    </List >
  );
}

const CustomTextListActions: FC = () => (
  <TopToolbar>
    <FilterButton />
    <CreateButton to='/customtexts/create' />
  </TopToolbar>
);

export const CustomTextList: FC<Childless<ListProps>> = props => {
  useAdminListAppLocation( 'text.customtexts' );

  const rowClick: RowClickFunction = ( id ) => `/customtexts/${ encodeURIComponent( id ) }/show`;
  const filters = [
    <LanguageInput label="Language" source='lang' alwaysOn />,
    <VariableNameInput label="Variable Name" source="name" alwaysOn custom />,
    <SearchInput source="q" alwaysOn />,
  ];

  return (
    <IfCanAccess resource='system' action='manage'>
      <List
        { ...props }
        actions={ <CustomTextListActions /> }
        filters={ filters }
        exporter={ false }
        perPage={ 25 }
        filter={ { custom: 'true' } }
        filterDefaultValues={ { lang: 'en' } }
        sort={ { field: 'id', order: 'ASC' } }
        resource='texts'
        storeKey='customTexts.listParams'
      >
        <Datagrid
          bulkActionButtons={ false }
          rowClick={ rowClick }
          expandSingle
          sort={ { field: 'id', order: 'ASC' } }
          sx={ {
            '& .column-locked, & .column-locked span ': {
              maxWidth: 'fit-content',
            },
            '& .column-value, & .column-value span ': {
              maxWidth: '80%',
            },
          } }
          header={ <DatagridHeader /> }
        >

          <TextField label="Text" source="value" />

          <TextReferenceCountField source='references' sortable={ false } textAlign='right' />

          <FunctionField label="Variable name" source="name"
            render={ ( r: RaRecord ) => (
              <Typography
                // color={ theme.palette.grey[ '500' ] }
                fontSize='90%'
              >
                { `{{${ r.name }}}` }
              </Typography>
            ) }
          />

          <LanguageField label='Language' source='lang' />

          <TimestampsField label="Updated" source="updatedAt" noWrap />

        </Datagrid>
      </List>
    </IfCanAccess>
  );
}

const LanguageField: FC<TextFieldProps> = () => {
  return (
    <Labeled label='Language' >
      <ReferenceField label={ false } source="lang" reference='languages' >
        <FunctionField label={ false } source="lang" render={ ( lang: LangApi ) => {
          return ( <LanguageBox lang={ lang } /> )
        } } />
      </ReferenceField>
    </Labeled>
  );
}


interface TranslationFieldProps extends TextFieldProps {
  setLang?: ( lang: string ) => void;
}

const TranslationField: FC<TranslationFieldProps> = ( props ) => {
  const { setLang: setParentLang } = props;
  interface Text extends RaRecord { name: string }
  const record = useRecordContext<Text>();
  const { name } = record || {};
  const [ lang, setLang ] = useState<string>( '' );
  const { data: translations } = useGetList<Text>( 'texts', { filter: { name, lang } }, { enabled: !!lang && !!name } );
  const [ translation ] = lang != 'en' && translations || [];

  useEffect( () => setParentLang?.( lang ), [] );

  const langChange = useCallback<NonNullable<LanguageSelectProps[ 'onChange' ]>>( ( e ) => {
    setLang( e.target.value );
    localStorage.setItem( 'lang', e.target.value || 'en' );
    setParentLang?.( e.target.value );
  }, [ setLang, setParentLang ] );

  return (
    <RecordContextProvider value={ translation }>
      <Grid container >
        <Grid item xs={ 6 }>
          <TextField label='Translated text' source='value' />
        </Grid>
        <Grid item xs={ 6 } >
          <LanguageSelect lang={ lang } onChange={ langChange } />
          <EditButton sx={ { margin: '0 1rem' } } />
        </Grid>
      </Grid>
    </RecordContextProvider>
  )
}

export const LockedField: FC<BooleanFieldProps> = props => {
  const { source, TrueIcon = Lock, FalseIcon = NoEncryption, looseValue = true, color = 'gray' } = props;

  return (
    <BooleanField source={ source }
      TrueIcon={ TrueIcon }
      valueLabelTrue='Locked'
      valueLabelFalse='Unlocked'
      FalseIcon={ FalseIcon }
      looseValue={ looseValue }
      color={ color }
    />
  );
}

export const TextShow: FC<Childless<ShowProps>> = () => {
  const [ lang, setLang ] = useState<string>( '' );

  return (
    <Show actions={ <ShowActionsWithReset disableRecordEdit={ r => r?.locked } /> }>
      <AdminShowLayout override='text.texts' >

        <LanguageField source='lang' />

        <TextField label="Variable name" source="name" />
        <LockedField source='locked' />

        {/* <TimestampsField label="Updated" source="updatedAt" /> */ }
        {/* <TimestampsField label="Created" source="createdAt" /> */ }

        <SelectField label='Translator' source="translator" choices={ choices.translators } />

        <TextField label='Hash' source="enHash" />

        <TextField label='Source text' source='value' />

        <Labeled label='Translated text'  >
          <TranslationField setLang={ setLang } source='lang' />
        </Labeled>

        <Labeled label='References' >
          <Box>
            <TextReferenceCountField source='lang' />
            <TextExpandReferences lang={ lang } />
          </Box>
        </Labeled>

      </AdminShowLayout>
    </Show>
  );
}

const CustomTextShowActions: FC = () => {
  const record = useRecordContext();
  const { id = '' } = record || {};
  const to = useMemo( () => `/customtexts/${ encodeURIComponent( id ) }`, [ id ] );

  return (
    <TopToolbar>
      <ResetButton />
      <Button
        component={ Link }
        label='ra.action.edit'
        to={ to }
        onClick={ e => e.stopPropagation() }
      >
        <EditIcon />
      </Button>
    </TopToolbar >
  );
}

export const CustomTextShow: FC<ShowProps> = () => {
  // const [ lang, setLang ] = useState<string>( '' );

  return (
    <Show
      resource='texts'
      actions={ <CustomTextShowActions /> }
    >
      <AdminShowLayout override='text.customtexts' >

        <LanguageField source='lang' />

        <TextField label="Variable name" source="name" />
        <LockedField source='locked' />

        {/* <TimestampsField label="Updated" source="updatedAt" /> */ }
        {/* <TimestampsField label="Created" source="createdAt" /> */ }

        <SelectField label='Translator' source="translator" choices={ choices.translators } />

        <TextField label='Hash' source="enHash" />

        <TextField label='Source text' source='value' />
        { /*
             <Labeled label='Translated text'  >
             <TranslationField setLang={ setLang } />
             </Labeled>

             <Labeled label='References' >
             <Box>
             <TextReferenceCountField />
             <TextExpandReferences lang={ lang } />
             </Box>
             </Labeled>
           */ }
      </AdminShowLayout>
    </Show>
  );
}

const LiveHashField: FC<TextFieldProps> = () => {
  const value = useWatch( { name: 'value' } );
  const hash = hashString( value );
  return <TextField label='Hash' source='enHash' record={ { enHash: hash } } />;
}


const TextForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  const location = useLocation();
  const override = location.pathname.includes( 'customtexts' ) ? 'text.customtexts' : 'text.texts';

  return (
    <AdminSimpleForm
      override={ override }
      sanitizeEmptyValues
      warnWhenUnsavedChanges
      { ...props }
      toolbar={ <CustomToolbar /> }
    >

      <LanguageField source='lang' />

      <Labeled label='Name' >
        <TextField label="Name" source="name" />
      </Labeled>

      <Labeled label='Translator' >
        <SelectField label='Translator' source="translator" choices={ choices.translators } />
      </Labeled>

      <WithRecord render={ ( record ) => {
        if( record.lang != 'en' ) return null;
        return (
          <Labeled label='Hash' >
            <TextField label='Hash' source="enHash" />
          </Labeled>
        );
      } } />

      <TextInput source="value" label="Value" />

      <WithRecord render={ ( record ) => {
        if( record.lang != 'en' ) return null;
        return (
          <Labeled label='New hash' >
            <LiveHashField source='enHash' />
          </Labeled>
        );
      } } />


    </AdminSimpleForm>
  );
}


export const TextExpandEdit: FC<EditProps> = props => (
  <Edit
    mutationMode='pessimistic'
    actions={ false }
    title={ '&nbsp;' }
    redirect='show'
    { ...props } >
    <TextForm />
  </Edit>
);

export const TextEdit: FC<EditProps> = props => (
  <Edit
    mutationMode='pessimistic'
    // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
    redirect='list'
    { ...props }
  >
    <TextForm />
  </Edit>
);

const CustomTextEditActions: FC = () => {
  const record = useRecordContext();
  const { id = '' } = record || {};
  const to = useMemo( () => `/customtexts/${ encodeURIComponent( id ) }/show`, [ id ] );

  return (
    <TopToolbar>
      <Button
        component={ Link }
        label='ra.action.show'
        to={ to }
        onClick={ e => e.stopPropagation() }
      >
        <ImageEye />
      </Button>
    </TopToolbar >
  );
}

export const CustomTextEdit: FC<EditProps> = props => {
  const redirect: RedirectionSideEffect = ( _resource, id = '' ) => `customtexts/${ encodeURIComponent( id ) }/show`;

  return (
    <Edit
      resource='texts'
      mutationMode='pessimistic'
      // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
      actions={ <CustomTextEditActions /> }
      redirect={ redirect }
      { ...props }
    >
      <TextForm />
    </Edit>
  );
}

const CustomTextCreateForm: FC<Omit<SimpleFormProps, 'children'>> = props => {

  const validateName = [
    required(),
    minLength( 2 ),
    maxLength( 25 ),
    regex( /^[a-zA-Z][a-zA-Z0-9_]+$/, 'Alpha first letter, then alphanumeric with underscores, no spaces or other chars.' )
  ];
  const validateValue = [
    required(),
    minLength( 1 ),
    maxLength( 255 ),
  ];

  return (
    <AdminSimpleForm override='text.customtexts'
      sanitizeEmptyValues
      warnWhenUnsavedChanges
      { ...props }
    // toolbar={ < CustomToolbar /> }
    >
      <TextInput source="name" validate={ validateName }
      />
      <TextInput source="value" validate={ validateValue } />

    </AdminSimpleForm >
  );
}

export const CustomTextCreate: FC<CreateProps> = props => {
  const redirect: RedirectionSideEffect = ( _resource, id = '' ) => `customtexts/${ encodeURIComponent( id ) }/show`;

  return (
    <Create
      resource='texts'
      redirect={ redirect }
      { ...props }
    >
      <CustomTextCreateForm />
    </Create>
  );
}


