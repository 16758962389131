import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton as MuiIconButton, Typography, useTheme } from '@mui/material';
import { DataGridPremium, GridColType } from '@mui/x-data-grid-premium';
import { BarDatum } from '@nivo/bar';
import { Datum, Line, LineProps, Serie, SliceTooltipProps } from '@nivo/line';
import { useCanAccess } from '@react-admin/ra-rbac';
import { addMonths } from 'date-fns';
import { Dayjs } from 'dayjs';
import { reverse, sumBy } from 'lodash';
import { default as queryString } from 'query-string';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Loading, useRedirect } from 'react-admin';
import { ExportOnlyToolbar } from './DashboardReputationActivity';
import { makeLineChartSliceTooltip, makeLineChartTooltip } from './DashboardTooltip';
import { ChartProps, colorPalettes, elevation, OnlyNumbers } from './DashboardWidget';
import { apiUrl, httpClient } from './DataProvider';
import { useUserPreference } from './UserPreferences';

interface AppointmentTrendDatum extends BarDatum {
  date: string;
  md: string;
  ymd: string;
  total: number;  // appointments
  'Arrived': number;
  'Booked': number;
  'Cancel': number;
  'Cancelled': number;
  'Checked-in': number;
  'No Show': number;
  'Pending': number;
}
export interface AppointmentTrendLineDatum extends Datum {
  y: number | null;
  ymd: string;
  md: string;
  total: number;
}
export interface AppointmentTrendLinePoint extends Serie {
  data: AppointmentTrendLineDatum[];
}

export type LineLegendProps = NonNullable<LineProps[ 'legends' ]>[ 0 ];

export const DashboardAppointmentTrend: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const redirect = useRedirect();
  const { canAccess: canAccessSystem } = useCanAccess( { action: 'manage', resource: 'system' } );
  const isSmall = false;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationMonths = 6;
  const cacheKey = 'dashboard-appointment-trend';
  const [ isLoading, setIsLoading ] = useState( true );
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  const [ tableData, setTableData ] = useState<AppointmentTrendDatum[] | undefined>( [] );
  const [ data, setData ] = useState<AppointmentTrendLinePoint[]>( [] );
  const isEmpty = useMemo( () => sumBy( data, d => d.data.length ) === 0, [ data ] );
  const keys: ( keyof OnlyNumbers<AppointmentTrendDatum> )[] = [ 'Cancelled', 'No Show' ];

  const durationMonths = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences ] );

  // const counts = useMemo( () => (
  //   Object.fromEntries( data.map( ( { id, data } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) )
  // ), [ data ] );
  const yMax = useMemo( () => Math.max( ...[ 10, ...data.flatMap( row => row.data.map( d => d.y || 0 ) ) ] ), [ data ] );
  // const yMin = useMemo( () => Math.min( ...[ yMax * 0.9, ...data.flatMap( row => row.data.map( d => d.y || 0 ) ) ] ), [ data ] );

  const fetchSummary = useCallback( async ( date: Dayjs | undefined | null ) => {
    const query: Record<string,string> = {
      months: `${durationMonths}`,
    };
    if( date ) query.date = date.toISOString();
    const { body } = await httpClient( `${ apiUrl }/summaries/appointmentTrend?${ new URLSearchParams( query ) }` );
    return body;
  }, [ durationMonths, httpClient ] );

  const convertRows = ( rows: AppointmentTrendDatum[] ): AppointmentTrendLinePoint[] => keys.map( id => ( {
    id, data: rows.map( d => {
      const { ymd, md, date, total } = d;
      // const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
      const y = d[ id ];
      return { x: ymd, y, ymd, md, date, total };
    } )
      .filter( d => !!d.y )
      .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 )
  } ) )
    ;


  useEffect( () => {
    ( async () => {
      if( data.length || !isLoading ) return;
      const json = localStorage.getItem( cacheKey );
      if( data.length || !json || !isLoading ) return;
      try {
        const rows = JSON.parse( json ) as AppointmentTrendDatum[];
        const lineData = convertRows( rows );
        setData( lineData );
        setIsLoading( false );
      } catch( e ) { return; }
    } )()
  }, [ data, setData, isLoading ] );

  useEffect( () => {
    ( async () => {
      const body = await fetchSummary( date );
      const rows = JSON.parse( body ) as AppointmentTrendDatum[];
      const lineData = convertRows( rows );
      setTableData( rows );
      setData( lineData );
      setIsLoading( false );
      localStorage.setItem( cacheKey, JSON.stringify( rows ) );
    } )()
  }, [ setData, setTableData, fetchSummary, date ] );

  const colors = useMemo( () => reverse( [ ...colorPalettes.discretePrimary.slice( 0, keys.length ) ] ), [ keys, colorPalettes ] );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Appointment Trend</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <MuiIconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </MuiIconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationMonths } Months Ending ${ date.format( 'MMM YYYY' ) }` : `Previous ${ durationMonths } Months` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
            // fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative',
            } }
          >
            { showDatagrid
              ? <Box
                width={ 500 }
                height={ 400 + 48 + 24 }
                sx={ {
                  marginTop: '-48px',
                  marginBottom: '-24px',
                } }
              >
                <DataGridPremium
                  density='compact'
                  disableRowSelectionOnClick
                  disableColumnResize
                  disableColumnSelector
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnPinning
                  disableChildrenSorting
                  disableMultipleColumnsSorting
                  pagination
                  autoPageSize
                  rows={ ( tableData || [] ).map( ( d, id ) => ( { id, ...d } ) ) }
                  columns={ [
                    { field: 'ymd', headerName: 'Date', sortable: true, flex: 2 },
                    // @ts-ignore: 2322
                    ...keys.map( key => ( {
                      type: 'number' as GridColType,
                      field: key,
                      headerName: key,
                      sortable: false,
                      flex: 1,
                    } ) ),
                  ] }
                  slots={ {
                    toolbar: ExportOnlyToolbar.bind( undefined, { fileName: cacheKey } ),
                  } }
                  sx={ {
                    borderColor: 'transparent',
                    '& .MuiDataGrid-columnHeaders,& .MuiDataGrid-cell': {
                      borderBottomColor: 'transparent',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      overflow: 'hidden',
                    },
                  } }
                  initialState={ {
                    sorting: {
                      sortModel: [ { field: 'ymd', sort: 'desc' } ],
                    }
                  } }
                />
              </Box>
              : <>

                <Line
                  width={ 500 }
                  height={ 400 }
                  curve='monotoneX'
                  data={ isEmpty ? [] : data }
                  margin={ { top: 50, right: 50, bottom: 55, left: 75 } }
                  gridYValues={ 4 }

                  isInteractive={ !isEmpty }
                  useMesh={ !isEmpty }
                  enableCrosshair
                  // enableArea
                  areaOpacity={ 0.1 }
                  lineWidth={ 3 }
                  pointSize={ 6 }

                  xFormat="time:%Y-%m"
                  xScale={ {
                    type: 'time',
                    format: '%Y-%m-%d',
                    precision: 'month',
                    useUTC: false,
                    max: date ? date.toDate() : new Date(),
                    min: date ? addMonths( date.toDate(), 0 - durationMonths ) : addMonths( new Date(), 0 - durationMonths ),
                  } }

                  // yFormat={ ( value: number | undefined, context: AppointmentTrendLineDatum ): string => {
                  //   return !value ? '' : `${ value.toString() }%`;
                  // } }
                  // @ts-ignore: 2769
                  yFormat={ ( value: number | undefined ): string => {
                    return !value ? '' : `${ value.toString() }%`;
                  } }
                  // yFormat='>-1.1~f'
                  yScale={ {
                    type: 'linear',
                    // base: 2,
                    max: Math.ceil( yMax / 5 ) * 5,
                    min: 0,
                  } }

                  enableSlices='x'
                  sliceTooltip={ makeLineChartSliceTooltip( {
                    theme,
                    renderHeader: AppointmentTrendSliceTooltipHeader
                  } ) }

                  tooltip={ makeLineChartTooltip( theme ) }

                  onClick={ ( point ) => {
                    if( !canAccessSystem ) return;
                    // @ts-ignore:2339,2352
                    const { serieId: id, data: { ymd } } = point as AppointmentTrendLinePoint;  // action: 'confirm', date: '5/31'
                    if( !ymd ) return;
                    const filter = JSON.stringify( {
                      action: typeof id == 'string' ? id.toLowerCase() : undefined,
                      // createdAt: [ `gte:${ ymd }`, `lte:${ ymd }` ].join( ';' ),
                      createdAt: ymd,
                      delimiter: ';',
                    } );
                    redirect( `/actionlogs?${ queryString.stringify( { filter } ) }` );
                  } }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                        padding: '5px 9px',
                      },
                    },
                  } }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } //{ scheme: colors, size: keys.length } }
                  // enableLabel={ false }
                  enableGridX={ false }
                  enableGridY={ true }
                  axisTop={ null }
                  axisRight={ null }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: 4, // [ 0, 1000, 2000, 3000 ],
                    legend: 'Monthly percentage',
                    legendPosition: 'middle',
                    legendOffset: -67

                  } }

                  axisBottom={ {
                    tickSize: 5,
                    // ticksPosition: 'before',
                    tickPadding: 5,
                    format: '%b',
                    tickValues: `every ${ Math.ceil( durationMonths / 9 ) } months`,
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }

                  legends={ [ {
                    anchor: 'top-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -40,
                    itemsSpacing: 65,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 1, // 0.95,
                    symbolSize: 18, // 20,
                    symbolShape: 'circle', // 'square',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1
                        }
                      }
                    ],
                    toggleSerie: true,
                  } ] }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card >

  );

}

export const AppointmentTrendSliceTooltipHeader: FC<SliceTooltipProps> = ( { slice } ) => {
  const [ point ] = slice.points;
  const {
    xFormatted,  // 2024-08
    // @ts-ignore: 2339
    ymd, total,
  } = point.data;
  const title = `${ new Date( ymd ).toUTCString().slice( 8, 11 ) } ${ xFormatted.toString().slice( 0, 4 ) }`; // Aug 2024

  return (
    <>
      <Box
        key='h0'
        className='tooltip-row'
      >
        <Typography sx={ { fontWeight: 'bold' } }>{ title } </Typography>
      </Box>
      <Box
        key='h1'
        className='tooltip-row'
      >
        <Typography>Appointments: </Typography>
        <Typography sx={ { fontWeight: 'bold' } } >{ total } </Typography>
      </Box>
    </>
  );
}
