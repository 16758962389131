import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { IconMenu, useAppLocationState } from '@react-admin/ra-navigation';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { FC, useEffect } from 'react';
import { Form, FormProps, SimpleForm, SimpleFormProps, SimpleShowLayout, SimpleShowLayoutProps, TabbedShowLayout, TabbedShowLayoutProps, Title, useRecordContext, useResourceContext } from 'react-admin';
import { ActionLogIcon } from './ActionLogs';
import { AltMenuItemCategory } from './AltMenuItemCategory';
import { AppointmentTypePackageIcon } from './AppointmentTypePackages';
import { CalendarPackageIcon } from './CalendarPackages';
import { ConfigIcon } from './Configs';
import { EvaluatorIcon } from './Evaluators';
import { FormDefinitionIcon } from './FormDefinitions';
import { FormSubmissionIcon } from './FormSubmissions';
import { GoLiveIcon } from './GoLive';
import { HtmlComponentIcon } from './HtmlComponents';
import { LanguageIcon } from './Languages';
import { MessageTemplateIcon } from './MessageTemplates';
import { ReminderTemplateListIcon } from './ReminderTemplateLists';
import { SnippetIcon } from './Snippets';
import { SystemIcon } from './System';
import { TextIcon } from './Texts';
import { TwimlIcon } from './Twimls';
import { UserIcon } from './Users';

// Include this in Admin embedded List views
export const useAdminListAppLocation = ( override?: string ): void => {
  const resource = useResourceContext();
  const [ _, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( `admin.${ override ? override : resource }` );
    return () => setLocation( null );
  }, [] );
};


// SimpleShowLayout with the AppLocation for amdin embedded resource Show pages
export const AdminShowLayout: FC<SimpleShowLayoutProps & { override?: string }> = props => {
  const { override, ...rest } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( `admin.${ override ? override : resource }.show`, { record } );
    return () => setLocation( null );
  }, [] );
  return <SimpleShowLayout { ...rest } />;
}

export const AdminTabbedShowLayout: FC<TabbedShowLayoutProps & { basePath: string }> = props => {
  const { basePath, ...rest } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( `admin.${ resource }.show`, { record, resource } );
    return () => setLocation( null );
  }, [] );
  return <TabbedShowLayout { ...rest } />;
}


// SimpleForm with the AppLocation for admin embedded resource Edit/Create pages
export const AdminSimpleForm: FC<SimpleFormProps & { override?: string }> = props => {
  const { override, ...rest } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( `admin.${ override ? override : resource }.${ record?.id ? 'edit' : 'create' }`, { record } );
    return () => setLocation( null );
  }, [] );
  return <SimpleForm { ...rest } />;
}
// Same for replacing Form elements
export const AdminForm: FC<FormProps> = props => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( `admin.${ resource }.${ record?.id ? 'edit' : 'create' }`, { record } );
    return () => setLocation( null );
  }, [] );
  return <Form { ...props } />;
}


export const Admin: FC = () => {
  const [ _location, setLocation ] = useAppLocationState();
  // const menuContext = useMultiLevelMenu();

  useEffect( () => {
    setLocation( 'admin' );
    return () => setLocation( null );
  }, [] );


  return (
    <IfCanAccess resource='system' action='manage'>
      <Card >
        <Title title='Admin ' />
        <CardContent
          sx={ {
            maxWidth: '720px',
            minHeight: '25em',
          } }
        >

          <IconMenu variant="default"
            // { ...omit( props, 'hasDashboard' ) }
            sx={ {
              '& .AltMenuItemCategory-link': {
                width: '83px',
                '& .MuiListItemText-primary': {
                  overflowWrap: 'normal',
                  wordBreak: 'normal',
                  textAlign: 'center',
                  whiteSpace: 'normal',
                },
              },
              '& .MuiSvgIcon-root': {
                width: '3rem',
                height: '3rem',
              }
            } }
          >

            <Grid container
              columnSpacing={ 8 }
              rowSpacing={ 2 }
              // justifyContent='space-around'
              sx={ {
                // width: '100%'
              } }
            >
              <Grid item xs={ 12 }>
                <Typography color='primary' sx={ { textTransform: 'uppercase' } }> Main </Typography>
                <Divider />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Configs"
                  name='configs'
                  icon={ <ConfigIcon /> }
                  to='/configs'
                />
              </Grid>


              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="System Tests"
                  name='system'
                  icon={ <SystemIcon /> }
                  to='/admin/system'

                />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Users"
                  name='users'
                  icon={ <UserIcon /> }
                  to='/users'
                />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Evaluators"
                  name='evaluators'
                  icon={ <EvaluatorIcon /> }
                  to='/evaluators'
                />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="GoLive!"
                  name='golive'
                  icon={ <GoLiveIcon /> }
                  to='/admin/golive'
                />
              </Grid>

              { /*
                   <Grid item xs={ 'auto' } >
                   <AltMenuItemCategory
                   label="Recipients"
                   name='recipients'
                   icon={ <RecipientIcon /> }
                   to='/recipients'
                   />
                   </Grid>
                 */ }

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Action Logs"
                  name='actionlogs'
                  icon={ <ActionLogIcon /> }
                  to='/actionlogs'
                />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Form Submissions"
                  name='formsubmissions'
                  icon={ <FormSubmissionIcon /> }
                  to='/formsubmissions'
                />
              </Grid>

              {/*
                  <Grid item xs={ 'auto' } >
                  <AltMenuItemCategory
                  label="Files"
                  name='files'
                  icon={ <FileIcon /> }
                  to='/files'
                  />
                  </Grid>
                */}

              <Grid item xs={ 12 }>
                <Typography color='primary' sx={ { textTransform: 'uppercase' } }>  Contextual Elements </Typography>
                <Divider />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Message Templates"
                  name='messagetemplates'
                  icon={ <MessageTemplateIcon /> }
                  to='/messagetemplates'
                />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Voice"
                  name='twimls'
                  icon={ <TwimlIcon /> }
                  to='/twimls'
                />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Snippets"
                  name='snippets'
                  icon={ <SnippetIcon /> }
                  to='/snippets'
                />
              </Grid>


              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Form Definitions"
                  name='formdefinitions'
                  icon={ <FormDefinitionIcon /> }
                  to='/formdefinitions'
                />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Texts"
                  name='texts'
                  icon={ <TextIcon /> }
                  to='/text'
                />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Languages"
                  name='languages'
                  icon={ <LanguageIcon /> }
                  to='/languages'
                />
              </Grid>

              { /*
                   <Grid item xs={ 12 }>
                   <Typography color='primary' sx={ { textTransform: 'uppercase' } }>  Email </Typography>
                   <Divider />
                   </Grid>
                 */}
              <Grid item xs={ 12 }>
                <Typography color='primary' sx={ { textTransform: 'uppercase' } }>  Packages </Typography>
                <Divider />
              </Grid>


              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Reminder Templates"
                  name='remindertemplatelists'
                  icon={ <ReminderTemplateListIcon /> }
                  to='/remindertemplatelists'
                />
              </Grid>

              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Appointment Types"
                  name='appointmenttypepackages'
                  icon={ <AppointmentTypePackageIcon /> }
                  to='/appointmenttypepackages'
                />
              </Grid>
              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Calendars"
                  name='calendarpackages'
                  icon={ <CalendarPackageIcon /> }
                  to='/calendarpackages'
                />
              </Grid>


              <Grid item xs={ 'auto' } >
                <AltMenuItemCategory
                  label="Email Components"
                  name='htmlcomponents'
                  icon={ <HtmlComponentIcon /> }
                  to='/htmlcomponents'
                />
              </Grid>

              {/*
                  <Grid item xs={ 'auto' } >
                  <AltMenuItemCategory
                  label="Email Titles"
                  name='htmlemailtitles'
                  icon={ <HtmlEmailTitleIcon /> }
                  to='/htmlemailtitles'
                  />
                  </Grid>

                  <Grid item xs={ 'auto' } >
                  <AltMenuItemCategory
                  label="Email Assemblies"
                  name='htmlassemblies'
                  icon={ <HtmlAssemblyIcon /> }
                  to='/htmlassemblies'
                  />
                  </Grid>
                */}

              {/*
                  <Grid item xs={ 12 }>
                  <Typography color='primary' sx={ { textTransform: 'uppercase' } }>  Appointment Updates </Typography>
                  <Divider />
                  </Grid>

                  <Grid item xs={ 'auto' } >
                  <AltMenuItemCategory
                  label="Update Text"
                  name='appointmentupdatetexts'
                  icon={ <AppointmentUpdateTextIcon /> }
                  to='/appointmentupdatetexts'
                  />
                  </Grid>
                */}

            </Grid>
          </IconMenu>
        </CardContent >
      </Card >
    </IfCanAccess>
  );
}

