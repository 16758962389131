import { Box, Grid } from '@mui/material';
import { LineProps } from '@nivo/line';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { Dayjs } from 'dayjs';
import { cloneElement, FC, ReactElement } from 'react';
import { DashboardWidgetDragDrop, OnDrop } from './DashboardWidgetDragDrop';
import { PickByPropertyType } from './types';

export type LineLegendProps = NonNullable<LineProps[ 'legends' ]>[ 0 ];
export type OnlyNumbers<Type> = PickByPropertyType<Type, number>;

export const colorPalettes = {
  discretePrimary: [ '#FF8A00', '#00BEE6', '#F6484D', '#00BBC1', '#2973EF', '#9600E5', '#18B74E' ], // orange, cyan, red, turquois, blue, purple, green
  spectrumGreenRed: [ '#009933', '#00BBC1', '#F7E71E', '#FF8A00', '#E22D2D' ],
  spectrumRedGreen: [ '#E22D2D', '#FF8A00', '#F7E71E', '#00BBC1', '#009933' ],
  spectrumPrimary: [ '#9600E5', '#2973EF', '#00C78E', '#FF8A00', '#F7E71E' ],
  spectrumTealRed: [ '#005763', '#00C78E', '#F7E71E', '#FF8A00', '#E22D2D' ],
}

export const elevation = 3;

export interface PieDatum {
  id: string;
  value: number;
}

export interface Widget extends Omit<DashboardWidgetProps, 'children' | 'idx' | 'onDrop'> {
  component: FC<ChartProps>;
}


export interface ChartProps {
  date?: Dayjs | null;
}


export interface DashboardWidgetProps extends ChartProps {
  children: ReactElement;
  hidden?: boolean;
  resource?: string;
  action?: string;
  tag: string;
  label: string;
  idx: number;
  onDrop: OnDrop;
}

export const DashboardWidget: FC<DashboardWidgetProps> = ( props ) => {
  const { children, hidden, resource, action, date, tag, idx, onDrop } = props;
  // useEffect( () => { console.log( `DashboardWidget ${ idx } ${ tag }` ) }, [ idx, tag ] );

  if( hidden ) return null;

  const widget = (
    <Grid item xs={ 'auto' }>
      <Box
        sx={ {
          height: 529,
          width: 532,
        } }
      >
        <DashboardWidgetDragDrop idx={ idx } onDrop={ onDrop } tag={ tag } disabled={ !!date }>
          { cloneElement( children, { date } ) }
        </DashboardWidgetDragDrop>
      </Box>
    </Grid>
  );

  if( action ) {
    return (
      <IfCanAccess action={ action } resource={ resource } >
        { widget }
      </IfCanAccess>
    );
  }

  return widget;

}


