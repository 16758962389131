/*eslint @typescript-eslint/no-unused-vars: 'warn'*/
import { AccessibilityNew, CardGiftcard, CreditCard, Fastfood, Group, HowToReg, ImportContacts as Magazine, Info, LocalFlorist, LocalParking, LocalPharmacy, RoomService, Wc, Wifi } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { MarkdownInput } from '@react-admin/ra-markdown';
import { EditorOptions } from '@toast-ui/editor';
import get from 'lodash/get';
import set from 'lodash/set';
import { FC, ReactElement, useEffect, useState } from 'react';
import { FormDataConsumer, Identifier, ImageField, InputProps, RaRecord, useGetList } from 'react-admin';
import { defaultAspect, defaultCrop, defaultRotation } from './ImageCropper';
import { ImageInputEdit } from './ImageInputEdit';

export { defaultAspect, defaultCrop, defaultRotation };

export const getCommonTagId = ( name: string ): string => {
  switch( name ) {
    case 'checkin': return '613914872ab88d000b62d19e';
    case 'checkout': return '613915d127ea940aa8edb6d0';
    case 'giftshop': return '6172fa866214baaf2b5ac346';
    case 'meetingRooms': return '6139175027ea940aa8edb79f';
    case 'onsite': return '613915eb27ea940aa8edb6ec';
    case 'parkAlternative': return getCommonTagId( 'parking' );
    case 'parkBest': return getCommonTagId( 'parking' );
    case 'parking': return '6102f0cc3b3fd28628ca935e';
    case 'pharmacy': return '6139170827ea940aa8edb74f';
    case 'restrooms': return '6139173127ea940aa8edb781';
    case 'vending': return '613a7e50d2de29000bb1638c';
    case 'waitingAreas': return '6139174027ea940aa8edb790';
    case 'wifi': return '613a0120550946000bce1b44';
    case 'charitableGiving': return '613a00ea550946000bce1b27';
    case 'accessibility': return '613a28e2c573f8000afe1e08';
    case 'information': return '613a2944c573f8000afe1e2c';
    case 'square': return '6102ee87db029d83f10886fd';
    case 'banner': return '6102f0c13b3fd28628ca935a';
    case 'photo': return '6102e8c4ac15a717ce15fedd';
    default: return '';
  }
}

import DOMPurify from 'dompurify';

export const markdownEditorOptions: Omit<EditorOptions, 'el'> = {
  usageStatistics: false,
  previewStyle: 'tab', // 'vertical',
  height: '160px',
  initialEditType: 'wysiwyg',
  hideModeSwitch: true,
  toolbarItems: [ [ 'heading', 'bold', 'italic' ], [ 'hr', 'quote' ], [ 'ul', 'ol', 'indent', 'outdent' ] ],
  // theme: 'dark', // TODO from usePreferences?
  // language: 'en', // TODO from usePreferences?

  customHTMLSanitizer: ( dirty ) => DOMPurify.sanitize( dirty, { ALLOWED_TAGS: [] } ),  // filter ALL html tags

  // onBeforeConvertWysiwygToMarkdown: () => {}

  /*
     // previewStyle: 'tab',
     previewHighlight: true,
     // initialEditType: 'markdown',
     // height: '300px',
     minHeight: '200px',
     language: 'en-US',
     useCommandShortcut: true,
     // usageStatistics: true,
     toolbarItems: [
     [ 'heading', 'bold', 'italic', 'strike' ],
     [ 'hr', 'quote' ],
     [ 'ul', 'ol', 'task', 'indent', 'outdent' ],
     [ 'table', 'image', 'link' ],
     [ 'code', 'codeblock' ],
     [ 'scrollSync' ],
     ],
     hideModeSwitch: false,
     linkAttributes: null,
     extendedAutolinks: false,
     customHTMLRenderer: null,
     customMarkdownRenderer: null,
     referenceDefinition: false,
     customHTMLSanitizer: null,
     frontMatter: false,
     widgetRules: [],
     theme: 'light',
     autofocus: true,


   */
};

export const convertDataUrlToBlob = async ( url: string ): Promise<Blob> => {
  // https://ionicframework.com/blog/converting-a-base64-string-to-a-blob-in-javascript/
  const response = await fetch( url );
  return await response.blob()
}


export interface AssetProps {
  name: string;
  page: string;
  label: string;
  labelImage?: string;
  placeholder?: string;
  helperText?: string;
  imageAspect?: number; // default is 4/3
  imageMultiple?: boolean;
  isFacility?: boolean;
  isOnsite?: boolean;
  isAmenity: boolean;
  icon?: ReactElement;
}

export const assetProps: AssetProps[] = [
  {
    isAmenity: true,  // May change when Transportation input enabled
    name: 'parking',
    page: 'Transportation',
    label: 'Parking',
    placeholder: 'When you arrive by car, ...',
    helperText: 'Describe the parking process',
    imageMultiple: true,
    icon: <LocalParking />,
  },
  {
    isAmenity: true,
    name: 'checkin',
    page: 'Process',
    label: 'Check In',
    placeholder: 'When you arrive, ...',
    helperText: 'Describe the check in process',
    imageMultiple: true,
    // icon: // stethascope
    icon: <HowToReg />,
  },
  {
    isAmenity: true,
    name: 'waitingAreas',
    page: 'Process',
    label: 'Waiting Areas',
    placeholder: 'Waiting areas can be found...',
    helperText: 'Describe where',
    imageMultiple: true,
    isFacility: true,
    icon: <Magazine />
  },
  {
    isAmenity: true,
    name: 'checkout',
    page: 'Process',
    label: 'Check Out',
    placeholder: 'Before you depart, ...',
    helperText: 'Describe the check out process',
    imageMultiple: true,
    icon: <CreditCard />,
  },
  {
    isAmenity: true,
    name: 'onsite',
    page: 'Amenities',
    label: 'Onsite Amenities',
    placeholder: '',
    helperText: 'Describe general onsite amenities',
    imageMultiple: true,
    icon: <RoomService />,
  },
  {
    isAmenity: true,
    name: 'restrooms',
    page: 'Amenities',
    label: 'Restrooms',
    placeholder: 'Restrooms are located ...',
    helperText: 'Describe where',
    imageMultiple: true,
    isFacility: true,
    icon: <Wc />,
  },
  {
    isAmenity: true,
    name: 'wifi',
    page: 'Amenities',
    label: 'Wi-Fi',
    placeholder: '',
    helperText: 'Include network name, password and any special instructions',
    imageMultiple: true,
    isFacility: true,
    icon: <Wifi />,
  },
  {
    isAmenity: true,
    name: 'vending',
    page: 'Amenities',
    label: 'Vending',
    placeholder: '',
    helperText: 'Describe where and what',
    imageMultiple: true,
    isFacility: true,
    icon: <Fastfood />,
  },
  {
    isAmenity: true,
    name: 'pharmacy',
    page: 'Onsite',
    label: 'Pharmacy',
    placeholder: 'The onsite pharmacy is...',
    helperText: 'Describe where, hours',
    imageMultiple: true,
    isFacility: true,
    isOnsite: true,
    icon: <LocalPharmacy />
  },
  {
    isAmenity: true,
    name: 'giftshop',
    page: 'Onsite',
    label: 'Gift Shop',
    placeholder: 'Out gift shop is...',
    helperText: 'Describe where, hours',
    imageMultiple: true,
    isFacility: true,
    isOnsite: true,
    icon: <LocalFlorist />
  },
  {
    isAmenity: true,
    name: 'meetingRooms',
    page: 'Onsite',
    label: 'Meeting Rooms',
    placeholder: 'Meeting rooms are located ...',
    helperText: 'Describe where',
    imageMultiple: true,
    isFacility: true,
    icon: <Group />,
  },
  {
    isAmenity: true,
    name: 'charitableGiving',
    page: 'Other',
    label: 'Charitable Giving',
    placeholder: 'Our facility is supported by ...',
    helperText: 'Describe what and how',
    imageMultiple: true,
    isFacility: true,
    icon: <CardGiftcard />
  },

  {
    isAmenity: true,
    name: 'accessibility',
    page: 'Other',
    label: 'Accessibility',
    placeholder: 'Our facility features accessible ...',
    helperText: 'Describe what and how',
    imageMultiple: true,
    isFacility: true,
    icon: <AccessibilityNew />,
  },
  {
    isAmenity: true,
    name: 'information',
    page: 'Other',
    label: 'Information',
    placeholder: 'If you need any information or assistance, ...',
    helperText: 'Describe what and how',
    imageMultiple: true,
    isFacility: true,
    icon: <Info />,
  },
  {
    isAmenity: false,
    name: 'banner',
    page: 'Location',
    label: 'Main Banner',
    placeholder: 'If you need any information or assistance, ...',
    helperText: 'Describe what and how',
    isFacility: true,
    imageAspect: 3 / 1,
  },
  {
    isAmenity: false,
    name: 'square',
    page: 'Location',
    label: 'Square Logo',
    placeholder: 'If you need any information or assistance, ...',
    helperText: 'Describe what and how',
    imageAspect: 1,
    isFacility: true,
  },
  {
    isAmenity: false,
    name: 'photo',
    page: 'Location',
    label: 'Photos',
    placeholder: 'If you need any information or assistance, ...',
    helperText: 'Describe what and how',
    imageMultiple: true,
    isFacility: true,
  },
  {
    isAmenity: false,
    name: 'photo',
    page: 'Practitioner',
    label: 'Profile Photo',
    placeholder: 'If you need any information or assistance, ...',
    helperText: 'Describe what and how',
    imageAspect: 3 / 4,
    isFacility: false,
  },
];

export interface AmenityChoice {
  id: string;
  name: string;
  tag: string;
  imageAspect: number;
  imageMultiple: boolean;
  isFacility: boolean;
  icon?: ReactElement;
}

export const amenityChoices: AmenityChoice[] = assetProps
  .filter( props => props.isAmenity )
  .map( props => {
    const { name: tag, label: name, isFacility = false, icon, imageAspect = defaultAspect, imageMultiple = false } = props;
    const id = getCommonTagId( tag );
    return { id, name, tag, isFacility, icon, imageAspect, imageMultiple };
  } ).filter( c => !!c.id );

export const getAssetChoices = ( page: string ): AmenityChoice[] => assetProps
  .filter( props => props.page == page )
  .map( props => {
    const { name: tag, label: name, isFacility = false, icon, imageAspect = defaultAspect, imageMultiple = false } = props;
    const id = getCommonTagId( tag );
    return { id, name, tag, isFacility, icon, imageAspect, imageMultiple };
  } ).filter( c => !!c.id );

export const assetChoices: AmenityChoice[] = getAssetChoices( 'Location' );

export const practitionerChoices: AmenityChoice[] = assetProps
  .filter( props => props.page == 'Practitioner' )
  .map( props => {
    const { name: tag, label: name, isFacility = false, icon, imageAspect = defaultAspect, imageMultiple = false } = props;
    const id = getCommonTagId( tag );
    return { id, name, tag, isFacility, icon, imageAspect, imageMultiple };
  } ).filter( c => !!c.id );

export const assetPages: string[] = assetProps
  .map( a => a.page )
  .reduce( ( pages: string[], page: string ): string[] => pages.includes( page ) ? pages : [ ...pages, page ], [] );

export interface TagRecord {
  id: Identifier;
  name: string;
}
export interface ImageRecord {
  url?: string;
  data?: string;
  rawFile?: File | Blob;
  crop?: typeof defaultCrop;
  rotation?: typeof defaultRotation;
  mimeType?: string;
}
export interface AssetRecord {
  id: Identifier;
  body: string;
  subject?: string;
  tags: Identifier[];
  images: Identifier[];
}
export interface AssetValue extends Omit<AssetRecord, 'tags' | 'images'> {
  url: ImageRecord[];
}
export type AssetMap = { [ index: string ]: Partial<AssetValue> }




export const AssetInput: FC<AssetInputProps> = ( props ) => {
  const { label, labelImage, source = 'body', sourceImage = 'url', helperText, multiple = true, initialValue } = props;

  return (
    <FormDataConsumer>
      { ( { formData } ) => (
        <Grid container direction='row' spacing={ 0 } alignContent='flex-start' alignItems='flex-start'>
          <Grid item xs={ 7 } sm={ 5 } >
            <MarkdownInput
              label={ label }
              source={ source }
              helperText={ helperText }
              fullWidth={ false }
              defaultValue={ get( { ...initialValue }, source ) }
              { ...markdownEditorOptions }
            />
          </Grid>
          <Grid item xs={ 7 } sm={ 4 } >
            { get( formData, source, '' ).length > 0 && (
              <ImageInputEdit
                source={ sourceImage }
                label={ labelImage || `Images for ${ label }` }
                accept={ { 'image/*': [] } }
                multiple={ multiple }
                fullWidth={ false }
                defaultValue={ get( { ...initialValue }, sourceImage ) }
              >
                <ImageField
                  source={ ( sourceImage || 'url' ).split( '.' ).slice( -1 )[ 0 ] }
                  title='image'
                  // className={ classes.image }
                  sx={ {
                    margin: '0.5rem',
                    maxHeight: '30rem',
                  } }
                  fullWidth={ false }
                />
              </ImageInputEdit>
            ) }
          </Grid>
        </Grid>
      ) }
    </FormDataConsumer>
  );
}

export interface AssetInputProps extends InputProps {
  sourceImage?: string;
  label?: string;
  labelImage?: string;
  helperText?: string;
  multiple?: boolean;
  initialValue?: { assets: AssetMap };
}

export const getAssetInputsForPage = ( page: string, assetMap: AssetMap = {} ): ReturnType<typeof AssetInput>[] => {

  // NB assetMap/initialValue should only be set for Edit 

  return assetProps.filter( p => p.page == page ).map( ( { name, label, helperText } ) => (
    <AssetInput
      source={ `assets.${ name }.body` }
      sourceImage={ `assets.${ name }.url` }
      label={ label }
      // placeholder={ placeholder }
      helperText={ helperText }
      initialValue={ { assets: assetMap } }
    />
  ) );
}


export const useGetTaggedAssets = ( tagIds?: Identifier | Identifier[] | string | string[], enabled = true ): ReturnType<typeof useGetList> => {
  return useGetList( 'texts', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'updatedAt', order: 'DESC' },
    filter: { tags: tagIds },
  }, { enabled } );
}

// // NB - loaded v data timing issue in useGetMany. Case #409 plus https://github.com/marmelab/react-admin/issues/6420
// export const useGetAssetImages = ( assets: RaRecord[] = [], enabled = true ): ReturnType<typeof useGetMany> => {
//   const assetImageIds = assets.flatMap( asset => asset.images );
//   return useGetMany( 'images', { ids: assetImageIds }, { enabled } );
// }

export const useGetAssetImages = ( assets: RaRecord[] = [], enabled = true ): ReturnType<typeof useGetList> => {
  const assetImageIds = Object.values( assets ).flatMap( asset => asset.images );
  return useGetList( 'images', {
    pagination: { page: 1, perPage: Math.max( 1, assetImageIds.length ) },
    sort: { field: 'updatedAt', order: 'DESC' },
    filter: { id: assetImageIds.length > 0 ? assetImageIds : '000000000000000000000000' }, // bc useGetMany is broken and rules of hooks
  }, { enabled } );
}

export const useGetAssets = ( tagIds?: Identifier | Identifier[] | string | string[], enabled = true ): {
  data?: AssetMap,
  isLoading: boolean,
  refetch: ReturnType<typeof useGetList>[ 'refetch' ],
} => {
  const [ data, setData ] = useState<AssetMap | undefined>();

  // TODO HERE refetch both
  // refetch should probably clear ready & data, refetch assets, trigger refetch images
  const { data: assets, isLoading: isAssetsLoading, refetch } = useGetTaggedAssets( tagIds, enabled );
  const { data: images, isLoading: isImagesLoading } = useGetAssetImages( assets, !isAssetsLoading );

  useEffect( () => {
    ( async () => {
      if( !assets || !images || isAssetsLoading || isImagesLoading ) return;

      const assetRecords: AssetRecord[] = Object.values( assets ) as AssetRecord[];
      const [ crop, rotation ] = [ defaultCrop, defaultRotation ];
      const findAssetWithTag = ( tagId: Identifier ): AssetRecord | undefined => {
        return assetRecords.find( asset => !!asset.tags.find( ( tag: Identifier ) => tag == tagId ) );
      };
      const assetMap: AssetMap = {};
      for( const { name } of assetProps ) {
        const tagId = getCommonTagId( name );
        const asset = findAssetWithTag( tagId );
        if( !asset ) continue;
        set( assetMap, [ name, 'id' ], asset.id );
        set( assetMap, [ name, 'body' ], asset.body );
        for( let idx = 0; idx < asset.images.length; idx++ ) {
          // const image = images.find( image => image && image.id == asset.images[ idx ] ); // useGetMany
          const image = images.find( i => i.id == asset.images[ idx ] );
          if( !image ) continue;
          const url = `data:${ image.mimeType };base64,${ image.data }`;
          const rawFile = await convertDataUrlToBlob( url );
          set( assetMap, [ name, 'url', idx ], { url, crop, rotation, rawFile } )
        }
      }
      setData( assetMap );
    } )();
  }, [ assetProps, assets, images, isAssetsLoading, isImagesLoading, setData ] );

  return {
    data,
    isLoading: isAssetsLoading || isImagesLoading && !data,
    refetch,
  };
}
