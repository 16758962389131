import { Autocomplete, AutocompleteProps, Box, Card, CardContent, FormHelperText, Grid, TextField as MuiTextField, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { debounce } from 'lodash';
import { FC, MouseEvent, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Title, useAuthenticated, useGetList } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { apiUrl, httpClient } from './DataProvider';
import { EditorMode, labelOfMode, Option, TelecomEditorForm } from './TelecomEditorForm';


export const TelecomEditor: FC = () => {
  const { state } = useLocation();
  const { recipient, search = '' } = state || {};
  useAuthenticated();
  const [ _location, setLocation ] = useAppLocationState();
  const [ mode, setMode ] = useState<EditorMode>( 'recipients' );
  const [ value, setValue ] = useState<Option | null>( null );
  const [ more, setMore ] = useState( false );
  const [ emrData, setEmrData ] = useState<Option[] | undefined>();
  const [ isLoadingEmr, setIsLoadingEmr ] = useState( false );
  const [ controller, setController ] = useState<AbortController | undefined>();
  const [ inputValue, setInputValue ] = useState<string>( search );
  const inputRef = useRef<HTMLInputElement>();
  const { data, isLoading: isLoadingOptions, refetch } = useGetList<Option>( mode, {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'fullName', order: 'ASC' },
    filter: {
      active: true,
      q: apiUrl.match( /:\/\/(localhost|gunter|test)[:.]/ ) && mode == 'recipients' && inputValue == '' ? 'Amelia Ballard' : inputValue,
      ...( mode == 'recipients' ? { recipientType: 'Patient' } : {} ),
    },
  }, {
    enabled: !more,
  } );

  useEffect( () => {
    setLocation( 'contacteditor' );
    return () => setLocation( null );
  }, [] );

  useEffect( () => {
    if( !recipient || !setValue ) return
    setValue( recipient );
  }, [ recipient, setValue ] );

  useEffect( () => {
    if( !search || !inputRef?.current ) return
    inputRef.current.focus(); // enables "auto focus (and open) when "search" is specified
  }, [ search, inputRef ] );

  useEffect( () => {
    setLocation( `contacteditor.resource.${ mode }`, { resource: mode } );
  }, [ mode ] );

  const fetchNewEmr = useCallback( debounce( () => {
    httpClient( `${ apiUrl }/recipients/actions/fetchNewEmr`, { method: 'POST' } );
  }, 10000, { leading: true, trailing: true } ), [] )

  useEffect( () => {
    if( isLoadingOptions ) return;
    fetchNewEmr();
  }, [ mode, isLoadingOptions ] );

  useEffect( () => { setMore( false ); }, [ mode ] ); // clear more on mode change

  useEffect( debounce( () => {
    if( !more || isLoadingEmr ) return;
    if( inputValue.length < 4 ) {
      setEmrData( undefined );
      setIsLoadingEmr( false );
      return;
    }
    if( controller ) {
      console.log( 'aborted fetch' );
      controller.abort();
    }
    const newController = new AbortController();
    console.log( 'create controller' );
    setController( newController );
    const { signal } = newController;
    setEmrData( undefined );
    setIsLoadingEmr( true );
    console.log( '  input: ', inputValue );
    ( async () => {
      try {
        const { json: names } = await httpClient( `${ apiUrl }/data/recipients/searchMore?query=${ encodeURIComponent( inputValue ) }`, { signal } );
        setEmrData( names );
      } catch( e ) {
        setEmrData( undefined );
      }
      setController( undefined );
      console.log( ' clear controller' );
      setIsLoadingEmr( false );
    } )();
  }, 2000 ), [ more, inputValue ] );


  const options = useMemo( (): Option[] | undefined => more ? emrData : data, [ emrData, data, more ] );
  const isLoading = useMemo( (): boolean => more ? isLoadingEmr : isLoadingOptions, [ more, isLoadingOptions, isLoadingEmr ] );

  const handleModeChange = ( _event: MouseEvent<HTMLElement>, newMode: EditorMode ) => {
    setValue( null );
    if( !newMode ) return;  // clicking active keeps active, resets value
    setMode( newMode );
  };

  const handleChange = async ( _event: unknown, newValue: Option | null ) => {
    setValue( newValue );
  };

  const getOptionLabel = ( option?: Option | string ): string | ReactElement => { // function(props: object, option: T, state: object) => ReactNode
    if( !option ) return '';
    if( typeof option === 'string' ) return option;
    if( mode == 'practitioners' ) return option.fullName ?? '';
    if( !value ) {
      const telecomOption = option?.telecomOptions.find( t => t.value?.toLowerCase().includes( inputValue.toLowerCase() ) );
      if( inputValue && telecomOption ) {
        return `${ telecomOption?.value } - ${ option?.fullName }`;
      }
    }
    const identifiers = ( option?.identifier || [] ).map( i => i.id ).join( ' / ' );
    return (
      <Grid container justifyContent='space-between'>
        <Grid item>
          { `${ ( option?.birthDate ?? '          ' ).slice( 0, 10 ) } ${ option?.fullName }` }
        </Grid>
        <Grid item>
          { identifiers }
        </Grid>
      </Grid>
    );
  };

  const getOptionValue = ( option?: Option | string ): string => { // function(props: object, option: T, state: object) => ReactNode
    if( !option ) return '';
    if( typeof option === 'string' ) return option;
    if( mode == 'practitioners' ) return option.fullName ?? '';
    if( !value ) {
      const telecomOption = option?.telecomOptions.find( t => t.value?.toLowerCase().includes( inputValue.toLowerCase() ) );
      if( inputValue && telecomOption ) {
        return `${ telecomOption?.value } - ${ option?.fullName }`;
      }
    }
    return `${ ( option?.birthDate ?? '          ' ).slice( 0, 10 ) } ${ option?.fullName }`;
  };

  const renderOption: NonNullable<AutocompleteProps<Option, false, false, false>[ 'renderOption' ]> = ( props, option ): ReactNode => {
    return (
      <li { ...props } key={ option.id }>
        { getOptionLabel( option ) }
      </li>
    );
  };

  return <>
    <Card
      sx={ {
        // padding: '1em',
        minHeight: '25em',
      } }
    >
      <Title title='Contact Editor' />
      { /* <CardHeader title="Contact Editor" titleTypographyProps={ { fontSize: '1rem' } } /> */ }
      <CardContent>
        <ToggleButtonGroup
          color="primary"
          value={ mode }
          exclusive
          onChange={ handleModeChange }
          sx={ {
            marginBottom: '1em',
          } }
        >
          <ToggleButton value="recipients">Patient</ToggleButton>
          <ToggleButton value="practitioners">Practitioner</ToggleButton>
        </ToggleButtonGroup>

        <Grid container spacing={ 2 }>
          <Grid item key='input' xs={ 7 }>
            <Box
              sx={ {
                marginBottom: '1.5rem',
              } }
            >
              <Autocomplete<Option>
                id="select"
                openOnFocus
                style={ {
                  maxWidth: 600,
                } }
                renderOption={ renderOption }
                getOptionLabel={ getOptionValue }
                isOptionEqualToValue={ ( option, value ) => {
                  return typeof option === 'string' ? option === value : option.id === value.id
                } }
                filterOptions={ ( x ) => x }
                value={ value }
                inputValue={ inputValue }
                options={ options || [] }
                // autoHighlight
                // autoComplete
                loading={ isLoading }
                onChange={ handleChange }
                onInputChange={ ( _event, newInputValue ) => {
                  setInputValue( newInputValue );
                  refetch(); // throttle( refetch, 1000 ); //fetchOptions();
                } }
                renderInput={ ( params ) => <MuiTextField { ...params } inputRef={ inputRef } label={ labelOfMode( mode ) } variant="outlined" /> }
              />
              { !value &&
                <FormHelperText
                  sx={ {
                    marginTop: 0,
                    marginLeft: '1rem',
                  } }>
                  Search by name{ mode != 'practitioners' && ', date of birth, phone, email or identifier/MRN' }
                </FormHelperText>
              }
            </Box>
          </Grid>
          { /* mode == 'recipients' &&
       <Grid item key='button' ml={ 2 } mt={ 1 }>
       <ToggleButton
       size='small'
       color='primary'
       value='checked'
       disabled={ !!value }
       selected={ more }
       onChange={ () => { setMore( !more ); } }
       >
       Search EMR
       </ToggleButton>
       </Grid>
     */ }
        </Grid>

        { mode && value?.id &&
          <TelecomEditorForm id={ value.id } resource={ mode } />
        }

      </CardContent>
    </Card >
  </>;
};




