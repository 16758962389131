import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton, Typography, useTheme } from '@mui/material';
import { DataGridPremium, GridColType } from '@mui/x-data-grid-premium';
import { AxisTick } from '@nivo/axes';
import { Bar, BarDatum } from '@nivo/bar';
import { useCanAccess } from '@react-admin/ra-rbac';
import { addMonths } from 'date-fns';
import _, { reverse, sumBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Loading } from 'react-admin';
import { ExportOnlyToolbar } from './DashboardReputationActivity';
import { makeLineChartSliceTooltip, makeLineChartTooltip, MonthlyLineChartSliceTooltipHeader } from './DashboardTooltip';
import { ChartProps, colorPalettes, elevation, OnlyNumbers } from './DashboardWidget';
import { Dayjs } from 'dayjs';
import { apiUrl, httpClient } from './DataProvider';
import { useUserPreference } from './UserPreferences';

interface ReviewTrendDatum extends BarDatum {
  date: string;
  md: string;
  ymd: string;
  '1 star': number;
  '2 stars': number;
  '3 stars': number;
  '4 stars': number;
  '5 stars': number;
}

export const DashboardReviewTrend: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const { canAccess: canAccessSystem } = useCanAccess( { action: 'manage', resource: 'system' } );
  const isSmall = false;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationMonths = 6;
  const cacheKey = 'dashboard-review-trend';
  const [ isLoading, setIsLoading ] = useState( true );
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  const [ data, setData ] = useState<ReviewTrendDatum[]>( [] );
  const isEmpty = useMemo( () => {
    return !data.find( datum => {
      for( const key in datum ) {
        if( key == 'id' ) continue;
        if( datum[ key ] ) return true;
      }
      return false;
    } )
  }, [ data ] );
  const keys: ( keyof OnlyNumbers<ReviewTrendDatum> )[] = [ '1 star', '2 stars', '3 stars', '4 stars', '5 stars' ];
  const colors = useMemo( () => colorPalettes.spectrumRedGreen, [ colorPalettes ] );

  const durationMonths = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences ] );

  const tickMonths = useMemo( () => {
    const skipSize = Math.ceil( data.length / 9 );
    return data.map( d => d.id ).filter( ( _d, i ) => !( i % skipSize ) );
  }, [ data ] );

  const yMax = useMemo( () => Math.max( ...[ 10, ...data.flatMap( row => _( row ).pick( keys ).values().sum() ) ] ), [ data ] );

  const tickValues = useMemo( () => {
    const end = Math.ceil( yMax / 20 ) * 20;
    const step = end / 4;
    return _.range( step, end + step, step );
  }, [ yMax ] );

  const fetchSummary = useCallback( async ( date: Dayjs | undefined | null ) => {
    const query: Record<string,string> = {
      months: `${durationMonths}`,
    };
    if( date ) query.date = date.toISOString();
    const { body } = await httpClient( `${ apiUrl }/summaries/reviewTrend?${ new URLSearchParams( query ) }` );
    return body;
  }, [ durationMonths, httpClient ] );

  useEffect( () => {
    ( async () => {
      if( data.length || !isLoading ) return;
      const json = localStorage.getItem( cacheKey );
      if( data.length || !json || !isLoading ) return;
      try {
        const rows = JSON.parse( json ) as ReviewTrendDatum[];
        setData( rows );
        setIsLoading( false );
      } catch( e ) { return; }
    } )()
  }, [ data, setData, isLoading ] );

  useEffect( () => {
    ( async () => {
      const body = await fetchSummary( date );
      const rows = JSON.parse( body ) as ReviewTrendDatum[];
      setData( rows );
      setIsLoading( false );
      localStorage.setItem( cacheKey, JSON.stringify( rows ) );
    } )()
  }, [ setData, fetchSummary, date ] );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Review Trend</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <IconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </IconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationMonths } Months Ending ${ date.format( 'MMM YYYY' ) }` : `Previous ${ durationMonths } Months` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
            // fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative',
            } }
          >
            { showDatagrid
              ? <Box
                width={ 500 }
                height={ 400 + 48 + 24 }
                sx={ {
                  marginTop: '-48px',
                  marginBottom: '-24px',
                } }
              >
                <DataGridPremium
                  density='compact'
                  disableRowSelectionOnClick
                  disableColumnResize
                  disableColumnSelector
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnPinning
                  disableChildrenSorting
                  disableMultipleColumnsSorting
                  pagination
                  autoPageSize
                  rows={ ( data || [] ).map( ( d, id ) => ( { id, ...d } ) ) }
                  columns={ [
                    { field: 'ymd', headerName: 'Date', sortable: true, flex: 2 },
                    // @ts-ignore: 2322
                    ...keys.map( key => ( {
                      type: 'number' as GridColType,
                      field: key,
                      headerName: key,
                      sortable: false,
                      flex: 1,
                    } ) ),
                  ] }
                  slots={ {
                    toolbar: ExportOnlyToolbar.bind( undefined, { fileName: cacheKey } ),
                  } }
                  sx={ {
                    borderColor: 'transparent',
                    '& .MuiDataGrid-columnHeaders,& .MuiDataGrid-cell': {
                      borderBottomColor: 'transparent',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      overflow: 'hidden',
                    },
                  } }
                  initialState={ {
                    sorting: {
                      sortModel: [ { field: 'ymd', sort: 'desc' } ],
                    }
                  } }
                />
              </Box>
              : <>

                <Bar
                  width={ 500 }
                  height={ 400 }
                  data={ isEmpty ? [] : data }
                  keys={ keys }
                  margin={ { top: 50, right: 50, bottom: 55, left: 75 } }
                  padding={ 0.25 }
                  gridYValues={ tickValues }

                  isInteractive={ !isEmpty }

                  maxValue={ tickValues[ tickValues.length - 1 ] }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                        padding: '5px 9px',
                      },
                    },
                  } }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } //{ scheme: colors, size: keys.length } }
                  enableGridX={ false }
                  enableGridY={ true }
                  enableLabel={ false }
                  axisTop={ null }
                  axisRight={ null }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: tickValues,
                    legend: 'Ratings',
                    legendPosition: 'middle',
                    legendOffset: -67

                  } }

                  axisBottom={ {
                    tickSize: 5,
                    tickPadding: 5,
                    renderTick: ( props => tickMonths.includes( props.value ) ? <AxisTick {...props}/> : <></> ),
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }

                  legends={ [ {
                    anchor: 'top-left',
                    direction: 'row',
                    justify: false,
                    dataFrom: 'keys',
                    translateX: -45,
                    translateY: -40,
                    itemsSpacing: 45,
                    itemWidth: 50,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 1, // 0.95,
                    symbolSize: 18, // 20,
                    symbolShape: 'circle', // 'square',
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1
                        }
                      }
                    ],
                    toggleSerie: true,
                  } ] }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card >

  )
}
