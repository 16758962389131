import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license';
import { initSupertokens } from './SupertokensProvider';
// import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';

initSupertokens();


LicenseInfo.setLicenseKey( 'ac76026faf68c469b19b64a8a0d412e3Tz04MDMyNyxFPTE3MzM3OTUxMTYwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y' );

const root = createRoot(
  document.getElementById( 'root' ) as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals( console.log );
reportWebVitals( () => { return; } );
