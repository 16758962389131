import { Box, Card, CardContent, CardHeader, Tab, Tabs } from '@mui/material';
import { DataGridPremium, GridRowParams, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import { FC, useCallback, useEffect, useState } from 'react';
import { useGetMany, useRedirect } from 'react-admin';
import { renderPhoneNumber } from './AwesomePhoneInput';
import { ChartProps, elevation } from './DashboardWidget';
import { apiUrl, httpClient } from './DataProvider';

interface DashboardUndeliverableDatum {
  _id: string;
  recipientId: string;
  date: string;
  recipientFullName: string;
  value: string;
}

export const DashboardUndeliverables: FC<ChartProps> = () => {
  const redirect = useRedirect();
  const [ isLoading, setIsLoading ] = useState( true );
  const [ data, setData ] = useState<DashboardUndeliverableDatum[]>( [] );
  const { data: recipients } = useGetMany( 'recipients', { ids: ( data || [] ).map( d => d.recipientId ) }, { enabled: data?.length > 0 } );
  const [ tab, setTab ] = useState( 'Emails' );
  const cacheKey = () => `dashboard-undeliverables-${ tab.toLowerCase() }`;

  const onTabChange = useCallback( ( v: string ) => {
    setTab( v );
    localStorage.setItem( `dashboard-undeliverables-tab`, v );
  }, [] );

  const fetchSummary = useCallback( async ( tab: string ) => {
    const { body } = await httpClient( `${ apiUrl }/summaries/undeliverable${ tab }` );
    return body;
  }, [ httpClient ] );

  useEffect( () => {
    ( async () => {
      if( data.length || !isLoading ) return;
      const json = localStorage.getItem( cacheKey() );
      if( data.length || !json || !isLoading ) return;
      try {
        setData( JSON.parse( json ) );
        setIsLoading( false );
      } catch( e ) { return; }
    } )()
  }, [ data, setData, isLoading, tab ] );

  useEffect( () => {
    ( async () => {
      setIsLoading( true );
      const body = await fetchSummary( tab );
      const rows = JSON.parse( body ) as DashboardUndeliverableDatum[];
      setData( rows );
      localStorage.setItem( cacheKey(), JSON.stringify( rows ) );
      setIsLoading( false );
    } )()
  }, [ setData, fetchSummary, tab ] );

  const onClickRecipient = ( id: string ) => {
    const recipient = ( recipients || [] ).find( r => r.id == id );
    if( !recipient ) return;
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  function ExportOnlyToolbar() {
    return (
      <GridToolbarContainer
        sx={ {
          height: '3rem',
          '& > .MuiButton-root': {
            marginTop: '-2rem',
          }
        } }
      >
        <Tabs
          variant='standard'
          value={ tab }
          onChange={ ( _e, v ) => onTabChange( v ) }
          sx={ {
            marginTop: '-2rem',
          } }
        >
          <Tab label='Email' value='Emails' />
          <Tab label='Phone' value='Phones' />
        </Tabs>
        <Box sx={ { flexGrow: 1 } } />
        <GridToolbarExport
          csvOptions={ {
            fileName: cacheKey(),
            utf8WithBom: true,
            allColumns: true,
            // getRowsToExport: getRowsWithoutGroups,
          } }
          excelOptions={ {
            fileName: cacheKey(),
            allColumns: true,
            // getRowsToExport: getRowsWithoutGroups,
            // exceljsPreProcess,
            // exceljsPostProcess,
          } }
          printOptions={ {
            fileName: cacheKey(),
            disableToolbarButton: true,
            hideToolbar: true,
            hideFooter: true,
            includeHeaders: false,
          } }

        />
      </GridToolbarContainer>
    );
  }

  return (
    ( <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title="Undeliverables"
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      // subheader={ date ? `7 Days Starting ${ date.format( 'MMM D' ) }` : 'Next 7 Days' }
      // subheaderTypographyProps={ {
      //   sx: {
      //     fontSize: '0.9rem',
      //   }
      // } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <Box
          sx={ {
            position: 'relative',
            width: 500,
            height: 440,
            marginTop: '-1.2rem',
            // marginBottom: '1rem',
          } }
        >
          <DataGridPremium
            density='compact'
            disableRowSelectionOnClick
            disableColumnResize
            disableColumnSelector
            disableColumnMenu
            disableColumnReorder
            disableColumnPinning
            disableChildrenSorting
            disableMultipleColumnsSorting
            // hideFooter
            pagination
            autoPageSize
            onRowClick={ ( params: GridRowParams<DashboardUndeliverableDatum> ) => {
              onClickRecipient( params.row.recipientId )
            } }
            rows={ isLoading
              ? []
              : data.map( ( d, id ) => {
                const { recipientId, recipientFullName, date: dateString, value: rawValue } = d;
                const value = tab == 'Phones' ? renderPhoneNumber( rawValue ) : rawValue;
                const date = new Date( dateString ).toISOString().slice( 0, 10 );
                return { id, recipientId, recipientFullName, date, value };
              } ) }
            columns={ [
              { field: 'recipientFullName', headerName: 'Recipient', sortable: true, flex: 1 },
              { field: 'value', headerName: tab == 'Phones' ? 'Phone' : 'Email', sortable: true, flex: 1 },
              { field: 'date', headerName: tab == 'Phones' ? 'Checked' : 'Last message', sortable: true, align: 'right', headerAlign: 'right', flex: 1 },
            ] }
            slots={ {
              toolbar: ExportOnlyToolbar,
            } }
            sx={ {
              borderColor: 'transparent',
              '& .MuiDataGrid-columnHeaders,& .MuiDataGrid-cell': {
                borderBottomColor: 'transparent',
              },
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'hidden',
              },
            } }
          />
        </Box>

      </CardContent>
    </Card > )
  );

}

