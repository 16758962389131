import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { DataGridPremium, GridRowParams } from '@mui/x-data-grid-premium';
import { BarDatum } from '@nivo/bar';
import { Dayjs } from 'dayjs';
import { FC, useCallback, useEffect, useState } from 'react';
import { useGetMany, useRedirect } from 'react-admin';
import { ChartProps, elevation } from './DashboardWidget';
import { ExportOnlyToolbar } from './DashboardReputationActivity';
import { apiUrl, httpClient } from './DataProvider';


interface UnreachableAppointmentDatum extends BarDatum {
  _id: string; // appointment Id
  patient: string; // patient id 
  appointmentTypeDisplay: string; // "Cataract Work Up",
  date: string; //  "2024-08-15",
  locationFullName: string; //  "Linda Vision RMD",
  patientFullName: string; //  "Thuan Nguyen",
  practitionerFullName: string; //  "Bach Lan Linda Vu",
  startTime: string; //  "2024-08-15T15:45:00.000Z",
  status: string; //  "pending",
}

export const DashboardUnreachableAppointments: FC<ChartProps> = ( { date } ) => {
  const redirect = useRedirect();
  const cacheKey = 'dashboard-unreachable-appointments';
  const [ isLoading, setIsLoading ] = useState( true );
  const [ data, setData ] = useState<UnreachableAppointmentDatum[]>( [] );
  const { data: recipients } = useGetMany( 'recipients', { ids: ( data || [] ).map( d => d.patient ) }, { enabled: data?.length > 0 } );

  const fetchSummary = useCallback( async ( date: Dayjs | undefined | null ) => {
    const { body } = await httpClient( `${ apiUrl }/summaries/appointmentsWithoutNotice${ date ? `?date=${ encodeURIComponent( date.toISOString() ) }` : '' }` );
    return body;
  }, [ httpClient ] );

  useEffect( () => {
    ( async () => {
      setIsLoading( true );
      const body = await fetchSummary( date );
      const rows = JSON.parse( body ) as UnreachableAppointmentDatum[];
      setData( rows );
      localStorage.setItem( cacheKey, JSON.stringify( rows ) );
      setIsLoading( false );
    } )()
  }, [ setData, fetchSummary, date ] );

  const onClickRecipient = ( id: string ) => {
    const recipient = ( recipients || [] ).find( r => r.id == id );
    if( !recipient ) return;
    redirect( '/contacteditor', undefined, undefined, undefined, { recipient } )
  };

  return (
    ( <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        height: '100%',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title="Upcoming Unreachable Appointments"
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `7 Days Starting ${ date.format( 'MMM D' ) }` : 'Next 7 Days' }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <Box
          sx={ {
            position: 'relative',
            width: 500,
            height: 400 + 48 + 24,
            marginTop: '-48px',
            marginBottom: '-24px',
          } }
        >
          <DataGridPremium
            density='compact'
            disableRowSelectionOnClick
            disableColumnResize
            disableColumnSelector
            disableColumnMenu
            disableColumnReorder
            disableColumnPinning
            disableChildrenSorting
            disableMultipleColumnsSorting
            // hideFooter
            pagination
            autoPageSize
            onRowClick={ ( params: GridRowParams<UnreachableAppointmentDatum> ) => {
              onClickRecipient( params.row.patient )
            } }
            rows={ isLoading
              ? []
              : data.map( ( d, id ) => {
                const { patient, patientFullName, date, status, practitionerFullName, appointmentTypeDisplay } = d;
                return { id, patient, patientFullName, date, status, practitionerFullName, appointmentTypeDisplay };
              } ) }
            columns={ [
              { field: 'date', headerName: 'Appointment', flex: 2, sortable: true },
              { field: 'patientFullName', headerName: 'Patient', sortable: false, flex: 2 },
              { field: 'practitionerFullName', headerName: 'Practitioner', sortable: false, flex: 2 },
              { field: 'appointmentTypeDisplay', headerName: 'Type', flex: 2, sortable: true },
            ] }
            slots={ {
              toolbar: ExportOnlyToolbar.bind( undefined, { fileName: cacheKey } ),
            } }
            sx={ {
              borderColor: 'transparent',
              '& .MuiDataGrid-columnHeaders,& .MuiDataGrid-cell': {
                borderBottomColor: 'transparent',
              },
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'hidden',
              },
            } }
          />
        </Box>

      </CardContent>
    </Card > )
  );

}

