import { RestorePageOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { addMinutes } from 'date-fns';
import { without } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { AutocompleteInput, BooleanField, BooleanInput, Create, CreateProps, Datagrid, DateField, DateInput, DateTimeInput, Edit, EditProps, FilterButton, List, ListProps, NullableBooleanInput, Pagination, RaRecord, ReferenceField, ReferenceInput, ReferenceManyField, SelectField, SelectInput, Show, ShowProps, SimpleFormProps, SimpleShowLayout, SortPayload, Tab, TextField, TextInput, TopToolbar, useGetOne, useListContext, useNotify, useRecordContext, useResourceContext } from 'react-admin';
import { apiUrl, choices, httpClient, options, queryClient } from './DataProvider';
import { SimpleFormWithLocation, TabbedShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import { ShowActionsOnShowTabOnly } from './ShowActionsOnShowTabOnly';
import { ActionButtonWithConfirm } from './ShowActionsWithReset';
import { Childless } from './types';

export { default as AppointmentIcon } from '@mui/icons-material/Event';

export const AppointmentRepresentation: FC = () => {
  const record = useRecordContext();
  const { data: patient, isLoading } = useGetOne( 'recipients', { id: record?.patient, meta: { enabled: !!record } } );

  if( isLoading || !record ) return null;
  return <span>`${ record.startTime.toLocaleString() } ${ patient.fullName }`</span>;
};

const appointmentsFilter = [
  <DateInput label="Appointment Date" source="startTime" alwaysOn
    parse={ ( v ) => !v ? v : addMinutes( v, v.getTimezoneOffset() ).toISOString().slice( 0, 10 ) }
  />
  , <ReferenceInput label="Patient" source="patient" reference="recipients" filter={ { status: 'active', recipientType: 'Patient' } } sort={ { field: 'fullName', order: 'ASC' } } allowEmpty alwaysOn >
    <AutocompleteInput sx={ { width: '22rem' } } optionText={ choice => choice ? `${ choice.birthDate?.slice( 0, 10 ) ?? '' } ${ choice.fullName ?? '' }` : '' } />
  </ReferenceInput>
  , <ReferenceInput label="Practitioner" source="practitioner" reference="practitioners" filter={ { active: true } } sort={ { field: 'fullName', order: 'ASC' } } allowEmpty alwaysOn >
    <AutocompleteInput optionText="fullName" sx={ { width: '16rem' } } />
  </ReferenceInput>
  , <ReferenceInput label="Location" source="location" reference="locations" filter={ { status: 'active' } } sort={ { field: 'name', order: 'ASC' } } allowEmpty alwaysOn >
    <AutocompleteInput sx={ { width: '22rem' } } />
  </ReferenceInput>
  , <SelectInput label="Status" source="status" choices={ choices.appointmentStatus } />
  , <NullableBooleanInput source="active" />
  , <TextInput label="ID (24 char hex)" source="id" />
  , <TextInput label="FHIR ID" source="fhirId" />
];

export const AppointmentListActions: FC = () => {
  const resource = useResourceContext();
  const notify = useNotify();
  const { data, filterValues } = useListContext<RaRecord & { status: string }>();
  const { startTime } = ( filterValues || {} ) as { startTime?: string };
  const isHistorical = useMemo<boolean>( () => !!startTime && startTime != new Date().toISOString().slice( 0, 10 ), [ startTime ] );
  const unfinishedStatuses = without( options.appointmentStatus, 'fulfilled', 'checked-in', 'cancelled', 'noshow', 'entered-in-error', 'waitlist' );
  const hasUnfinshed = useMemo( () => !data || !data.length || data.find( d => unfinishedStatuses.includes( d.status ) ), [ data ] );
  const [ running, setRunning ] = useState( false );

  const handleResync = async () => {
    if( !isHistorical || !startTime ) return;
    setRunning( true );
    const { body: _body } = await httpClient( `${ apiUrl }/${ resource }/actions/reloadHistorical?date=${ startTime }`, {
      method: 'POST',
    } );
    await queryClient.invalidateQueries( { queryKey: [ resource ] } );
    setRunning( false );
    notify( 'app.confirmation.action.appointments.reloadHistorical', { type: 'success' } );
    // navigate( '/anomalies/anomalyreports' );
  }

  return (
    <TopToolbar>
      { isHistorical && hasUnfinshed &&
        <IfCanAccess resource='system' action='manage' >
          <ActionButtonWithConfirm
            color='error'
            confirmTitle='Request resync of appointments'
            confirmContent={ `Are you sure you want to request resync of appointments from the EMR for ${ startTime }?` }
            label='Resync day'
            onClick={ handleResync }
            disabled={ !isHistorical || running }
            startIcon={ running ? <CircularProgress size='small' /> : undefined }
          >
            <RestorePageOutlined />
          </ActionButtonWithConfirm >
        </IfCanAccess>
      }
      <FilterButton />
    </TopToolbar>
  );
}

export const AppointmentList: FC<Childless<ListProps>> = props => {
  useListAppLocation( 'appointment' );
  const filterDefaultValues = { startTime: addMinutes( new Date(), new Date().getTimezoneOffset() ).toISOString().slice( 0, 10 ) };
  const sort: SortPayload = { field: 'startTime', order: 'ASC' };

  return (
    <List
      exporter={ false }
      filterDefaultValues={ filterDefaultValues }
      filters={ appointmentsFilter }
      perPage={ 25 }
      sort={ sort }
      actions={ <AppointmentListActions /> }

      { ...props }
    >
      <Datagrid rowClick="show"
        // expand={ <AppointmentRemindersShow /> }
        bulkActionButtons={ false }
      >

        <SelectField source="status" choices={ choices.appointmentStatus } />
        <ReferenceField source="patient" reference="recipients" link={ false }>
          <TextField source="fullName" />
        </ReferenceField>
        <DateField source="startTime" showTime />
        <ReferenceField label='Type' source="appointmentTypeCode" reference="appointmenttypepackages" link={ false } />
        <ReferenceField source="practitioner" reference="practitioners" link='show' >
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField source="location" reference="locations" link='show' />
      </Datagrid>
    </List>
  );
}

export const AppointmentShow: FC<ShowProps> = props => (
  <Show actions={ <ShowActionsOnShowTabOnly /> }
    { ...props }>
    <TabbedShowLayoutWithLocation basePath='appointment' >
      <Tab label="Appointment">
        <SelectField source="status" choices={ choices.appointmentStatus } />
        <ReferenceField source="patient" reference="recipients" link={ false }>
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField source="practitioner" reference="practitioners" link='show'>
          <TextField source="fullName" />
        </ReferenceField>
        <ReferenceField source="location" reference="locations" link='show'>
          <TextField source="name" />
        </ReferenceField>

        <DateField source="startTime" showTime />
        <DateField source="endTime" showTime />
        <ReferenceField label='Type' source="appointmentTypeCode" reference="appointmenttypepackages" link={ false } />

        <TextField source="id" />
        <TextField source="fhirId" />
        <BooleanField source="active" looseValue />
        <BooleanField source="realTimeUpdates" looseValue />
        <TextField source="comment" />

        <SelectField source="lastStatus" choices={ choices.appointmentStatus } />

        <DateField source="lastStartTime" />

        <BooleanField source="fhirSync" looseValue />
        <TextField source="fhirComment" />

        <DateField source="lastUpdatedPrev" />
        <DateField source="lastUpdated" />

        <SelectField source="entryMethod" choices={ choices.entryMethod } />
        <DateField source="createdDate" />
      </Tab>
      <Tab label="Reminders">
        <ReferenceManyField label="Reminders" reference="reminders" target="appointment"
          pagination={ <Pagination /> }
          sort={ { field: 'reminderCompletedDate', order: 'DESC' } }
        >
          <Datagrid bulkActionButtons={ false } >
            <TextField label="Template" source="reminderPatientTemplate" />
            <SelectField label="Purpose" source="messagePurpose" choices={ choices.messagePurpose } />
            <SelectField label="State" source="reminderState" choices={ choices.reminderState } />

            <DateField label="Scheduled" source="reminderRequestedFireDate" showTime />
            <DateField label="Completed" source="reminderCompletedDate" showTime />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayoutWithLocation>
  </Show >
);

export const AppointmentRemindersShow: FC<Omit<ShowProps, 'children'>> = props => (
  <Show { ...props } actions={ false } title="&nbsp;">
    <SimpleShowLayout>
      <ReferenceManyField label="Reminders" reference="reminders" target="appointment"
        perPage={ 100 }
        sort={ { field: 'reminderCompletedDate', order: 'DESC' } }
      >
        <Datagrid bulkActionButtons={ false } >
          <TextField label="Template" source="reminderPatientTemplateName" />
          <SelectField label="Type" source="reminderType" choices={ choices.reminderType } />
          <SelectField label="State" source="reminderState" choices={ choices.reminderState } />

          <DateField label="Scheduled" source="reminderRequestedFireDate" showTime />
          <DateField label="Completed" source="reminderCompletedDate" showTime />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show >
);


const AppointmentForm: FC<Omit<SimpleFormProps, 'children'>> = props => (
  <SimpleFormWithLocation basePath='appointment' sanitizeEmptyValues   { ...props }>
    <SelectInput source="status" choices={ choices.appointmentStatus } />

    <ReferenceInput source="patient" reference="appointments">
      <SelectInput optionText="fullName" />
    </ReferenceInput>

    <DateTimeInput source="startTime" />
    <DateTimeInput source="endTime" />

    <TextInput source="comment" />

    <ReferenceInput source="practitioner" reference="practitioners" >
      <SelectInput optionText="fullName" />
    </ReferenceInput>

    <ReferenceInput source="location" reference="locations" >
      <SelectInput />
    </ReferenceInput>

    <BooleanInput source="active" />
    <BooleanInput source="realTimeUpdates" />

    {/* 
        <SelectInput source="lastStatus" choices={ choices.appointmentStatus } />
        <DateInput source="lastStartTime" />
      */}
    <BooleanInput source="fhirSync" />
    {/* <TextInput source="fhirComment" /> */ }

    <DateInput source="lastUpdatedPrev" />
    <DateInput source="lastUpdated" />

    <SelectInput source="entryMethod" choices={ choices.entryMethod } />
    <DateInput source="createdDate" />

  </SimpleFormWithLocation>

);



export const AppointmentEdit: FC<EditProps> = props => ( <Edit { ...props } ><AppointmentForm /></Edit> );
export const AppointmentCreate: FC<CreateProps> = props => <Create { ...props } ><AppointmentForm /></Create>;
