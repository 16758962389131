/*eslint @typescript-eslint/no-unused-vars: "off" */
import { Help } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, Grid, ThemeOptions, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Bar, BarDatum } from '@nivo/bar';
import { Datum, Line, LineProps, PointTooltipProps, Serie } from '@nivo/line';
import { Pie, PieTooltipProps } from '@nivo/pie';
import { useAppLocationState } from '@react-admin/ra-navigation';
import { IfCanAccess, useCanAccess } from '@react-admin/ra-rbac';
import { addDays } from 'date-fns';
import { Dayjs } from 'dayjs';
import { capitalize } from 'inflection';
import { chunk, get, reverse, set, sortBy, sumBy } from 'lodash';
import { default as queryString } from 'query-string';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Loading, Title, useGetList, useRedirect } from 'react-admin';
import { AnomalyReportDocument } from './AnomalyReports';
import { apiUrl, httpClient } from './DataProvider';
import { useHasNoCharity, useHasNoEmr } from './Organizations';
import { PickByPropertyType } from './types';
import { DashboardReputationActivity } from './DashboardReputationActivity';
import { ChartProps, elevation } from './DashboardWidget';


interface NonprofitTrendDatum extends BarDatum {
  pledgeId: string;
  count: number;
  feedback: string;
}


export const DashboardTopPatientCharities: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const [ isLoading, setIsLoading ] = useState( true );
  const [ data, setData ] = useState<NonprofitTrendDatum[]>( [] );
  const [ nonprofitMap, setNonprofitMap ] = useState<Record<string, string> | undefined>();
  const top = 10;

  const fetchSummary = useCallback( async ( date: Dayjs | undefined | null ) => {
    const { body } = await httpClient( `${ apiUrl }/summaries/nonprofit7dayTrend${ date ? `?date=${ encodeURIComponent( date.toISOString() ) }` : '' }` );
    return body;
  }, [ httpClient ] );

  const fetchNonprofitName = useCallback( async ( id: string | undefined, feedback: string | undefined ): Promise<string | undefined> => {
    if( !id || !feedback ) return;
    const { body } = await httpClient( `${ apiUrl }/data/nonprofits/${ id }?t=${ feedback }` );
    try {
      const { name } = JSON.parse( body ) as { name: string };
      return name;
    } catch( e ) { return; }
  }, [ httpClient ] );

  useEffect( () => {
    if( nonprofitMap || data.length || !isLoading ) return;
    ( async () => {
      const json = localStorage.getItem( 'dashboard-nonprofit-trend' );
      if( data.length || !json || !isLoading ) {
        setNonprofitMap( {} );
        return;
      }
      try {
        setData( JSON.parse( json ) );
        const map = localStorage.getItem( 'dashboard-nonprofit-map' );
        setNonprofitMap( map ? JSON.parse( map ) : {} );
        setIsLoading( false );
      } catch( e ) {
        setNonprofitMap( {} );
        return;
      }
    } )()
  }, [ data, setData, isLoading, setIsLoading, nonprofitMap, setNonprofitMap ] );

  useEffect( () => {
    ( async () => {
      const body = await fetchSummary( date );
      const rows = JSON.parse( body ) as NonprofitTrendDatum[];
      setData( rows );
      const map = localStorage.getItem( 'dashboard-nonprofit-map' );
      setNonprofitMap( map ? JSON.parse( map ) : {} );
      // setIsLoading( false );
      localStorage.setItem( 'dashboard-nonprofit-trend', JSON.stringify( rows ) );
    } )()
  }, [ setData, fetchSummary, date ] );

  useEffect( () => {
    ( async () => {
      if( !nonprofitMap || !data.length ) return;
      const map = nonprofitMap;
      const feedback = data.find( d => d.feedback )?.feedback || '';
      const ids = Array.from( new Set( data.slice( 0, top ).map( d => d.pledgeId || '' ) ) );
      // setIsLoading( true );
      for( const id of ids ) {
        if( map[ id ] ) continue;
        const name = await fetchNonprofitName( id, feedback );
        if( name ) map[ id ] = name;
      }
      setNonprofitMap( map );
      setData( data ); // refresh page
      setIsLoading( false );
      localStorage.setItem( 'dashboard-nonprofit-map', JSON.stringify( map ) );
    } )();
  }, [ data, setNonprofitMap, nonprofitMap, setData, fetchNonprofitName, setIsLoading, date ] );


  return (
    ( <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title="Top Patient Charities"
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `7 Days Ending ${ date.format( 'MMM D' ) }` : 'Previous 7 Days' }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        <Box
          sx={ {
            position: 'relative',
            width: 500,
            height: 400,
            marginTop: '-0.5rem',
            marginBottom: '1rem',
          } }
        >
          <DataGridPremium
            density='compact'
            disableRowSelectionOnClick
            disableColumnResize
            disableColumnSelector
            disableColumnMenu
            disableColumnReorder
            disableColumnPinning
            disableChildrenSorting
            disableMultipleColumnsSorting
            hideFooter

            rows={ isLoading || !nonprofitMap
              ? []
              : data.slice( 0, top ).map( ( d, id ) => {
                const { pledgeId, count } = d;
                const charity = nonprofitMap[ pledgeId ] || '';
                return { id, charity, count };
              } ) }
            columns={ [
              { field: 'count', headerName: 'Count', type: 'number', sortable: false, flex: 0.5 },
              { field: 'charity', headerName: 'Charity', flex: 2, sortable: false },
            ] }
            sx={ {
              borderColor: 'transparent',
              '& .MuiDataGrid-columnHeaders,& .MuiDataGrid-cell': {
                borderBottomColor: 'transparent',
              },
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'hidden',
              },
            } }
          />
        </Box>

      </CardContent>
    </Card > )
  );

}

