/*eslint @typescript-eslint/no-unused-vars: "off"*/
import { green, grey, yellow } from '@mui/material/colors';
import { debounce, get } from 'lodash';
// import { useDefineAppLocation } from './ra-navigation/useDefineAppLocation';
import { Stack } from '@mui/material';
import { CreateInDialogButton, useFormDialogContext } from '@react-admin/ra-form-layout';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { BooleanField, BooleanInput, Create, CreateProps, Datagrid, DateField, Edit, EditButton, EditProps, EditView, Filter, FilterProps, Form, FormProps, FunctionField, ImageField, List, ListProps, NumberField, Pagination, RaRecord, ReferenceArrayField, ReferenceField, ReferenceManyField, ReferenceOneField, SaveButton, SearchInput, SelectField, Show, ShowProps, ShowView, SimpleFormProps, SimpleShowLayout, SortPayload, Tab, TextField, TextInput, TitleProps, Toolbar, UrlField, useCreate, useGetList, useNotify, useRecordContext, useUpdate, WithRecord } from 'react-admin';
import { PractitionerShowAssets } from './AssetShow';
import { renderPhoneNumber } from './AwesomePhoneInput';
import { apiUrl, choices, httpClient } from './DataProvider';
import { RemoveReviewSitesButton } from './Locations';
import { SimpleFormWithLocation, TabbedShowLayoutWithLocation, useListAppLocation } from './MyBreadcrumb';
import MyUrlField from './MyUrlField';
import { useEmr } from './Organizations';
import { CustomToolbar } from './ReminderTemplateLists';
import { ShowActionsOnShowTabOnly } from './ShowActionsOnShowTabOnly';
import { Childless } from './types';

export { default as PractitionerIcon } from '@mui/icons-material/PersonOutline';

export type ShowViewProps = Parameters<typeof ShowView>[ 0 ];
export type EditViewProps = Parameters<typeof EditView>[ 0 ];

const PractitionerTitle: FC<TitleProps> = props => {
  return <span>Practitioner { props.record?.fullName ? `"${ props.record.fullName }"` : '' }</span>;
};

const PractitionerFilter: FC<Omit<FilterProps, 'children'>> = ( props ) => (
  <Filter { ...props }>
    <SearchInput source="q" alwaysOn />
    <TextInput label="Suffix" source="suffix" />
    <TextInput label="NPI" source="npi" />
    <TextInput label="FHIR ID" source="fhirId" />
  </Filter>
);

export const PractitionerList: FC<Omit<ListProps, 'children'>> = props => {
  useListAppLocation( 'organization' );
  const emr = useEmr();
  const sort: SortPayload = { field: 'fullName', order: 'ASC' };
  const filter = { active: true };
  // const { data: serviceMap, isLoading } = useGetList( 'reputationservices' );

  const fetchNewEmr = useCallback( debounce( () => {
    httpClient( `${ apiUrl }/practitioners/actions/fetchNewEmr`, { method: 'POST' } );
  }, 10000, { leading: true, trailing: true } ), [] )

  useEffect( () => {
    fetchNewEmr();
  }, [ fetchNewEmr ] );

  return (
    <List { ...props }
      exporter={ false }
      filter={ filter }
      filters={ <PractitionerFilter /> }
      sort={ sort }
      perPage={ 25 }
    >
      <Datagrid rowClick="show" bulkActionButtons={ false } >
        <TextField label="NPI" source="npi" />
        <TextField source="prefix" />
        <TextField source="fullName" />
        <TextField source="suffix" />
        { [ 'PointClickCare' ].includes( emr ) &&
          <TextField source="providerType" />
        }

        { ![ 'PointClickCare' ].includes( emr ) &&
          <TextField source="fhirId" />
        }

        <FunctionField
          label="Mobile"
          source="telecomOptions"
          render={ ( record ): string => {
            const mobile = record?.telecomOptions?.find( ( o: Record<string, string> ) => o.system == 'phone' && o.use == 'mobile' )?.value;
            return renderPhoneNumber( mobile );
          } }
          sortable={ false }
        />
        <FunctionField
          label="Email"
          source="telecomOptions"
          render={ ( record ): string => {
            const email = record?.telecomOptions?.find( ( o: Record<string, string> ) => o.system == 'email' )?.value;
            return email;
          } }
          sortable={ false }
        />
        {/*
        <ArrayField label="Reputation Services" source="reputationServices">
          <SingleFieldList linkType={ false  } >
        <ReferenceField label="Service" source="id" reference="reputationservices"
          link={ false
            // ( record?: RaRecord ): string => {if( !record ) return ''; return serviceMap[ record.Id ]?.url + record.externalId || '';}
          }
        >
        <ChipField source="name" />
      </ReferenceField>
    </SingleFieldList>
    </ArrayField >
    */ }
        < EditButton label="" />
      </Datagrid >
    </List >
  );
}

export const PractitionerShow: FC<ShowProps> = props => {
  const appointmentRowStyle = ( { status }: RaRecord ): Record<string, unknown> => {
    const map = {
      fulfilled: grey[ '400' ],
      'checked-in': green[ '200' ],
      cancelled: yellow[ '200' ],
      // pending: 'yellow',
    };
    return { backgroundColor: get( map, status ) };
  }

  return (

    <Show
      actions={ <ShowActionsOnShowTabOnly /> }

      title={ <PractitionerTitle /> }
      { ...props }
    >
      <TabbedShowLayoutWithLocation basePath='organization'>
        <Tab label="Practitioner" >
          <TextField source="fullName" />
          <TextField source="prefix" />
          <TextField source="firstName" />
          <TextField source="middleName" />
          <TextField source="lastName" />
          <TextField source="suffix" />
          <TextField label="NPI" source="npi" />

          <TextField source="comment" />
          <BooleanField source="active" looseValue />
          <SelectField source="entryMethod" choices={ choices.entryMethod } />

          <BooleanField source='disableAppointmentRealTimeUpdates' looseValue />
          <BooleanField source='disableLabUpdates' looseValue />
          <BooleanField source='disableOfficeCommunication' looseValue />

          { /*
         <ArrayField label="Reputation Services" source="reputationServices">
         <Datagrid bulkActionButtons={ false } >
         <ReferenceField label="Service" source="id" reference="reputationservices" link={ false } >
         <TextField source="name" />
         </ReferenceField>
         <TextField label="ID" source="externalId" />
         </Datagrid>
         </ArrayField>
             */ }

        </Tab>
        <Tab path="appointments-today" label="Appointments Today">
          <ReferenceManyField label="" reference="appointments" target="practitioner"
            pagination={ <Pagination /> }
            sort={ { field: 'startTime', order: 'ASC' } }
            filter={ { startTime: new Date().toISOString().slice( 0, 10 ) } }
          >
            <Datagrid rowClick="show"
              bulkActionButtons={ false }
              rowStyle={ appointmentRowStyle }
            >
              <SelectField source="status" choices={ choices.appointmentStatus } />
              <TextField source="fhirId" />
              <DateField source="startTime" showTime />
              <ReferenceField source="patient" reference="recipients" link={ false }>
                <TextField source="fullName" />
              </ReferenceField>
              <ReferenceField source="location" reference="locations" link='show'>
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        { /*
      <Tab path="sms" label="SMS Today">
      <ReferenceManyField label="" reference="outboundmessages" target="recipient"
      pagination={ <Pagination /> }
      sort={ { field: 'postedDate', order: 'DESC' } }
            filter={ { transmitMethod: 'sms', postedDate: new Date().toISOString().slice( 0, 10 ) } }
      >
      <Datagrid rowStyle={ () => ( { verticalAlign: 'top' } ) } rowClick="show">
      <DateField label="Posted" source="postedDate" showTime />
      <DateField label="Delivered" source="smsDeliveredDate" showTime />
      <TextField label="SMS" source="message" component="pre" />
      </Datagrid>
      </ReferenceManyField>
      </Tab>
      */ }

        <Tab path="profile" label="Profile" >
          <PractitionerShowAssets imageAspect={ 3 / 4 } { ...props } />
        </Tab>

        { /*
      <TabWithPermissions path="reputation" label="Reputation" resource='reputations' rbacAction='list' >
      <ReferenceManyField label="" reference="reputations" target="practitioner"
      pagination={ <Pagination /> }
      sort={ { field: 'createdAt', order: 'DESC' } }
          >
            <Datagrid bulkActionButtons={ false } >
              <ReferenceField label="Service" source="reputationService" reference="reputationservices" link={ false } >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="rating" />
              <TextField source="ratingCount" />
              <TextField source="reviewCount" />
              <DateField source="createdAt" label="Updated" />
            </Datagrid>
          </ReferenceManyField>
        </TabWithPermissions>

        <TabWithPermissions path="reviews" label='Reviews' resource='reputations' rbacAction='list'>
          <ReferenceManyField label="" reference="reputationreviews" target="practitioner"
            pagination={ <Pagination /> }
            sort={ { field: 'createdAt', order: 'DESC' } }
          >
            <Datagrid
              rowClick="expand"
              expand={ <PractitionerReputationReviewExpandShow /> }
            >
              <ReferenceField label="Service" source="reputationService" reference="reputationservices" link={ false } >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="author" />
              <DateField source="datePublished" label="Date" />
              <TextField source="rating" />
            </Datagrid>
          </ReferenceManyField>
        </TabWithPermissions>
*/}

        <Tab path='review-sites' label='Review Sites'>
          <TextField source='fullName' label='Name' />
          <ReferenceOneField label='Review Sites' source='id' target='reference' reference='reputationsubjects' filter={ { kind: 'practitioner' } } >
            <WithRecord render={ ( { id: subjectId, name: subjectName } ) => (
              <ReferenceArrayField source='profiles' reference='reputationprofiles' >
                <Datagrid bulkActionButtons={ false }>
                  <ReferenceField source='platform' reference='reputationplatforms' >
                    <TextField source='name' />
                  </ReferenceField>
                  <ReferenceField label='Logo' source='platform' reference='reputationplatforms' >
                    <WithRecord render={ ( record ) => (
                      <ImageField source='src' record={ { src: `/${ record.id }.png` } } sx={ {
                        '& .RaImageField-image': {
                          width: '100px'
                        }
                      } } />
                    ) } />
                  </ReferenceField>
                  <ReferenceOneField label='Average Rating' source='id' target='profile' reference='reputationsummaries' sort={ { field: 'createdAt', order: 'DESC' } } >
                    <NumberField source='rating' options={ { maximumFractionDigits: 1 } } />
                  </ReferenceOneField>
                  <ReferenceOneField label='Rating Count' source='id' target='profile' reference='reputationsummaries' sort={ { field: 'createdAt', order: 'DESC' } } >
                    <NumberField source='count' />
                  </ReferenceOneField>
                  <UrlField source='url'
                    style={ {
                      textDecoration: 'none',
                    } }
                    rel='noopener'
                    target='_blank'
                    onClick={ e => { e.stopPropagation(); } }
                  />
                  <IfCanAccess action='manage' resource='system'>
                    <MyUrlField
                      source='url'
                      record={ {
                        url: `https://reputation.analoginformation.com/#/subjects?displayedFilters=%7B%22name%22%3Atrue%7D&filter=%7B%22name%22%3A%22${ encodeURIComponent( subjectName ) }%22%7D&order=ASC&page=1&perPage=10&sort=id`
                      } }
                      text='Rep Admin'
                    />
                  </IfCanAccess>
                  <RemoveReviewSitesButton subjectId={ subjectId } />
                </Datagrid>
              </ReferenceArrayField>
            ) } />
          </ReferenceOneField>
          <div>
            <WithRecord render={ ( record ) => (
              <CreateInDialogButton
                label="Add review site url"
                title={ `Add new review site url` }
                maxWidth='md'
                fullWidth
                record={ record }
              >
                <PractitionerReviewSiteUrlForm />
              </CreateInDialogButton>
            ) } />
          </div>
        </Tab>

      </TabbedShowLayoutWithLocation>

    </Show >
  );
}

export const PractitionerReviewSiteUrlForm: FC<Childless<FormProps>> = props => {
  const { close } = useFormDialogContext() || {};
  const practitioner = useRecordContext();
  const notify = useNotify();
  const reference = useMemo<string>( () => practitioner?.id.toString() || '', [ practitioner ] );
  const { data: subjects } = useGetList( 'reputationsubjects', { filter: { kind: 'Practitioner', reference } } );
  const { data: organizations } = useGetList<{ name: string } & RaRecord>( 'organizations' );
  const [ create ] = useCreate();
  const [ update ] = useUpdate();

  const testProfile = useCallback( async ( url: string ): Promise<RaRecord | undefined> => {
    try {
      // TODO this api return 500 instead of 404 and needs fixing.
      const { body } = await httpClient( `${ apiUrl }/reputationprofiles/lookup?${ new URLSearchParams( { url: url.trim() } ) }` );
      return JSON.parse( body ) as RaRecord;
    } catch( e ) {
      return;
    }
  }, [ httpClient ] );

  return (
    <Form
      record={ { url: '' } }
      warnWhenUnsavedChanges
      validate={ async ( data ) => {
        if( !data.url.includes( 'https' ) ) return { url: 'Must be a valid url' };
        return {};
      } }
      // @ts-ignore: 2339
      onSubmit={ async ( data ) => {
        if( !practitioner ) return;
        const profile = await testProfile( data.url )
        if( !profile ) return { url: 'Could not process' };
        const { id: profileId } = profile;
        const [ existingSubject ] = subjects || [];
        if( existingSubject ) {
          const result = await update( 'reputationsubjects', {
            id: existingSubject.id,
            data: {
              ...existingSubject,
              profiles: ( existingSubject.profiles || [] ).concat( profileId )
            },
            previousData: existingSubject
          }, { returnPromise: true } );
        } else {
          const result = await create( 'reputationsubjects', {
            data: {
              kind: 'Practitioner',
              name: practitioner.fullName,
              reference: practitioner.id,
              organization: organizations && organizations[ 0 ].name,
              profiles: [ profileId ],
            },
          }, { returnPromise: true } );

        }

        notify( 'app.confirmation.add.reviewSiteUrl', { type: 'success' } ); // TODO i18n
        close && close();
      } }
    >
      <Stack
        sx={ {
          padding: '1rem',
        } }
      >
        <TextInput source="url" fullWidth />
      </Stack>
      <Toolbar>
        <SaveButton />
      </Toolbar>
    </Form>
  )
}

const PractitionerForm: FC<Omit<SimpleFormProps, 'children'>> = props => {
  return (
    <SimpleFormWithLocation basePath='organization' sanitizeEmptyValues={ false } toolbar={ <CustomToolbar /> } { ...props }>

      <TextInput source="fullName" fullWidth />
      <TextInput source="prefix" fullWidth />
      <TextInput source="firstName" fullWidth />
      <TextInput source="middleName" fullWidth />
      <TextInput source="lastName" fullWidth />
      <TextInput source="suffix" fullWidth />
      { /* <TextInput label="NPI" source="npi" /> */ }

      <TextInput source="comment" fullWidth />
      { /* <BooleanInput source="active" /> */ }

      <BooleanInput source='disableAppointmentRealTimeUpdates' />
      <BooleanInput source='disableLabUpdates' />
      <BooleanInput source='disableOfficeCommunication' />

      { /*
         <ArrayInput source="reputationServices">
         <SimpleFormIterator disableReordering>
         <ReferenceInput label="Service" source="id" reference="reputationservices">
         <SelectInput source='Name' />
         </ReferenceInput>
         <TextInput label="External Id" source="externalId" />
         </SimpleFormIterator>
         </ArrayInput>
       */ }

    </SimpleFormWithLocation>
  );
}

export const PractitionerEdit: FC<EditProps> = props => {
  return (
    <Edit
      redirect='show'
      mutationMode='pessimistic'
      // actions={ <EditActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
      { ...props }
    >
      <PractitionerForm />
    </Edit>
  );
}

export const PractitionerCreate: FC<CreateProps> = props => {
  return (
    <Create
      redirect='show'
      // actions={ <CreateActions breadcrumb={ <MyBreadcrumb variant="actions" /> } /> }
      { ...props }
    >
      <PractitionerForm />
    </Create>
  );
}

export const PractitionerReputationReviewExpandShow: FC<Omit<ShowProps, 'children'>> = props => (
  <Show { ...props } title={ <span /> } actions={ false } >
    <SimpleShowLayout >
      <TextField source="body" label="Comment" />
      <TextField source="response.body" label="Response" />
      <DateField source="response.datePublished" label="Response Date" />
    </SimpleShowLayout>
  </Show>
);
