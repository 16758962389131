/* eslint-disable @typescript-eslint/no-explicit-any  */
import { Deserializer, DeserializerOptions, Serializer, SerializerOptions } from '@dreamer2q/jsonapi-serializer';
import { QueryClient } from '@tanstack/react-query';
import { addMinutes } from 'date-fns';
import { default as queryString } from 'query-string';
import { DataProvider, DeleteManyResult, fetchUtils } from 'react-admin';

export type HttpClient = ( url: RequestInfo, options?: fetchUtils.Options ) => Promise<{ status: any, headers: Record<string, any>, body: any, json: any }>;
export type DataProviderFunction = ( resourceMap: ResourceMap, url: string, queryClient: QueryClient, httpClient: HttpClient ) => DataProvider;
// export type TreeDataProviderFunction = ( resourceMap: ResourceMap, url: string, httpClient: HttpClient ) => DataProvider & TreeDataProvider;

const defaultHttpClient: HttpClient = ( url, options = {} ) => fetchUtils.fetchJson( url, options );

const deserialize = async ( data: any, map: ResourceMap, options?: DeserializerOptions ): Promise<any> => {
  const types = Object.keys( map );
  const valueForRelationship = ( rel: { id: string } ) => rel.id;
  const relationships = Object.fromEntries( types.map( type => [ type, { valueForRelationship } ] ) );
  return new Deserializer( {
    keyForAttribute: 'camelCase',
    ...relationships,
    ...options,
  } ).deserialize( data )
}

const serialize = async ( resource: string, data: any, map: ResourceMap, options?: SerializerOptions ) => { // : Promise<any> => {
  const { attributes, relationships: relationshipFieldNames } = map[ resource ];
  const relationships = Object.fromEntries( relationshipFieldNames.map( field => [ field, { ref: true } ] ) )
  const body = await new Serializer( resource, {
    keyForAttribute: 'camelCase',
    pluralizeType: true,
    attributes,
    ...relationships,

    // Fetch per resource relationship->resource map
    // http -b :3400/api/v1/spec | jq '[ .paths | to_entries[] | select( .key | match( "/{id}" ) ) | .value = ( .value.patch.requestBody.content | to_entries? | .[0].value | .schema.properties.data | to_entries[0].value | {  relationships: ( [ .. | .relationships?.properties | select( . ) | with_entries( .value |= ( .. | .enum? | select( . ) ) ) ] ) } ) | .key = ( .key[1:-5] ) | select( .value.relationships[0] ) ] | from_entries '

    // dictionary of relationship names by resource
    // http -b :3400/api/v1/spec | jq ' .paths | to_entries[] | select( .key | match( "/{id}" ) ) | .value = ( .value.patch.requestBody.content | to_entries? | .[0].value | .schema.properties.data | to_entries[0].value | ( [ .. | .relationships?.properties | select( . ) | with_entries( .value |= ( .. | .enum?[0] | select( . )  ) ) ] ) )[ 0 ] | .key = ( .key[1:-5] ) | .value | select( . ) | to_entries[]' | jq -s 'include "helpers2"; group_by_dict( .value ) | with_entries( .value |= ( [ .[] | .key ] | unique ) )'

    // hack
    typeForAttribute: type => {
      switch( type ) {
        case 'parent':
          if( data.parentType == 'Practitioner' ) return 'practitioners';
          return resource;
        case 'children':
          return resource;
        case 'amenityTags':
          return 'tags';
        case 'images':
          return 'images';
        case 'baseAlertTemplate':
          return 'alerttemplates';
        case 'patient':
        case 'patients':
        case 'recipients':
          return 'recipients';
        case 'lastLocation':
          return 'locations';
        case 'reminderGuardianTemplate':
        case 'reminderNonPatientTemplate':
        case 'reminderPatientTemplate':
          return 'messagetemplates';
        case 'atAppointmentReminders':
        case 'cancelledAppointmentReminders':
        case 'noShowAppointmentReminders':
        case 'postAppointmentReminders':
        case 'postAppointmentReviews':
        case 'preAppointmentReminders':
          return 'reminders';
        case 'atReminderTemplateList':
        case 'cancelledTemplateList':
        case 'noShowTemplateList':
        case 'postReminderTemplateList':
        case 'preReminderTemplateList':
        case 'reviewTemplateList':
          return 'remindertemplatelists';
        case 'atOpCalendarPackage':
        case 'postOpCalendarPackage':
        case 'preOpCalendarPackage':
          return 'calendarpackages';
        case 'htmlMessage':
          return 'htmlassemblies';
        case 'twimlAnsweringMachine':
        case 'twimlDigit0':
        case 'twimlDigit1':
        case 'twimlDigit2':
        case 'twimlDigit3':
        case 'twimlDigit4':
        case 'twimlDigit5':
        case 'twimlDigit6':
        case 'twimlDigit7':
        case 'twimlDigit8':
        case 'twimlDigit9':
        case 'twimlMenu':
        case 'twimlStart':
          return 'twimls';
        case 'visualization':
        case 'visualizations':
          return 'reputationvisualizations';
        case 'profile':
        case 'profiles':
          return 'reputationprofiles';
        case 'reputationServices':
          return 'reputationplatforms';
        case 'appointmentTypeCodesEnabledForCancel':
        case 'appointmentTypeCodesEnabledForReschedule':
        case 'appointmentTypeCodesEnabledForSchedule':
          return 'appointmenttypepackages';
        case 'outboundMessage':
          return 'outboundmessages';
        case 'form':
          return 'formdefinitions';
        default:
          return;
      }
    },

    ...options,
  } ).serialize( data );
  return JSON.stringify( body );
}

export interface ResourceDefinition {
  attributes: string[];
  relationships: string[];
}
export type ResourceMap = Record<string, ResourceDefinition>;

const getValidUntil = (): Date => addMinutes( new Date(), 7 );

type JSONAPI_ENTITY = { id: string, type: string, attributes: Record<string, any>, links: Record<string, string> };

export const jsonapiDataProvider: DataProviderFunction = ( resourceMap, apiUrl, _queryClient, httpClient = defaultHttpClient ) => {

  const addIncludedToCache = async ( _json: { included: JSONAPI_ENTITY[] | undefined } ): Promise<void> => {
    // NB - disabled this because AlertRecipients.outboundAlerts.relationships weren't
    //      populated (second level) and blowing away data from first level calls in
    //      OutboundAlertsShow/Recipients.
    //
    // const included = get( json, 'included', [] );
    // // console.log( 'included', included.length );
    // included.forEach( async ( data ) => {
    //   const { type: resource, id } = data;
    //   const doc = await deserialize( { data }, resourceMap );
    //   queryClient.setQueryData( [ resource, 'getOne', { id } ], doc )
    //   queryClient.setQueryData( [ resource, 'getMany', { ids: [ id ] } ], [ doc ] )
    // } );
  };

  return {
    getList: async ( resource, params ) => {
      const { page, perPage } = params.pagination || {};
      const { field, order = 'ASC' } = params.sort || {};
      const query: Record<string, any> = {
        sort: ( order.toUpperCase().slice( 0, 4 ) === 'DESC' ? '-' : '' ) + field,
        'page[number]': page,
        'page[size]': perPage,
      };
      Object.keys( params.filter || {} ).forEach( ( key ) => {
        const value = params.filter[ key ];
        query[ `filter[${ key }]` ] = Array.isArray( value ) ? value.join( ';' ) : value;
        query[ `filter[delimiter]` ] = ';';
      } );
      const url = `${ apiUrl }/${ resource }?${ queryString.stringify( query ) }`;
      const { json } = await httpClient( url );
      const data = await deserialize( json, resourceMap );
      const { total = data.length } = json.meta;
      const validUntil = getValidUntil();
      addIncludedToCache( json );
      return { data, total, json, validUntil };
    },

    getOne: async ( resource, params ) => {
      const { json } = await httpClient( `${ apiUrl }/${ resource }/${ params.id }` );
      const data = await deserialize( json, resourceMap );
      const validUntil = getValidUntil();
      addIncludedToCache( json );
      return { data, validUntil };
    },

    getMany: async ( resource, params ) => {
      const query: Record<string, any> = {
        'filter[id]': ( params.ids || [] ).flatMap( id => id ).filter( id => !!id ).join( ',' ),
      };
      const url = `${ apiUrl }/${ resource }?${ queryString.stringify( query ) }`;
      const { json } = await httpClient( url );
      const data = await deserialize( json, resourceMap );
      const validUntil = getValidUntil();
      addIncludedToCache( json );
      return { data, validUntil };
    },

    getManyReference: async ( resource, params ) => {
      const { id, target } = params;
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query: Record<string, any> = {
        sort: ( order === 'ASC' ? '' : '-' ) + field,
        'page[number]': page,
        'page[size]': perPage,
      };
      Object.keys( params.filter || {} ).forEach( ( key ) => {
        query[ `filter[${ key }]` ] = params.filter[ key ];
      } );
      if( target && id ) {
        query[ `filter[${ params.target }]` ] = id;
      }
      const url = `${ apiUrl }/${ resource }?${ queryString.stringify( query ) }`;
      const { json } = await httpClient( url );
      const data = await deserialize( json, resourceMap );
      const { total = data.length } = json.meta;
      const validUntil = getValidUntil();
      addIncludedToCache( json );
      return { data, total, validUntil };
    },

    update: async ( resource, params ) => {
      const { id } = params;
      const body = await serialize( resource, { id, ...params.data }, resourceMap, {
        // transform: ( record: Record<string, any> ) => pickBy( record ),
      } );
      const url = `${ apiUrl }/${ resource }/${ params.id }`;
      const { json } = await httpClient( url, { method: 'PATCH', body } );
      const data = await deserialize( json, resourceMap );
      return { data };
    },

    updateMany: async ( resource, params ) => {
      const _body = await serialize( resource, params.data, resourceMap, {
        // transform: ( record: Record<string, any> ) => pickBy( record ),
      } );
      const data = [];
      for( const id of params.ids ) {
        const body = await serialize( resource, { ...params.data, id }, resourceMap, {} );
        const url = `${ apiUrl }/${ resource }/${ id }`;
        const { json } = await httpClient( url, { method: 'PATCH', body } );
        const d = await deserialize( json, resourceMap );
        data.push( d );
      }
      return { data };
    },

    create: async ( resource, params ) => {
      const body = await serialize( resource, params.data, resourceMap, {
        // transform: ( record: Record<string, any> ) => pickBy( record ),
      } );
      const url = `${ apiUrl }/${ resource }`;
      const { json } = await httpClient( url, { method: 'POST', body } );
      const data = await deserialize( json, resourceMap );
      return { data };
    },

    delete: async ( resource, params ) => {
      const url = `${ apiUrl }/${ resource }/${ params.id }`;
      const { json } = await httpClient( url, { method: 'DELETE' } );
      const data = await deserialize( json, resourceMap );
      return { data };
    },

    deleteMany: async ( resource, params ) => {
      const data: DeleteManyResult[ 'data' ] = [];
      for( const id of params.ids ) {
        const url = `${ apiUrl }/${ resource }/${ id }`;
        const { json } = await httpClient( url, { method: 'DELETE' } );
        data.push = await deserialize( json, resourceMap );
      }
      return { data };
    },
  };
}


// export const jsonapiDataProviderWithTree: TreeDataProviderFunction = ( resourceMap, apiUrl, httpClient = defaultHttpClient ) => {
//   const dataProvider = jsonapiDataProvider( resourceMap, apiUrl, httpClient );
//
//   const getTree: TreeDataProvider[ 'getTree' ] = async ( resource, params = {} ) => {
//     const { page = 1, perPage = 1000 } = params?.pagination || {};
//     const { field = 'id', order = 'ASC' } = params?.sort || {};
//     const query: Record<string, any> = {
//       sort: ( order === 'ASC' ? '' : '-' ) + field,
//       'page[number]': page,
//       'page[size]': perPage,
//     };
//     Object.keys( params?.filter || {} ).forEach( ( key ) => {
//       const value = params.filter[ key ];
//       query[ `filter[${ key }]` ] = Array.isArray( value ) ? value.join( ',' ) : value;
//     } );
//     const url = `${ apiUrl }/${ resource }/tree?${ queryString.stringify( query ) }`;
//     const { json } = await httpClient( url );
//     const data = await deserialize( json, resourceMap );
//     return { data };
//   };
//
//   return {
//     ...dataProvider,
//
//     getTree,
//
//     getRootNodes: async ( resource, params ) => {
//       return getTree( resource, { ...params, filter: { ...params.filter, isRoot: true } } );
//     },
//
//     getParentNode: async ( resource, { childId: id } ) => {
//       const url = `${ apiUrl }/${ resource }/${ id }/parent`;
//       const { json } = await httpClient( url );
//       const data = await deserialize( json, resourceMap );
//       return { data };
//     },
//
//     getChildNodes: async ( resource, { parentId: id } ) => {
//       const url = `${ apiUrl }/${ resource }/${ id }/children`;
//       const { json } = await httpClient( url );
//       const data = await deserialize( json, resourceMap );
//       return { data };
//     },
//
//     addChildNode: async ( resource, { parentId, data } ) => {
//       return await dataProvider.create( resource, { data: { ...data, parent: parentId } } );
//     },
//
//     addRootNode: async ( resource, { data } ) => {
//       return await dataProvider.create( resource, { data } );
//     },
//
//     moveAsNthChildOf: async ( resource, { source, destination, position } ) => {
//       const idx = position < 0 || position > destination.children.length ? destination.children.length : position;
//       const children = [ ...destination.children ];
//       children.splice( idx, 0, source.id );
//       const body = JSON.stringify( { data: children.map( id => ( { type: resource, id } ) ) } );
//       const url = `${ apiUrl }/${ resource }/${ destination.id }/relationships/children`;
//       const { json } = await httpClient( url, { method: 'PATCH', body } );
//       const data = await deserialize( json, resourceMap );
//       return { data };
//     },
//
//     moveAsNthSiblingOf: async ( resource, { source, destination, position } ) => {
//       const body = JSON.stringify( { data: [ { type: resource, id: source.id } ] } );
//       const url = `${ apiUrl }/${ resource }/${ destination.parent }/relationships/children/${ position }`;
//       const { json } = await httpClient( url, { method: 'POST', body } );
//       const data = await deserialize( json, resourceMap );
//       return { data };
//     },
//
//     deleteBranch: async ( resource, { id } ) => {
//       return await dataProvider.delete( resource, { id } );
//     },
//
//   };
// }
