import { SsidChart as LineChartIcon, TableChartOutlined as TableIcon } from '@mui/icons-material';
import { Box, Card, CardContent, CardHeader, IconButton as MuiIconButton, Typography, useTheme } from '@mui/material';
import { DataGridPremium, GridColType } from '@mui/x-data-grid-premium';
import { BarDatum } from '@nivo/bar';
import { Datum, Line, LineProps, Serie, SliceTooltipProps } from '@nivo/line';
import { useCanAccess } from '@react-admin/ra-rbac';
import { addMonths } from 'date-fns';
import { Dayjs } from 'dayjs';
import { chunk, reverse, sumBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Loading, useRedirect } from 'react-admin';
import { ExportOnlyToolbar } from './DashboardReputationActivity';
import { makeLineChartSliceTooltip, makeLineChartTooltip, MonthlyLineChartSliceTooltipHeader } from './DashboardTooltip';
import { ChartProps, colorPalettes, elevation, OnlyNumbers } from './DashboardWidget';
import { apiUrl, httpClient } from './DataProvider';
import { useUserPreference } from './UserPreferences';

interface ReputationTrendDatum extends BarDatum {
  date: string;
  md: string;
  ymd: string;
  Clicks: number;
  Views: number;
  Blocks: number;
  Reviews: number;
}
export interface ReputationTrendLineDatum extends Datum {
  y: number | null;
  ymd: string;
  md: string;
}
export interface ReputationTrendLinePoint extends Serie {
  data: ReputationTrendLineDatum[];
}

export type LineLegendProps = NonNullable<LineProps[ 'legends' ]>[ 0 ];

export const DashboardReputationTrend: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const redirect = useRedirect();
  const { canAccess: canAccessSystem } = useCanAccess( { action: 'manage', resource: 'system' } );
  const isSmall = false;
  const { preferences, isLoading: isLoadingPreferences } = useUserPreference();
  const defaultDurationMonths = 6;
  const legendCols = 2;
  const cacheKey = 'dashboard-reputation-trend';
  const [ isLoading, setIsLoading ] = useState( true );
  const [ showDatagrid, setShowDatagrid ] = useState( false );
  const [ tableData, setTableData ] = useState<ReputationTrendDatum[] | undefined>( [] );
  const [ data, setData ] = useState<ReputationTrendLinePoint[]>( [] );
  const isEmpty = useMemo( () => sumBy( data, d => d.data.length ) === 0, [ data ] );
  const keys: ( keyof OnlyNumbers<ReputationTrendDatum> )[] = [ 'Views', 'Clicks', 'Blocks', 'Reviews' ];

  const durationMonths = useMemo( () => {
    if( isLoadingPreferences || !preferences?.dashboardTrendMonths ) return defaultDurationMonths;
    return preferences.dashboardTrendMonths as number;
  }, [ preferences, isLoadingPreferences ] );

  const counts = useMemo( () => (
    Object.fromEntries( ( data || [] ).map( ( { id, data = [] } ) => [ id, data.reduce( ( tot, d ) => ( d.y || 0 ) + tot, 0 ) ] ) )
  ), [ data ] );
  const yMax = useMemo( () => Math.max( ...[ 10, ...data.flatMap( row => row.data.map( d => d.y || 0 ) ) ] ), [ data ] );
  // const yMin = useMemo( () => Math.min( ...[ yMax * 0.9, ...data.flatMap( row => row.data.map( d => d.y || 0 ) ) ] ), [ data ] );

  const fetchSummary = useCallback( async ( date: Dayjs | undefined | null ) => {
    const query: Record<string,string> = {
      months: `${durationMonths}`,
    };
    if( date ) query.date = date.toISOString();
    const { body } = await httpClient( `${ apiUrl }/summaries/reputationTrend?${ new URLSearchParams( query ) }` );
    return body;
  }, [ durationMonths, httpClient ] );

  const convertRows = ( rows: ReputationTrendDatum[] ): ReputationTrendLinePoint[] => keys.map( id => ( {
    id, data: rows.map( d => {
      const { ymd, md, date } = d;
      const y = d[ id ] >= 1 ? d[ id ] : null; // no zeros for log scale
      // const y = d[ id ];
      return { x: ymd, y, ymd, md, date };
    } )
      .filter( d => !!d.y )
      .sort( ( a, b ) => a.ymd < b.ymd ? -1 : 1 )
  } ) )
    ;


  useEffect( () => {
    ( async () => {
      if( data.length || !isLoading ) return;
      const json = localStorage.getItem( cacheKey );
      if( data.length || !json || !isLoading ) return;
      try {
        const rows = JSON.parse( json ) as ReputationTrendDatum[];
        const lineData = convertRows( rows );
        setData( lineData );
        setIsLoading( false );
      } catch( e ) { return; }
    } )()
  }, [ data, setData, isLoading ] );

  useEffect( () => {
    ( async () => {
      const body = await fetchSummary( date );
      const rows = JSON.parse( body ) as ReputationTrendDatum[];
      const lineData = convertRows( rows );
      setTableData( rows );
      setData( lineData );
      setIsLoading( false );
      localStorage.setItem( cacheKey, JSON.stringify( rows ) );
    } )()
  }, [ setData, setTableData, fetchSummary, date ] );

  const colors = useMemo( () => [
    colorPalettes.discretePrimary[ 4 ], // blue
    colorPalettes.discretePrimary[ 5 ], // purple
    colorPalettes.discretePrimary[ 2 ], // red
    colorPalettes.discretePrimary[ 6 ], // green
  ], [ colorPalettes, keys ] );

  return (
    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title={
          <Box sx={ { display: 'flex' } } >
            <Typography>Reputation Trend</Typography>
            <Box sx={ { flexGrow: 1 } } />
            <MuiIconButton
              sx={ {
                // paddingTop: 0, paddingBottom: 0,
                marginTop: '-8px',
                marginBottom: '-8px',
              } }
              onClick={ () => setShowDatagrid( !showDatagrid ) }
            >
              { showDatagrid ? <LineChartIcon /> : <TableIcon /> }
            </MuiIconButton>
          </Box>
        }
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `${ durationMonths } Months Ending ${ date.format( 'MMM YYYY' ) }` : `Previous ${ durationMonths } Months` }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
            // fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          :
          <Box
            sx={ {
              position: 'relative',
            } }
          >
            { showDatagrid
              ? <Box
                width={ 500 }
                height={ 400 + 48 + 24 }
                sx={ {
                  marginTop: '-48px',
                  marginBottom: '-24px',
                } }
              >
                <DataGridPremium
                  density='compact'
                  disableRowSelectionOnClick
                  disableColumnResize
                  disableColumnSelector
                  disableColumnMenu
                  disableColumnReorder
                  disableColumnPinning
                  disableChildrenSorting
                  disableMultipleColumnsSorting
                  pagination
                  autoPageSize
                  rows={ ( tableData || [] ).map( ( d, id ) => ( { id, ...d } ) ) }
                  columns={ [
                    { field: 'ymd', headerName: 'Date', sortable: true, flex: 2 },
                    // @ts-ignore: 2322
                    ...keys.map( key => ( {
                      type: 'number' as GridColType,
                      field: key,
                      headerName: key,
                      sortable: false,
                      flex: 1,
                    } ) ),
                  ] }
                  slots={ {
                    toolbar: ExportOnlyToolbar.bind( undefined, { fileName: cacheKey } ),
                  } }
                  sx={ {
                    borderColor: 'transparent',
                    '& .MuiDataGrid-columnHeaders,& .MuiDataGrid-cell': {
                      borderBottomColor: 'transparent',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      overflow: 'hidden',
                    },
                  } }
                  initialState={ {
                    sorting: {
                      sortModel: [ { field: 'ymd', sort: 'desc' } ],
                    }
                  } }
                />
              </Box>
              : <>

                <Line
                  width={ 500 }
                  height={ 400 }
                  curve='monotoneX'
                  data={ isEmpty ? [] : data }
                  margin={ { top: 60, right: 50, bottom: 55, left: 75 } }
                  gridYValues={ 4 }

                  isInteractive={ !isEmpty }
                  useMesh={ !isEmpty }
                  enableCrosshair
                  // enableArea
                  areaOpacity={ 0.1 }
                  lineWidth={ 3 }
                  pointSize={ 6 }

                  xFormat="time:%Y-%m"
                  xScale={ {
                    type: 'time',
                    format: '%Y-%m-%d',
                    precision: 'month',
                    useUTC: false,
                    max: date ? date.toDate() : new Date(),
                    min: date ? addMonths( date.toDate(), 0 - durationMonths ) : addMonths( new Date(), 0 - durationMonths ),
                  } }

                  yScale={ {
                    type: 'log',
                    base: 2,
                    max: yMax * 1.5,
                    min: 1,
                  } }

                  enableSlices='x'
                  sliceTooltip={ makeLineChartSliceTooltip( {
                    theme,
                    renderHeader: MonthlyLineChartSliceTooltipHeader
                  } ) }

                  tooltip={ makeLineChartTooltip( theme ) }

                  theme={ {
                    background: theme.palette?.background?.default,
                    axis: {
                      legend: { text: { fontSize: isSmall ? 16 : 18 } },
                      ticks: { text: { fontSize: isSmall ? 0 : 14 } },
                    },
                    labels: { text: { fill: '#333' } },
                    legends: { text: { fontSize: 14 } },
                    text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
                    tooltip: {
                      container: {
                        borderRadius: '8px',
                        border: `0.5px solid ${ theme.palette?.grey?.A400 ?? 'grey' }`,
                        boxShadow: `${ theme.palette?.grey?.A400 ?? 'grey' } 0px 2px 2px`,
                        backgroundColor: theme.palette?.background?.default,
                        color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                        padding: '5px 9px',
                      },
                    },
                  } }

                  colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors } //{ scheme: colors, size: keys.length } }
                  // enableLabel={ false }
                  enableGridX={ false }
                  enableGridY={ true }
                  axisTop={ null }
                  axisRight={ null }
                  axisLeft={ {
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    tickValues: 4, // [ 0, 1000, 2000, 3000 ],
                    legend: 'Daily count (log2)',
                    legendPosition: 'middle',
                    legendOffset: -67

                  } }

                  axisBottom={ {
                    tickSize: 5,
                    // ticksPosition: 'before',
                    tickPadding: 5,
                    format: '%b',
                    tickValues: `every ${ Math.ceil( durationMonths / 9 ) } months`,
                    legend: 'Month',
                    legendPosition: 'middle',
                    legendOffset: 42,
                  } }

                  // multirow legend (by 2)
                  legends={ chunk( keys, legendCols ).map<LineLegendProps>( ( ch, chIdx, chArr ) => (
                    {
                      data: [
                        ...ch.map( ( id, idx, _arr ) => ( {
                          id,
                          label: `${ id } (${ counts[ id ] || 0 })`,
                          fill: colors[ idx + legendCols * chIdx ],
                        } ) ),
                        // this works for legendCols 2 but not 3
                        ...( keys.length % legendCols !== 0 && chIdx == chArr.length - 1
                          ? [ { id: 'x', opacity: 0.2, label: '', fill: 'transparent' } ]
                          : []
                        ),
                      ],
                      anchor: 'top-left',
                      direction: 'row',
                      justify: false,
                      translateX: -30,
                      translateY: -60 + chIdx * 22,
                      itemsSpacing: 80,
                      itemWidth: 110, // * ( keys.length % legendCols !== 0 && chIdx == chArr.length - 1 ? legendCols : 1 ),
                      itemHeight: 20,
                      itemDirection: 'left-to-right',
                      itemOpacity: 1, // 0.95,
                      symbolSize: 18, // 12,
                      symbolShape: 'circle', // 'square',
                      toggleSerie: true,
                    }
                  ) ) }
                />
              </>
            }
          </Box>
        }
      </CardContent>
    </Card >

  );

}
