import { Breadcrumb, BreadcrumbItem, BreadcrumbPathMap, BreadcrumbProps, GetLabelFunction, useAppLocationState } from '@react-admin/ra-navigation';
import { IfCanAccess } from '@react-admin/ra-rbac';
import { titleize } from 'inflection';
import { get } from 'lodash';
import { FC, useEffect, useMemo } from 'react';
import { Form, FormProps, GetResourceLabel, RaRecord, ResourceComponentInjectedProps, ResourceDefinition, ResourceProps, SimpleForm, SimpleFormProps, SimpleShowLayout, SimpleShowLayoutProps, TabbedShowLayout, TabbedShowLayoutProps, Translate, useCreatePath, useGetResourceLabel, useRecordContext, useResourceContext, useResourceDefinitions, useTranslate } from 'react-admin';
import { useLocation } from 'react-router-dom';

export const isRaRecord = ( r: unknown ): r is RaRecord => !!r && ( r as RaRecord ).id !== undefined;

type MenuResource = Omit<ResourceProps, 'options'> & { options?: { label?: string, hide?: boolean, isAdmin?: boolean; } } & ResourceComponentInjectedProps;

export type MyBreadcrumbContext = Record<string, Record<string, string>>;


// export const SimpleShowLayoutWithLocation: FC<SimpleShowLayoutProps> = props => {
//   const resource = useResourceContext();
//   const record = useRecordContext();
//   if( !( record && resource ) ) return null;
//   // useDefineAppLocation( `${ resource }.${ singularize( resource ) }`, { record, resource, basePath } );
//   return ( <SimpleShowLayout { ...props } /> );
// }

// export const SimpleFormWithLocation: FC<SimpleFormProps> = props => {
//   const { record, resource } = props;
//   if( !( record && resource ) ) return null;
//   // useDefineAppLocation(
//   //   record.id ? `${ resource }.${ singularize( resource ) }` : `${ resource }.create`,
//   //   { record, resource, basePath }
//   // );
//   return ( <SimpleForm sanitizeEmptyValues   { ...props } /> );
// }

// Include this in Admin embedded List views
export const useListAppLocation = ( basePath: string ): void => {
  const resource = useResourceContext();
  const [ _, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( `${ basePath }.${ resource }` );
    return () => setLocation( null );
  }, [] );
};


// SimpleShowLayout with the AppLocation for embedded resource Show pages
export const SimpleShowLayoutWithLocation: FC<SimpleShowLayoutProps & { basePath: string }> = props => {
  const { basePath, ...rest } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( `${ basePath }.${ resource }.show`, { record, resource, basePath } );
    return () => setLocation( null );
  }, [] );
  return <SimpleShowLayout { ...rest } />;
}

export const TabbedShowLayoutWithLocation: FC<TabbedShowLayoutProps & { basePath: string }> = props => {
  const { basePath, ...rest } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  const [ _location, setLocation ] = useAppLocationState();
  const { pathname, state } = useLocation();
  useEffect( () => {
    setLocation( `${ basePath }.${ resource }.show`, { record, resource, basePath } );
    return () => setLocation( null );
  }, [ record, resource ] );
  useEffect( () => {
    // console.log( 'pathname', pathname, state );
    const [ _0, tab ] = pathname.match( /\/show\/(.*)$/ ) || [];
    if( tab ) {
      setLocation( `${ basePath }.${ resource }.show.${ tab }`, { record, resource, basePath, tab } );
    }
    return () => setLocation( `${ basePath }.${ resource }.show`, { record, resource, basePath } );
  }, [ pathname, state ] );
  return <TabbedShowLayout { ...rest } />;
}

// SimpleForm with the AppLocation for amdin embedded resource Edit/Create pages
export const SimpleFormWithLocation: FC<SimpleFormProps & { basePath: string }> = props => {
  const { basePath, ...rest } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( `${ basePath }.${ resource }.${ record?.id ? 'edit' : 'create' }`, { record, resource, basePath } );
    return () => setLocation( null );
  }, [] );
  return <SimpleForm { ...rest } />;
}

// Same for replacing Form elements
export const FormWithLocation: FC<FormProps & { basePath: string }> = props => {
  const { basePath, ...rest } = props;
  const resource = useResourceContext();
  const record = useRecordContext();
  const [ _location, setLocation ] = useAppLocationState();
  useEffect( () => {
    setLocation( `${ basePath }.${ resource }.${ record?.id ? 'edit' : 'create' }`, { record } );
    return () => setLocation( null );
  }, [] );
  return <Form { ...rest } />;
}




export type MyResourceBreadcrumbItemsProps = {
  resources?: string[];
  children?: BreadcrumbProps[ 'children' ];
};

export const MyResourceBreadcrumbItems: FC<MyResourceBreadcrumbItemsProps> = ( { resources: selectedResources } ) => {
  const createPath = useCreatePath();
  const resourceDefinitions = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const translate = useTranslate();

  const { resources } = useMemo( (): { adminResources: Array<MenuResource>, resources: Array<MenuResource> } => {
    const allResources = Object.values( resourceDefinitions )
    const visible = allResources.filter( r => /* r.hasList && */ !r.options?.hide );
    const adminResources = visible.filter( r => r.options?.isAdmin );
    const resources = visible.filter( r => !r.options?.isAdmin );
    return { adminResources, resources };
  }, [ resourceDefinitions ] );


  const resourcesPaths = useMemo( () => buildMyResourcesBreadcrumbPaths(
    resources.filter( resource => !selectedResources || selectedResources.includes( resource.name ) ),
    translate,
    getResourceLabel,
    createPath,
  ), [ resources, translate, getResourceLabel, createPath ] );

  return (
    <>
      { Object.keys( resourcesPaths ).map( name => (
        <BreadcrumbItem
          key={ name }
          name={ name }
          { ...resourcesPaths[ name ] }
        />
      ) ) }
    </>
  );
};

export interface MyAdminResourceBreadcrumbItemsProps extends MyResourceBreadcrumbItemsProps {
  skip?: string[];
}

export const MyAdminResourceBreadcrumbItems: FC<MyAdminResourceBreadcrumbItemsProps> = ( { resources: selectedResources, children, skip = [] } ) => {
  const createPath = useCreatePath();
  const resourceDefinitions = useResourceDefinitions();
  const getResourceLabel = useGetResourceLabel();
  const translate = useTranslate();

  const { adminResources } = useMemo( (): { adminResources: Array<MenuResource>, resources: Array<MenuResource> } => {
    const allResources = Object.values( resourceDefinitions )
    const visible = allResources.filter( r => r.hasList && !r.options?.hide ).filter( r => !skip.includes( r.name ) );
    const adminResources = visible.filter( r => r.options?.isAdmin )
    const resources = visible.filter( r => !r.options?.isAdmin );
    return { adminResources, resources };
  }, [ resourceDefinitions ] );


  const adminResourcesPaths = useMemo( () => buildMyResourcesBreadcrumbPaths(
    adminResources.filter( resource => !selectedResources || selectedResources.includes( resource.name ) ),
    translate,
    getResourceLabel,
    createPath,
  ), [ adminResources, translate, getResourceLabel, createPath ] );

  return (
    <>
      { Object.keys( adminResourcesPaths ).map( name => (
        <BreadcrumbItem
          key={ `admin.${ name }` }
          name={ `admin.${ name }` }
          { ...adminResourcesPaths[ name ] }
        />
      ) ) }
      { children }
    </>
  );
};

export const buildMyResourcesBreadcrumbPaths = (
  resources: ResourceDefinition[],
  translate: Translate,
  getResourceLabel: GetResourceLabel,
  createPath: ReturnType<typeof useCreatePath>,
): BreadcrumbPathMap =>
  resources.reduce( ( paths, resource ) => {
    const { recordRepresentation } = resource;
    const resourcePaths: BreadcrumbPathMap = {};
    const resourceLabelPlural = getResourceLabel( resource.name, 2 );
    const resourceLabelSingular = getResourceLabel( resource.name, 1 );

    resourcePaths[ resource.name ] = {
      label: resourceLabelPlural,
      to: `/${ resource.name }`,
    };

    resourcePaths[ `${ resource.name }.create` ] = {
      label: !resource.hasList
        ? translate( 'ra.page.create', {
          name: resourceLabelSingular,
        } )
        : translate( 'ra.action.create' ),
      to: `/${ resource.name }/create`,
    };

    resourcePaths[ `${ resource.name }.edit` ] = {
      label: ( { record } ) => {
        if( !isRaRecord( record ) ) return translate( 'ra.action.edit' );
        if( recordRepresentation ) {
          if( typeof recordRepresentation == 'string' ) return record[ recordRepresentation ] ?? '';
          if( typeof recordRepresentation == 'function' ) return recordRepresentation( record ) ?? '';
          // return; // recordRepresentation;
        }
        if( !resource.hasList ) {
          return translate( 'ra.page.edit', {
            name: resourceLabelSingular,
            id: record.name || record.fullName || record.id,
            record,
          } );
        }
        return `${ record.name || record.name || record.fullName || '#' + record.id }`;
      },
      to: ( { record } ) => isRaRecord( record ) ? createPath( { resource: resource.name, type: 'edit', id: record.id } ) : '',
    };

    resourcePaths[ `${ resource.name }.show` ] = {
      label: ( { record } ) => {
        if( !isRaRecord( record ) ) return translate( 'ra.action.show' );
        if( recordRepresentation ) {
          if( typeof recordRepresentation == 'string' ) return record[ recordRepresentation ] ?? '';
          if( typeof recordRepresentation == 'function' ) return recordRepresentation( record ) ?? '';
          // return recordRepresentation;
        }
        if( !resource.hasList ) {
          return translate( 'ra.page.show', {
            name: resourceLabelSingular,
            id: record.name || record.fullName || record.id,
            record,
          } );
        }
        return `${ record.name || record.name || record.fullName || '#' + record.id }`;
      },
      to: ( { record } ) => isRaRecord( record ) ? createPath( { resource: resource.name, type: 'show', id: record.id } ) : '',
    };

    // if( resource.isAdmin ) {
    //   return { ...paths, admin: { paths.admin, ...resourcePaths } };
    // }

    return { ...paths, ...resourcePaths };
  }, {} );

export const MyBreadcrumb: FC<BreadcrumbProps> = props => {
  const resourceDefinitions = useResourceDefinitions();

  const getLabel = ( resource: string ): GetLabelFunction => ( { record } ) => {
    const { recordRepresentation } = resourceDefinitions[ resource ] || {}
    if( !isRaRecord( record ) || !recordRepresentation ) return 'Edit';
    if( typeof recordRepresentation == 'string' ) return record[ recordRepresentation ] ?? '';
    if( typeof recordRepresentation == 'function' ) return recordRepresentation( record ) ?? '';
    return record.id
  }

  return (
    <Breadcrumb { ...props }>

      <MyResourceBreadcrumbItems />

      { /* ** this resides in the App <Admin /> props
           <BreadcrumbItem name='dashboard' label='Dashboard' to='/' />
         */ }

      <BreadcrumbItem name='broadcasts' label='Broadcasts' to='/broadcasts/outboundalerts' >
        <BreadcrumbItem name='outboundalerts' label='Patient' to='/broadcasts/outboundalerts' >
          <BreadcrumbItem name="create" label='Create' />
          <BreadcrumbItem name="edit" label={ getLabel( 'outboundalerts' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'outboundalerts' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='alerttemplates' label='Templates' to='/broadcasts/alerttemplates' >
          <BreadcrumbItem name="show" label={ getLabel( 'alerttemplates' ) } />
          <BreadcrumbItem name="edit" label={ getLabel( 'alerttemplates' ) } />
        </BreadcrumbItem>
      </BreadcrumbItem>
      <BreadcrumbItem name='anomalies' label='Anomalies' to='/anomalies/anomalyreports' >
        <BreadcrumbItem name='anomalyreports' label='Reports' to='/anomalies/anomalyreports' >
          <BreadcrumbItem name="edit" label={ getLabel( 'anomalyreports' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'anomalyreports' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='anomalyreportdefinitions' label='Definitions' to='/anomalies/anomalyreportdefinitions' >
          <BreadcrumbItem name="edit" label={ getLabel( 'anomalyreportdefinitions' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'anomalyreportdefinitions' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='anomalyreportemails' label='Emails' to='/anomalies/anomalyreportemails' >
          <BreadcrumbItem name="edit" label={ getLabel( 'anomalyreportemails' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'anomalyreportemails' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='dashboardalerts' label='EMR Setup Issues' to='/anomalies/dashboardalerts' />
      </BreadcrumbItem>
      <BreadcrumbItem name='appointment' label='Appointment' to='/appointment/appointments' >
        <BreadcrumbItem name='appointments' label='Appointments' to='/appointment/appointments' >
          <BreadcrumbItem name="edit" label={ getLabel( 'appointments' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'appointments' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='appointmenttypes' label='Types' to='/appointment/appointmenttypes' >
        </BreadcrumbItem>
      </BreadcrumbItem>

      <BreadcrumbItem name='patient' label='Patient' to='/patient/patients' >
        <BreadcrumbItem name='recipients' label='Patients' to='/patient/patients' >
          <BreadcrumbItem name="edit" label={ getLabel( 'recipients' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'recipients' ) } />
        </BreadcrumbItem>


        <BreadcrumbItem name='emreventtypes' label='Event Setup' to='/patient/emreventtypes' >
          <BreadcrumbItem name="edit" label={ getLabel( 'emreventtypes' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'emreventtypes' ) }
            // @ts-ignore:2339
            to={ ( { record } ) => `/emreventtypes/${ record?.id + '/show' || '' }` }
          >
          </BreadcrumbItem>
        </BreadcrumbItem>

      </BreadcrumbItem>

      <BreadcrumbItem name='organization' label='Organization' to='/organization/locations' >

        <BreadcrumbItem name='locations' label='Locations' to='/organization/locations' >
          <BreadcrumbItem name="edit" label={ getLabel( 'locations' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'locations' ) }
            // @ts-ignore:2339
            to={ ( { record } ) => `/locations/${ record?.id + '/show' || '' }` }
          >
            <BreadcrumbItem name='assets' label='Branding Collateral'
              // @ts-ignore:2339
              to={ ( { record } ) => `/locations/${ record?.id + '/show/assets' || '' }` }
            >
              <BreadcrumbItem name="create" label='Create' />
              <BreadcrumbItem name="edit" label={ 'Edit' } />
              <BreadcrumbItem name="show" label={ 'Show' } />
            </BreadcrumbItem>
            <BreadcrumbItem name="amenities" label='Amenities'
              // @ts-ignore:2339
              to={ ( { record } ) => `/locations/${ record?.id + '/show/amenities' || '' }` }
            >
              <BreadcrumbItem name="create" label='Create' />
              <BreadcrumbItem name="edit" label={ 'Edit' } />
              <BreadcrumbItem name="show" label={ 'Show' } />

            </BreadcrumbItem>
            <BreadcrumbItem name='review-sites' label='Review Sites' />
          </BreadcrumbItem>
        </BreadcrumbItem>

        <BreadcrumbItem name='practitioners' label='Practitioners' to='/organization/practitioners' >
          <BreadcrumbItem name="edit" label={ getLabel( 'practitioners' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'practitioners' ) }
            // @ts-ignore:2339
            to={ ( { record } ) => `/practitioners/${ record?.id + '/show' || '' }` }
          >
            <BreadcrumbItem name='appointments-today' label='Appointments Today' />
            <BreadcrumbItem name='profile' label='Profile'
              // @ts-ignore:2339
              to={ ( { record } ) => `/practitioners/${ record?.id + '/show/profile' || '' }` }
            >
              <BreadcrumbItem name="edit" label={ 'Edit' } />
              <BreadcrumbItem name="show" label={ 'Show' } />
            </BreadcrumbItem>
            <BreadcrumbItem name='review-sites' label='Review Sites' />
          </BreadcrumbItem>
        </BreadcrumbItem>

        <BreadcrumbItem name='organizations' label='Organizations' to='/organization/organizations' >
          <BreadcrumbItem name="edit" label={ getLabel( 'organizations' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'organizations' ) }
            // @ts-ignore:2339
            to={ ( { record } ) => `/organizations/${ record?.id + '/show' || '' }` }
          >
            <BreadcrumbItem name='assets' label='Branding Collateral'
              // @ts-ignore:2339
              to={ ( { record } ) => `/organizations/${ record?.id + '/show/assets' || '' }` }
            >
              <BreadcrumbItem name="create" label='Create' />
              <BreadcrumbItem name="edit" label={ 'Edit' } />
              <BreadcrumbItem name="show" label={ 'Show' } />
            </BreadcrumbItem>

          </BreadcrumbItem>
        </BreadcrumbItem>
      </BreadcrumbItem>


      <BreadcrumbItem name='reputation' label='Reputation' to='/reputation/reputationvisualizations' >
        <BreadcrumbItem name='reputationexplorer' label='Explorer' to='/reputation/reputationexplorer' />
        <BreadcrumbItem name='reputationvisualizations' label='Charts' to='/reputation/reputationvisualizations' >
          <BreadcrumbItem name="create" label='Create' />
          <BreadcrumbItem name="edit" label={ getLabel( 'reputationvisualizations' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'reputationvisualizations' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='reputationreportschedules' label='Email' to='/reputation/reputationreportschedules' >
          <BreadcrumbItem name="create" label='Create' />
          <BreadcrumbItem name="edit" label={ getLabel( 'reputationreportschedules' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'reputationreportschedules' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='reputationconnections' label='Connections' to='/reputation/reputationconnections' />
      </BreadcrumbItem>

      <BreadcrumbItem name='reviews' label='Reviews' to='/reviews/reputationreviews' >
        <BreadcrumbItem name='reputationreviews' label='Online' to='/reviews/reputationreviews' >
          <BreadcrumbItem name="edit" label={ getLabel( 'reputationreviews' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'reputationreviews' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='reputationresponsetemplates' label='Response Templates' to='reviews/reputationresponsetemplates' >
          <BreadcrumbItem name="edit" label={ getLabel( 'reputationresponsetemplates' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'reputationresponsetemplates' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='reputationfeedbacks' label='Internal' to='/reviews/reputationfeedbacks' >
          <BreadcrumbItem name="edit" label={ getLabel( 'reputationrfeedbacks' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'reputationrfeedbacks' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='reputationviews' label='Views' to='/reviews/reputationviews' >
          <BreadcrumbItem name="edit" label={ getLabel( 'reputationviews' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'reputationviews' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='reputationclicks' label='Clicks' to='/reviews/reputationclicks' >
          <BreadcrumbItem name="edit" label={ getLabel( 'reputationclicks' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'reputationclicks' ) } />
        </BreadcrumbItem>
      </BreadcrumbItem>

      <BreadcrumbItem name='messaging' label='Messaging' to='/messaging/outboundmessages' >
        <BreadcrumbItem name='outboundmessages' label='Sent Messages' to='/messaging/outboundmessages' >
          <BreadcrumbItem name="show" label={ getLabel( 'outboundmessages' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='reminders' label='Scheduled Messages' to='/messaging/reminders' >
          <BreadcrumbItem name="create" label='Create' />
          <BreadcrumbItem name="edit" label={ getLabel( 'reminders' ) } />
          <BreadcrumbItem name="show" label={ getLabel( 'reminders' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='incomingReply' label='Incoming Email' to='/messaging/incomingReply' />
        <BreadcrumbItem name='undeliverableEmail' label='Undeliverable Email' to='/messaging/undeliverableEmail' />

        <BreadcrumbItem name='incomingText' label='Incoming SMS' to='/messaging/incomingText' >
          <BreadcrumbItem name="show" label={ getLabel( 'inboundtexts' ) } />
        </BreadcrumbItem>
        <BreadcrumbItem name='inboundtexts' label='Incoming SMS' to='/messaging/incomingText' >
          <BreadcrumbItem name="show" label={ getLabel( 'inboundtexts' ) } />
        </BreadcrumbItem>

      </BreadcrumbItem>

      <BreadcrumbItem name='contacteditor' label='Contact Editor' to='/contacteditor' >
        <BreadcrumbItem
          name='resource'
          label={ context => {
            const { resource } = context;
            if( typeof resource != 'string' ) return '';
            return titleize( resource == 'recipients' ? 'patients' : resource );
          }
          }
        />
      </BreadcrumbItem>

      <IfCanAccess resource='system' action='manage'>
        <BreadcrumbItem name='admin' label='Admin' to='/admin' >
          <BreadcrumbItem
            name='golive'
            label='GoLive!'
            to='/admin/golive'
          />
          <BreadcrumbItem
            name='system'
            label='System'
            to='/admin/system/clear'
          >
            <BreadcrumbItem
              name='action'
              label={ context =>
                get( context, 'action.name', '' ) as string
              }
            />
          </BreadcrumbItem>

          <MyAdminResourceBreadcrumbItems skip={ [ 'texts', 'messagetemplates' ] } />

          <BreadcrumbItem key='text' name='text' label='Texts' to='/text/texts' >
            <BreadcrumbItem key='texts' name='texts' label='Standard' to='/text/texts' >
              <BreadcrumbItem name="create" label='Create' />
              <BreadcrumbItem name="edit" label={ getLabel( 'texts' ) } />
              <BreadcrumbItem name="show" label={ getLabel( 'texts' ) } />
            </BreadcrumbItem>
            <BreadcrumbItem key='customtexts' name='customtexts' label='Custom' to='/text/customtexts' >
              <BreadcrumbItem name="create" label='Create' />
              <BreadcrumbItem name="edit" label={ getLabel( 'texts' ) } />
              <BreadcrumbItem name="show" label={ getLabel( 'texts' ) } />
            </BreadcrumbItem>
          </BreadcrumbItem>

          <BreadcrumbItem name='messagetemplates' label='Message Templates' to='/messagetemplates' >
            <BreadcrumbItem name='create' label='Create' />
            <BreadcrumbItem name='edit' label={ getLabel( 'messagetemplates' ) }>
              <BreadcrumbItem name='config' label='Config' />
              <BreadcrumbItem name='email' label='Email' />
              <BreadcrumbItem name='sms' label='SMS' />
              <BreadcrumbItem name='voice' label='Voice' />
            </BreadcrumbItem>
            <BreadcrumbItem name='show' label={ getLabel( 'messagetemplates' ) } />
          </BreadcrumbItem>



        </BreadcrumbItem>
      </IfCanAccess >

    </Breadcrumb >
  );

  //     <BreadcrumbItem name="recipients" label="Recipients" to="/recipients">
  //       <BreadcrumbItem name="create" label="Create" />
  //       <BreadcrumbItem name="recipient"
  //         label={ context =>
  //           `${ get( context as MyBreadcrumbContext, 'record.fullName', 'name' ) }`
  //         }
  //         to={ context => {
  //           return `${ get( context as MyBreadcrumbContext, 'basePath', 'basepath' ) }/${ get( context as MyBreadcrumbContext, 'record.id', 'id' ) }/show`;
  //         } }
  //       >
  //         <BreadcrumbItem name="edit" label="Edit" />
  //       </BreadcrumbItem>
  //     </BreadcrumbItem>
  //
  //     <BreadcrumbItem name="practitioners" label="Practitioners" to="/practitioners">
  //       <BreadcrumbItem name="create" label="Create" />
  //       <BreadcrumbItem name="practitioner"
  //         label={ context =>
  //           `${ get( context as MyBreadcrumbContext, 'record.fullName', 'name' ) }`
  //         }
  //         to={ context => {
  //           return `${ get( context as MyBreadcrumbContext, 'basePath', 'basepath' ) }/${ get( context as MyBreadcrumbContext, 'record.id', 'id' ) }/show`;
  //         } }
  //       >
  //         <BreadcrumbItem name="edit" label="Edit" />
  //       </BreadcrumbItem>
  //     </BreadcrumbItem>
  //
  //     <BreadcrumbItem name="locations" label="Locations" to="/locations">
  //       <BreadcrumbItem name="create" label="Create" />
  //       <BreadcrumbItem name="location"
  //         label={ context =>
  //           `${ get( context as MyBreadcrumbContext, 'record.name', 'name' ) }`
  //         }
  //         to={ context => {
  //           return `${ get( context as MyBreadcrumbContext, 'basePath', 'basepath' ) }/${ get( context as MyBreadcrumbContext, 'record.id', 'id' ) }/show`;
  //         } }
  //       >
  //         <BreadcrumbItem name="edit" label="Edit" />
  //       </BreadcrumbItem>
  //     </BreadcrumbItem>
  //

}

