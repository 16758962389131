import { Box, Card, CardContent, CardHeader, useTheme } from '@mui/material';
import { BarDatum } from '@nivo/bar';
import { Pie } from '@nivo/pie';
import { Dayjs } from 'dayjs';
import { sumBy } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Loading, useGetList, useRedirect } from 'react-admin';
import { makePieChartTooltip } from './DashboardTooltip';
import { ChartProps, colorPalettes, elevation, PieDatum } from './DashboardWidget';
import { apiUrl, httpClient } from './DataProvider';


export const DashboardReputationSummary: FC<ChartProps> = ( { date } ) => {
  const theme = useTheme();
  const redirect = useRedirect();
  const isSmall = false;
  const [ data, setData ] = useState<PieDatum[]>( [] );
  const { data: locations, isLoading: isLoadingLocations } = useGetList( 'locations', { filter: { status: 'active' } } );
  const total = useMemo( () => data.reduce( ( tot, d ) => tot += d.value, 0 ), [ data ] );

  const fetchSummary = useCallback( async ( reference: string, date?: Dayjs | null ) => {
    const { body } = await httpClient( `${ apiUrl }/reputationsubjectreviews/trends?durationCount=6&durationType=months&reference=${ encodeURIComponent( reference ) }${ date ? `&date=${ encodeURIComponent( date.toISOString() ) }` : '' }` );
    return body;
  }, [ httpClient ] );

  const [ isLoading, setIsLoading ] = useState( true );
  useEffect( () => {
    ( async () => {
      if( data.length || !isLoading ) return;
      const json = localStorage.getItem( 'dashboard-rep' );
      if( data.length || !json || !isLoading ) return;
      // console.log( 'loaded', 'dashboard-rep' );
      try {
        setData( JSON.parse( json ) );
        setIsLoading( false );
        // console.log( 'set', 'dashboard-rep' );
      } catch( e ) { return; }
    } )()
  }, [ data, setData, isLoading ] );

  useEffect( () => {
    ( async () => {
      if( isLoadingLocations || !locations ) return;
      const reference = locations.map( l => l.id ).join( ',' );
      const body = await fetchSummary( reference, date );
      const raw = JSON.parse( body ) as BarDatum[];
      const partial = raw
        .map( o => { const { id, ...rest } = o; return { ...rest }; } )
        // @ts-ignore:2769
        .reduce( ( tot, o ) => {
          for( const s in tot ) {
            // @ts-ignore:2365
            tot[ s ] += o[ s ] || 0;
          }
          return tot;
        }, { '1 star': 0, '2 stars': 0, '3 stars': 0, '4 stars': 0, '5 stars': 0 } );
      // console.log( partial );
      let data = Object.entries( partial ).map( ( [ id, value ] ) => ( { id, value } ) ).reverse() as PieDatum[];
      if( sumBy( data, d => d.value ) === 0 ) {
        // @ts-ignore:2322
        data = [ { id: null, label: 'No data', value: true } ];
      }
      setData( data );
      setIsLoading( false );
      // console.log( 'fetched', 'dashboard-rep' );
      localStorage.setItem( 'dashboard-rep', JSON.stringify( data ) );
    } )()
  }, [ setData, fetchSummary, isLoadingLocations, locations, date ] );

  const isEmpty = useMemo( () => data[ 0 ]?.id === null, [ data ] );
  const colors = useMemo( () => colorPalettes.spectrumGreenRed, [ colorPalettes ] );

  return (

    <Card
      elevation={ elevation }
      sx={ {
        minHeight: '25em',
        overflowX: 'visible',
        overflow: 'initial',
      } }
    >
      <CardHeader
        title="Reputation Summary"
        titleTypographyProps={ {
          sx: {
            fontSize: '1.2rem',
            fontWeight: 400,
            // lineHeight: 1.334,
          }
        } }
        subheader={ date ? `6 Months Ending ${ date.format( 'MMM D' ) }` : 'Previous 6 Months' }
        subheaderTypographyProps={ {
          sx: {
            fontSize: '0.9rem',
          }
        } }
      // avatar={ <DashboardAlertIcon /> }
      />
      <CardContent
        sx={ {
          overflowX: 'visible',
        } }
      >
        { isLoading
          ? <Box>
            <Loading
              loadingPrimary=''
              loadingSecondary=''
              sx={ {
                '@media (min-width: 0)': {
                  marginTop: 0,
                  marginBottom: 7,
                  height: '100%',
                  width: 500,
                  minHeight: 400,
                }
              } }
            />
          </Box>

          : <Pie
            width={ 500 }
            height={ 400 }
            data={ data }
            margin={ { top: 0, right: 160, bottom: 10, left: 10 } }

            onClick={ ( datum ) => {
              const { id } = datum;
              const [ ord ] = id.toString().split( ' ' ) as string[];
              return redirect( 'list', `/reviews/reviews?filter=%7B"rating"%3A%5B${ ord }%5D%7D` );
            } }

            theme={ {
              background: theme.palette?.background?.default,
              axis: {
                legend: { text: { fontSize: isSmall ? 16 : 18 } },
                ticks: { text: { fontSize: isSmall ? 0 : 14 } },
              },
              labels: { text: { fill: '#333', fontSize: 14, fontWeight: 'bold' } },
              legends: { text: { fontSize: 14 } },
              text: { fill: theme.palette?.mode === 'dark' ? '#ccc' : '#333' },
              tooltip: {
                container: {
                  backgroundColor: theme.palette?.background?.default,
                  color: theme.palette?.mode === 'dark' ? '#ccc' : '#333',
                },
              },
            } }
            tooltip={ makePieChartTooltip( theme, total ) }

            colors={ isEmpty ? [ theme.palette?.mode === 'dark' ? '#555' : '#ccc' ] : colors }

            arcLabel={ ( d ) => `${ ( 100 * d.value / total ).toFixed( 0 ) }% (${ d.value })` }
            arcLabelsRadiusOffset={ 0.6 }

            isInteractive={ !isEmpty }
            enableArcLabels={ !isEmpty }
            enableArcLinkLabels={ false }
            arcLabelsSkipAngle={ 20 }

            legends={ [
              {
                anchor: 'right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: -80,
                itemsSpacing: 4,
                itemWidth: 80,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ] }
          />
        }
      </CardContent>
    </Card>

  );

}

